<div class="flex-column" (click)="closeEmoji($event)" style="height: 100%;" *ngIf="currentConvo">
	<div class="flex-row conversation-header"  *ngIf="!currentConvo.gameid">
		<button
			mat-icon-button
			matSuffix
			(click)="closeConversation()"
			matTooltip="Back To Conversations"
			style="margin: 0 10px"
		>
			<mat-icon>arrow_back</mat-icon>
		</button>
		<mat-icon mat-list-icon>{{ currentConvo.members?.length > 2 ? 'group' : 'person' }}</mat-icon>
		<mat-label style="flex: 1; margin: 0 10px; font-size: larger">{{
			currentConvo.name || ''
		}}</mat-label>
		<button
			mat-icon-button
			[matMenuTriggerFor]="convoMenu"
			class="icon-spin setting-icon"
		>
			<mat-icon>settings</mat-icon>
		</button>
		<mat-menu #convoMenu="matMenu" xPosition="before">
			<button
				mat-menu-item
				(click)="leaveConversation($event)"
				*ngIf="user._id !== currentConvo.createdBy && !currentConvo.groupId && currentConvo.members?.length > 2"
			>
				<mat-icon>logout</mat-icon>
				Leave conversation
			</button>
			<button
				mat-menu-item
				(click)="editConversation($event)"
				*ngIf="user._id === currentConvo.createdBy && !currentConvo.groupId"
			>
				<mat-icon>edit</mat-icon>
				Edit conversation
			</button>
			<button
				mat-menu-item
				(click)="deleteConversation($event)"
				*ngIf="user._id === currentConvo.createdBy || currentConvo.members?.length === 2"
			>
				<mat-icon>delete</mat-icon>
				Delete conversation
			</button>
			<!-- <button mat-menu-item (click)="openScheduleMessageDialog()">
				<mat-icon>schedule</mat-icon>
				Schedule Message
			</button> -->
			<button mat-menu-item (click)="toggleNotification($event)">
				<mat-slide-toggle color="primary" [(ngModel)]="isNotificationEnabled" class="noti-toggle">Notifications Enabled</mat-slide-toggle>
			</button>
			<div class="flex-column center-container" style="margin: 10px 0; font-size: small; color: white;" *ngIf="elevatedPriv && currentConvo?.groupId" (click)="$event.stopPropagation()">
				<mat-label style="text-align: center; margin: 0 5px;">*Edit this conversation from the Groups tab</mat-label>
				<mat-label style="text-align: center;">of the My Team page</mat-label>
			</div>
		</mat-menu>
	</div>
	<div class="flex-fill" style="width: 100%">
		<div class="fill-container" id="message-list" *ngIf="!loadingMessages">
			<div
				*ngIf="convoMessages && convoMessages.length > 0"
				style="width: 100%"
			>
				<div
					class="flex-column"
					*ngFor="let message of convoMessages; let i = index"
				>
					<div class="flex-row" *ngIf="message.senderId !== user._id && (currentConvo.members?.length > 2 || currentConvo.gameid)">
						<mat-label class="sender-text" *ngIf="!currentConvo.gameid">{{listUsersName[message.senderId]}}</mat-label>
						<mat-label class="sender-text" *ngIf="currentConvo.gameid && message.user">{{message.user.firstname || ''}} {{ message.user.lastname || '' }}</mat-label>
					</div>
					<div
						class="flex-row"
						(mouseenter)="showReactionIcon(message._id)"
						(mouseleave)="hideReactionIcon()"
					>
						<div style="flex: 1" *ngIf="message.senderId === user._id"></div>
						<div
							class="message-list-item"
							[class.my-message]="message.senderId === user._id"
						>
							<div
								class="emoji-reaction"
								[style]="
									message.senderId === user._id ? 'right: 50%' : 'left: 50%'
								"
								*ngIf="message._id === selectedMessage"
							>
								<div
									class="emoji-react"
									(click)="addReactionEmoji('❤️')"
								>
									<ngx-emoji
										emoji=":heart:"
										[isNative]="true"
										style="height: 17px"
									></ngx-emoji>
								</div>
								<div class="emoji-react" (click)="addReactionEmoji('😂')">
									<ngx-emoji
										emoji=":joy:"
										[isNative]="true"
										style="height: 17px"
									></ngx-emoji>
								</div>
								<div
									class="emoji-react"
									(click)="addReactionEmoji('😮')"
								>
									<ngx-emoji
										emoji=":open_mouth:"
										[isNative]="true"
										style="height: 17px"
									></ngx-emoji>
								</div>
								<div class="emoji-react" (click)="addReactionEmoji('😢')">
									<ngx-emoji
										emoji=":cry:"
										[isNative]="true"
										style="height: 17px"
									></ngx-emoji>
								</div>
								<div
									class="emoji-react"
									(click)="addReactionEmoji('😠')"
								>
									<ngx-emoji
										emoji=":angry:"
										[isNative]="true"
										style="height: 17px"
									></ngx-emoji>
								</div>
								<div
									class="emoji-react"
									(click)="addReactionEmoji('👍')"
								>
									<ngx-emoji
										emoji=":thumbsup:"
										[isNative]="true"
										style="height: 17px"
									></ngx-emoji>
								</div>
								<div (click)="pickEmoji($event)" class="add-reaction">
									<mat-icon>add</mat-icon>
								</div>
							</div>
							<button
								(click)="showReactionPopup($event, message._id)"
								*ngIf="isShowReactionIcon === message._id"
								mat-icon-button
								matSuffix
								[style]="
									message.senderId === user._id
										? 'left: -35px'
										: 'right: -35px'
								"
								style="position: absolute; color: #676767;"
							>
								<mat-icon>tag_faces</mat-icon>
							</button>
							<button
								(click)="editMessage($event, message)"
								*ngIf="isShowReactionIcon === message._id && message.senderId === user._id"
								mat-icon-button
								matSuffix
								matTooltip="Edit Message"
								style="left: -70px; position: absolute; color: #676767"
							>
								<mat-icon [fontSet]="'material-icons-outlined'">edit</mat-icon>
							</button>
							<button
								(click)="deleteMessage($event, message)"
								*ngIf="isShowReactionIcon === message._id && message.senderId === user._id"
								mat-icon-button
								matSuffix
								matTooltip="Delete Message"
								style="left: -105px; position: absolute; color: #923737"
							>
								<mat-icon [fontSet]="'material-icons-outlined'">delete</mat-icon>
							</button>
							<button
								(click)="sendDirectMessage($event, message)"
								*ngIf="!currentConvo.gameid && isShowReactionIcon === message._id && message.senderId !== user._id && currentConvo?.members?.length > 2"
								mat-icon-button
								matSuffix
								matTooltip="Send Direct Message"
								style="right: -70px; position: absolute; color: #676767"
							>
								<mat-icon [fontSet]="'material-icons-outlined'">chat</mat-icon>
							</button>
							<mat-label
								class="message-timestamp"
								[style.text-align]="
									message.senderId === user._id ? 'right' : 'left'
								"
								>{{ formatDate(message.createdAt) }}</mat-label
							>
							<mat-label class="message-content" [innerHTML]="getMessageContents(message)"></mat-label>

							<div class="flex-row">
								<a
									*ngIf="message.file"
									download
									[href]="getFileUrl(message.file?.url)"
									target="_blank"
									class="file-preview-container"
								>
									<ng-container *ngIf="message.file.type !== 'image' && message.file.type !== 'video'">
										<span
											style="
												white-space: nowrap;
												overflow: hidden;
												text-overflow: ellipsis;
											"
											>{{ message?.file?.name || "" }}</span
										>
										<mat-icon>download</mat-icon>
									</ng-container>

									<img *ngIf="message.file.type === 'image'" [src]="getFileUrl(message.file.url)" class="image-preview">

									<app-video-image *ngIf="message.file.type === 'video'" [videoSrc]="getFileUrl(message.file.url)" [position]="0" [showPlayIcon]="true" style="width: 100%;"></app-video-image>
								</a>
							</div>

							<div
								(click)="
									showDialogListReaction(message.reaction, message._id)
								"
								class="emoji-reacted"
								[style]="
									message.senderId === user._id ? 'left: 2px' : 'right: 2px'
								"
								*ngIf="
									message.reaction &&
									getObjectKeys(message.reaction).length > 0
								"
							>
								<div *ngFor="let item of getObjectKeys(message.reaction), let i = index" style="height: 18px">
									<div *ngIf="i < emojiHasShow">{{ item }}</div>
								</div>
								<div *ngIf="getObjectKeys(message.reaction).length > emojiHasShow" class="reaction-count">{{getObjectKeys(message.reaction).length - emojiHasShow}}</div>
							</div>
						</div>
						<div style="flex: 1" *ngIf="message.senderId !== user._id"></div>
					</div>
				</div>
			</div>

			<div
				class="flex-row center-container"
				*ngIf="isLoadMore"
				style="margin: 5px 0"
			>
				<button
					mat-stroked-button
					(click)="loadMoreMessages()"
					*ngIf="!loadingMoreMessages"
				>
					Load More
				</button>
				<mat-spinner
					*ngIf="loadingMoreMessages"
					[diameter]="25"
				></mat-spinner>
			</div>
		</div>

		<div class="fill-container center-container" *ngIf="loadingMessages">
			<mat-spinner></mat-spinner>
		</div>
	</div>
	<div
		class="flex-row no-padding min-internal-padding"
		style="align-items: center"
	>
		<mat-form-field appearance="outline" style="flex: 1; margin: 0 10px">
			<input
				matInput
				type="text"
				[(ngModel)]="messageText"
				[disabled]="sendingMessage"
				(keyup.enter)="sendMessage()"
				(keyup.backspace)="setSelectionStart($event)"
				(keyup.arrowleft)="setSelectionStart($event)"
				(input)="setSelectionStart($event)"
				(click)="setSelectionStart($event)"
				id="message-input"
			/>
		</mat-form-field>
		<button
			mat-icon-button
			matSuffix
			(click)="pickEmoji($event)"
			matTooltip="Emoji"
			[ngStyle]="{ color: isSelectEmoji ? '#15c80f' : 'white' }"
		>
			<mat-icon>tag_faces</mat-icon>
		</button>
		<emoji-mart
			*ngIf="isSelectEmoji"
			(click)="clickEmoji($event)"
			(emojiClick)="addEmoji($event)"
			[showPreview]="false"
			[isNative]="true"
			[style]="{
				position: 'fixed',
				top: currentConvo.gameid ? undefined : '80px',
				bottom: currentConvo.gameid ? '80px' : undefined,
				right: '100px',
				'z-index': '10'
			}"
			[darkMode]="true"
			color="#15c80f"
		></emoji-mart>
		<ng-container *ngIf="!currentConvo.gameid">
			<input
				type="file"
				#fileInput
				style="display: none"
				(change)="onFileSelected($event)"
			/>
			<button
				[disabled]="this.currentUpload?.isUploading || this.currentEditMessage?._id"
				mat-icon-button
				matSuffix
				(click)="openFileSelector()"
				matTooltip="Upload File"
				[ngStyle]="{ color: 'white' }"
			>
				<mat-icon>attach_file</mat-icon>
			</button>
		</ng-container>
		<button
			mat-icon-button
			matSuffix
			color="primary"
			[disabled]="(!messageText && !currentUpload?.file) || sendingMessage || currentUpload?.isUploading"
			(click)="sendMessage()"
			matTooltip="Send Message"
			style="margin: 0 10px"
		>
			<mat-icon>send</mat-icon>
		</button>
	</div>
	<div
		class="flex-row center-container"
		*ngIf="currentUpload && currentUpload.file"
		style="margin: 10px 0"
	>
		<mat-label>Selected File: {{ currentUpload.file.name }}</mat-label>
	</div>
	<div class="flex-row" *ngIf="currentUpload && currentUpload.file">
		<mat-progress-bar
			color="primary"
			mode="determinate"
			[value]="currentUpload?.progress || 0"
			class="progress-bar"
		></mat-progress-bar>
	</div>
</div>