import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { throwError as observableThrowError, lastValueFrom, Subject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ApiHelperService } from './api-helper.service';
import {
  GenericAPIArrayResponse,
  GenericAPIResponse,
  LoginApiResponse,
} from '../models/api-response';
import {
  Conversation,
  MessageFile,
  Message,
  Reaction,
  ScheduleMessage,
} from '../models/chat';
import { User } from '../models/user';
import { TeamGroup } from '../models/team';

export interface ChatData {type: 'user' | 'group' | 'custom', user?: User, users?: User[], group?: TeamGroup, name?: string};
@Injectable({
  providedIn: 'root',
})
export class ChatService {

  private openChat: Subject<ChatData> = new Subject();
	private chatClosed: Subject<any> = new Subject();

  constructor(
    private http: HttpClient,
    private apiHelper: ApiHelperService,
    private router: Router
  ) {}

  listenToOpenChat(): Observable<ChatData> {
    return this.openChat.asObservable();
  }

  emitOpenChat(type: 'user' | 'group' | 'custom', user?: User, users?: User[], group?: TeamGroup, name?: string) {
    this.openChat.next({type, user, users, group, name});
  }

	listenToChatClosed(): Observable<any> {
		return this.chatClosed.asObservable();
	}

	emitChatClosed() {
		this.chatClosed.next(null);
	}

	getConversationName(convo: Conversation, userid: string) {
    if (!convo) return '';
    if (convo.name) return convo.name;
		const { members, users } = convo;
		if (members.length > 1) {
			let name = '';
			_.forEach(users, (user) => {
				if (user && user._id !== userid) {
					if (name.length > 0) name += ', ';
					name += `${user.firstname} ${user.lastname}`;
				}
			});
			if (name.length > 35) {
				name = name.substring(0, 35);
				name += '...';
			}
			return name;
		}
		else if (members.length === 1) {
			return`${users[0].firstname} ${users[0].lastname} (Me)`;
		}
		else return 'Unknown';
  }

  getConversations(): Promise<GenericAPIArrayResponse<Conversation>> {
    const url = `${this.apiHelper.apiUrl()}/chat/conversations`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(
      this.http
        .get<GenericAPIArrayResponse<Conversation>>(url, { headers })
        .pipe(
          map((res) => res),
          catchError((err) => {
            return observableThrowError(err.message);
          })
        )
    );
  }

  getOrCreateConversation(name: string, members: string[], createdBy: string, groupId?: string): Promise<GenericAPIResponse<Conversation>> {
    const url = `${this.apiHelper.apiUrl()}/chat/conversations`;
    const headers = this.apiHelper.apiHeaderWithToken();
		const body = { name, members, createdBy, groupId };

    return lastValueFrom(
      this.http
        .post<GenericAPIResponse<Conversation>>(url, body, { headers })
        .pipe(
          map((res) => res),
          catchError((err) => {
            return observableThrowError(err.message);
          })
        )
    );
  }

  updateConversation(
    _id: Conversation['_id'],
    conversation: Conversation
  ): Promise<GenericAPIResponse<Conversation>> {
    const url = `${this.apiHelper.apiUrl()}/chat/conversations`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = { conversationId: _id, update: { ...conversation } };
    return lastValueFrom(
      this.http
        .put<GenericAPIResponse<Conversation>>(url, body, { headers })
        .pipe(
          map((res) => res),
          catchError((err) => {
            return observableThrowError(err.message);
          })
        )
    );
  }

  leaveConversation(
    conversationId: Conversation['_id'],
		userid: User['_id']
  ): Promise<GenericAPIResponse<Conversation>> {
    const url = `${this.apiHelper.apiUrl()}/chat/leave-conversation`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = { conversationId, userid };

    return lastValueFrom(
      this.http
        .post<GenericAPIResponse<Conversation>>(url, body, { headers })
        .pipe(
          map((res) => res),
          catchError((err) => {
            return observableThrowError(err.message);
          })
        )
    );
  }

  getMessages(
    conversationId: string,
    messagesRequested: number
  ): Promise<GenericAPIArrayResponse<Message>> {
    const url = `${this.apiHelper.apiUrl()}/chat/messages/${conversationId}/${messagesRequested}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(
      this.http.get<GenericAPIArrayResponse<Message>>(url, { headers }).pipe(
        map((res) => res),
        catchError((err) => {
          return observableThrowError(err.message);
        })
      )
    );
  }

  updateStatusMessage(
    conversationId: string
  ): Promise<GenericAPIResponse<any>> {
    const url = `${this.apiHelper.apiUrl()}/chat/conversation/${conversationId}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(
      this.http.put<GenericAPIResponse<any>>(url, {}, { headers }).pipe(
        map((res) => res),
        catchError((err) => {
          return observableThrowError(err.message);
        })
      )
    );
  }

  deleteConversation(conversationId: string): Promise<GenericAPIResponse<any>> {
    const url = `${this.apiHelper.apiUrl()}/chat/conversation/${conversationId}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(
      this.http.delete<GenericAPIResponse<any>>(url, { headers }).pipe(
        map((res) => res),
        catchError((err) => {
          console.log(err);
          return observableThrowError(err.message);
        })
      )
    );
  }

  getGameConversation(gameid: string): Promise<GenericAPIResponse<Conversation>> {
    const url = `${this.apiHelper.apiUrl()}/chat/conversation/game/${gameid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(
      this.http
        .get<GenericAPIResponse<Conversation>>(url, { headers })
        .pipe(
          map((res) => res),
          catchError((err) => {
            return observableThrowError(err.message);
          })
        )
    );
  }

  createMessage(message: string, conversationId: string, file?: MessageFile): Promise<GenericAPIResponse<Message>> {
    const url = `${this.apiHelper.apiUrl()}/chat/messages`;
    const headers = this.apiHelper.apiHeaderWithToken();
		const body = {message, conversationId, file};

    return lastValueFrom(
      this.http.post<GenericAPIResponse<Message>>(url, body, { headers }).pipe(
        map((res) => res),
        catchError((err) => {
          console.log(err);
          return observableThrowError(err.message);
        })
      )
    );
  }

  deleteMessage(messageId: string): Promise<GenericAPIResponse<undefined>> {
    const url = `${this.apiHelper.apiUrl()}/chat/message/${messageId}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(
      this.http.delete<GenericAPIResponse<undefined>>(url, { headers }).pipe(
        map((res) => res),
        catchError((err) => {
          console.log(err);
          return observableThrowError(err.message);
        })
      )
    );
  }

  editMessageText(messageId: string, text: string): Promise<GenericAPIResponse<Message>> {
    const url = `${this.apiHelper.apiUrl()}/chat/message/${messageId}`;
    const headers = this.apiHelper.apiHeaderWithToken();
		const body = {text};

    return lastValueFrom(
      this.http.put<GenericAPIResponse<Message>>(url, body, { headers }).pipe(
        map((res) => res),
        catchError((err) => {
          console.log(err);
          return observableThrowError(err.message);
        })
      )
    );
  }

  addReactionEmoji(
    messageId: string,
    reaction: Reaction
  ): Promise<GenericAPIResponse<Message>> {
    const url = `${this.apiHelper.apiUrl()}/chat/messages-add-reaction/${messageId}`;
    const body = { reaction };
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(
      this.http.put<GenericAPIResponse<Message>>(url, body, { headers }).pipe(
        map((res) => res),
        catchError((err) => {
          return observableThrowError(err.message);
        })
      )
    );
  }

  getScheduleMessage(
    conversationId: string
  ): Promise<GenericAPIArrayResponse<ScheduleMessage>> {
    const url = `${this.apiHelper.apiUrl()}/chat/schedule-messages/${conversationId}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(
      this.http
        .get<GenericAPIArrayResponse<ScheduleMessage>>(url, {
          headers,
        })
        .pipe(
          map((res) => res),
          catchError((err) => {
            return observableThrowError(err.message);
          })
        )
    );
  }

  saveScheduleMessage(
    scheduleMessage: ScheduleMessage
  ): Promise<GenericAPIResponse<ScheduleMessage>> {
    const url = `${this.apiHelper.apiUrl()}/chat/schedule-messages`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(
      this.http
        .post<GenericAPIResponse<ScheduleMessage>>(
          url,
          { scheduleMessage },
          {
            headers,
          }
        )
        .pipe(
          map((res) => res),
          catchError((err) => {
            return observableThrowError(err.message);
          })
        )
    );
  }

  deleteScheduleMessage(
    scheduleMessageId: string
  ): Promise<GenericAPIResponse<ScheduleMessage>> {
    const url = `${this.apiHelper.apiUrl()}/chat/schedule-message/${scheduleMessageId}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(
      this.http.delete<GenericAPIResponse<any>>(url, { headers }).pipe(
        map((res) => res),
        catchError((err) => {
          return observableThrowError(err.message);
        })
      )
    );
  }

  createMessageFile(name: string): Promise<GenericAPIResponse<MessageFile>> {
    const url = `${this.apiHelper.apiUrl()}/chat/file-upload`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = { name };

    return lastValueFrom(
      this.http
        .post<GenericAPIResponse<MessageFile>>(url, body, { headers })
        .pipe(
          map((res) => res),
          catchError((err: any) => {
            console.log(err);
            return observableThrowError(err.message);
          })
        )
    );
  }
	
	uploadMessageFile(fileName: string, expiration: number, contentType: string): Promise<GenericAPIResponse<{uploadURL: string, file: MessageFile, url: string}>> {
    const url = `${this.apiHelper.apiUrl()}/chat/upload-file`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = { bucket: 'qwikbox', fileName, expiration, contentType };

    return lastValueFrom(
      this.http
        .post<GenericAPIResponse<{uploadURL: string, file: MessageFile, url: string}>>(url, body, { headers })
        .pipe(
          map((res) => res),
          catchError((err: any) => {
            console.log(err);
            return observableThrowError(err.message);
          })
        )
    );
  }
}
