import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { Buffer } from 'buffer';
import {throwError as observableThrowError, lastValueFrom, Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiHelperService } from './api-helper.service';
import { GenericAPIArrayResponse, GenericAPIResponse } from '../models/api-response';
import { StatAdjustment, StatColumn } from '../models/stats';
import { CustomStatChart, Report, StatReport, StatReportGame } from '../models/reports';
import { ExchangeRequest } from '../models/exchangerequest';
import { TeamShare } from '../models/game';
import { DefenseType, OffenseType, Team, TeamGroup, TeamMetrics, TeamTodo, TodoType } from '../models/team';
import { ConferenceType, Sport } from '../models/conference';
import { User, UserRoleValue } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  constructor(
    private http: HttpClient,
    private apiHelper: ApiHelperService,
    private router: Router
  ) { }

  getTeamUsers(teamid: string, userroles: UserRoleValue[]): Promise<GenericAPIArrayResponse<User>> {
    const url = `${this.apiHelper.apiUrl()}/teams/users/${teamid}`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {userroles};

    return lastValueFrom(this.http.put<GenericAPIArrayResponse<User>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getTeamAthletesWithMetrics(teamid: string): Promise<GenericAPIArrayResponse<User>> {
    const url = `${this.apiHelper.apiUrl()}/teams/athletes/${teamid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIArrayResponse<User>>(url, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  removeUserFromTeam(userid: string, role: UserRoleValue, teamid: string): Promise<GenericAPIResponse<User>> {
    const url = `${this.apiHelper.apiUrl()}/teams/remove-member`;
    const headers = this.apiHelper.apiHeaderWithToken()
    const body = {
      userid,
      role,
      teamid
    };

    return lastValueFrom(this.http.post<GenericAPIResponse<User>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  removeTeamMembers(teamid: string, userRoleData: {userid: string, role: UserRoleValue}[]): Promise<GenericAPIArrayResponse<User>> {
    const url = `${this.apiHelper.apiUrl()}/teams/remove-members`;
    const headers = this.apiHelper.apiHeaderWithToken()
    const body = {
      teamid,
      userRoleData
    };

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<User>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getTeamlevelTeams(teamlevelid: string): Promise<GenericAPIArrayResponse<Team>> {
    const url = `${this.apiHelper.apiUrl()}/teams/teamlevel/${teamlevelid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIArrayResponse<Team>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getOrganizationTeams(organizationid: string): Promise<GenericAPIArrayResponse<Team>> {
    const url = `${this.apiHelper.apiUrl()}/teams/organization/${organizationid}`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.get<GenericAPIArrayResponse<Team>>(url, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  createTeam(name: string, teamlevelid: string, organizationid: string, mascot?: string, initiateTrial: boolean = false, payForAccess?: boolean, accessPrice?: number): Promise<GenericAPIResponse<Team>> {
    const body = {
      name,
      teamlevelid,
      organizationid,
      mascot,
      initiateTrial,
			payForAccess,
			accessPrice
    };
    const url = `${this.apiHelper.apiUrl()}/teams`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<Team>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  searchTeams(searchText: string | null, sportid?: Sport, type?: ConferenceType, conferenceid?: string, countryid?: string, stateid?: string, teamlevelid?: string, activeSub?: boolean, thirdpartyid?: string): Promise<GenericAPIArrayResponse<Team>> {
    const body = {
      searchText,
      sportid,
      type,
      conferenceid,
      countryid,
      stateid,
      teamlevelid,
      activeSub,
			thirdpartyid
    }

    const url = `${this.apiHelper.apiUrl()}/teams/search`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<Team>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  updateTeam(_id: string, update: any): Promise<GenericAPIResponse<Team>> {
    const url = `${this.apiHelper.apiUrl()}/team/${_id}`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {update};

    return lastValueFrom(this.http.put<GenericAPIResponse<Team>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  updateTeamOffenseAndDefense(_id: string, offenseType: OffenseType, defenseType: DefenseType): Promise<GenericAPIResponse<Team>> {
    const url = `${this.apiHelper.apiUrl()}/team-offense-defense/${_id}`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {offenseType, defenseType};

    return lastValueFrom(this.http.put<GenericAPIResponse<Team>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  deleteTeam(_id: string): Promise<GenericAPIResponse<any>> {
    const url = `${this.apiHelper.apiUrl()}/team/${_id}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.delete<GenericAPIResponse<any>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getTeam(_id: string): Promise<GenericAPIResponse<Team>> {
    const url = `${this.apiHelper.apiUrl()}/team/${_id}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIResponse<Team>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getTeams(teamids: string[]): Promise<GenericAPIArrayResponse<Team>> {
    const url = `${this.apiHelper.apiUrl()}/teams`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {teamids};

    return lastValueFrom(this.http.put<GenericAPIArrayResponse<Team>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getGroups(teamid: string): Promise<GenericAPIArrayResponse<TeamGroup>> {
    const url = `${this.apiHelper.apiUrl()}/teams/groups/${teamid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIArrayResponse<TeamGroup>>(url, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  createGroup(teamid: string, name: string, groupmembers: string[]): Promise<GenericAPIResponse<TeamGroup>> {
    const url = `${this.apiHelper.apiUrl()}/teams/groups/${teamid}`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {name, groupmembers};

    return lastValueFrom(this.http.post<GenericAPIResponse<TeamGroup>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  updateGroup(groupid: string, update: any): Promise<GenericAPIResponse<TeamGroup>> {
    const url = `${this.apiHelper.apiUrl()}/teams/groups/${groupid}`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {update};

    return lastValueFrom(this.http.put<GenericAPIResponse<TeamGroup>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  deleteGroup(groupid: string): Promise<GenericAPIResponse<TeamGroup>> {
    const url = `${this.apiHelper.apiUrl()}/teams/groups/${groupid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.delete<GenericAPIResponse<TeamGroup>>(url, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getTeamShares(teamid: string, seasonid: number): Promise<GenericAPIArrayResponse<TeamShare>> {
    const body = {
      teamid,
      seasonid
    };
    const url = `${this.apiHelper.apiUrl()}/teams/shares`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<TeamShare>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  updateTeamShare(_id: string, update: any): Promise<GenericAPIResponse<TeamShare>> {
    const url = `${this.apiHelper.apiUrl()}/teams/shares/${_id}`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {update};

    return lastValueFrom(this.http.put<GenericAPIResponse<TeamShare>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  removeTeamShare(_id: String): Promise<GenericAPIResponse<undefined>> {
    const url = `${this.apiHelper.apiUrl()}/teams/shares/${_id}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.delete<GenericAPIResponse<undefined>>(url, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  createExchangeRequest(exchangeRequest: ExchangeRequest): Promise<GenericAPIResponse<ExchangeRequest>> {
    const body = exchangeRequest;
    const url = `${this.apiHelper.apiUrl()}/teams/exchange-requests`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.post<GenericAPIResponse<ExchangeRequest>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getExchangeRequests(): Promise<GenericAPIArrayResponse<ExchangeRequest>> {
    const url = `${this.apiHelper.apiUrl()}/teams/exchange-requests`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.get<GenericAPIArrayResponse<ExchangeRequest>>(url, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  updateExchangeRequest(_id: string, update: any): Promise<GenericAPIResponse<ExchangeRequest>> {
    const body = {update};
    const url = `${this.apiHelper.apiUrl()}/teams/exchange-requests/${_id}`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.put<GenericAPIResponse<ExchangeRequest>> (url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  revokeOnewayExchangeRequest(exchangerequestid: string): Promise<GenericAPIResponse<ExchangeRequest>> {
    const body = {exchangerequestid};
    const url = `${this.apiHelper.apiUrl()}/teams/exchange-requests-revoke`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<ExchangeRequest>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        return observableThrowError(err.message);
      }),));
  }

  revokeSingleTeamShare(gameid: string, teamid: string): Promise<GenericAPIArrayResponse<undefined>> {
    const body = {gameid, teamid};

    const url = `${this.apiHelper.apiUrl()}/teams/team-share-revoke`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<undefined>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => observableThrowError(err.message)),));
  }

  imageEncoderTeam(seasonid: number, filename: string, storagename: string, invalidate: boolean = false): Promise<GenericAPIResponse<undefined>> {
    const body = {
      seasonid,
      filename,
      storagename,
      invalidate
    };
    const url = `${this.apiHelper.apiUrl()}/teams/encoder`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<undefined>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  uploadFiletoS3(uploadUrl: string, contentType: string, file: File | ArrayBuffer): Observable<any> {
    const headers = new HttpHeaders({"Content-Type": contentType});
    return this.http.put(uploadUrl, file, { headers, reportProgress: true, observe: 'events' });
  }

  uploadFilePartToS3(uploadUrl: string, blob: Blob): Observable<any> {
    return this.http.put(uploadUrl, blob, { reportProgress: true, observe: 'events' });
  }

  createCustomReport(teamid: string, name: string, offensive: boolean, columns: StatColumn[]): Promise<GenericAPIResponse<Report>> {
    const body = {
      teamid,
      name,
      offensive,
      columns
    };
    const url = `${this.apiHelper.apiUrl()}/teams/custom-reports`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.post<GenericAPIResponse<Report>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getCustomReports(): Promise<GenericAPIArrayResponse<Report>> {
    const url = `${this.apiHelper.apiUrl()}/teams/custom-reports`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.get<GenericAPIArrayResponse<Report>>(url, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  updateCustomReport(_id: string, update: any): Promise<GenericAPIResponse<Report>> {
    const url = `${this.apiHelper.apiUrl()}/teams/custom-reports/${_id}`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {update};

    return lastValueFrom(this.http.put<GenericAPIResponse<Report>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  deleteCustomReport(_id: string): Promise<GenericAPIResponse<undefined>> {
    const url = `${this.apiHelper.apiUrl()}/teams/custom-reports/${_id}`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.delete<GenericAPIResponse<undefined>>(url, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getStatReports(seasonid: number): Promise<GenericAPIArrayResponse<StatReport>> {
    const url = `${this.apiHelper.apiUrl()}/teams/stat-reports`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {seasonid};

    return lastValueFrom(this.http.put<GenericAPIArrayResponse<StatReport>>(url, body, { headers }).pipe(
      map((res) => res),
      catchError((err) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  createStatReport(name: string, seasonid: number, userids: string[], groupids: string[], games: StatReportGame[], playlistid: string, filters: {name: string, values: any[]}[], statName: string, reportOn: string[], parentfolderid?: string): Promise<GenericAPIResponse<StatReport>> {
    const body = {
      name,
      seasonid,
			userids,
			groupids,
			games,
			playlistid,
			filters,
			statName,
			reportOn,
      parentfolderid
    }
    const url = `${this.apiHelper.apiUrl()}/teams/stat-reports`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<StatReport>>(url, body, { headers }).pipe(
      map((res) => res),
      catchError((err) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  updateStatReport(_id: string, update: any): Promise<GenericAPIResponse<StatReport>> {
    const body = {update};
    const url = `${this.apiHelper.apiUrl()}/teams/stat-reports/${_id}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.put<GenericAPIResponse<StatReport>>(url, body, { headers }).pipe(
      map((res) => res),
      catchError((err) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  deleteStatReport(_id: string): Promise<GenericAPIResponse<undefined>> {
    const url = `${this.apiHelper.apiUrl()}/teams/stat-reports/${_id}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.delete<GenericAPIResponse<undefined>>(url, { headers }).pipe(
      map((res) => res),
      catchError((err) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  createCustomStatChart(name: string, display: string): Promise<GenericAPIResponse<CustomStatChart>> {
    const body = {name, display};
    const url = `${this.apiHelper.apiUrl()}/teams/custom-stat-charts`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<CustomStatChart>>(url, body, { headers }).pipe(
      map((res) => res),
      catchError((err) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getCustomStatCharts(teamid: string): Promise<GenericAPIArrayResponse<CustomStatChart>> {
    const url = `${this.apiHelper.apiUrl()}/teams/custom-stat-charts/${teamid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIArrayResponse<CustomStatChart>>(url, { headers }).pipe(
      map((res) => res),
      catchError((err) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  deleteCustomStatChart(_id: string): Promise<GenericAPIResponse<undefined>> {
    const url = `${this.apiHelper.apiUrl()}/teams/custom-stat-charts/${_id}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.delete<GenericAPIResponse<undefined>>(url, { headers }).pipe(
      map((res) => res),
      catchError((err) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  moveAthletesToAnotherTeam(teamid: string, newTeamid: string): Promise<GenericAPIResponse<undefined>> {
    const body = {
      teamid,
      newTeamid
    };

    const url = `${this.apiHelper.apiUrl()}/teams/move-athletes`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<undefined>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  migrateTeams(newTeamid: string, sourceTeamid: string): Promise<GenericAPIResponse<undefined>> {
    const body = {
      newTeamid,
      sourceTeamid
    };

    const url = `${this.apiHelper.apiUrl()}/teams/migrate`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<undefined>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  submitStatAdjustment(teamid: string, gameid: string, playerNumber: number, columnName: string, amount: number) : Promise<GenericAPIResponse<any>> {
    const body = {
      teamid,
      gameid,
      playerNumber,
      columnName,
      amount
    }
    const url = `${this.apiHelper.apiUrl()}/teams/stat-adjustments`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.post<GenericAPIResponse<any>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getStatAdjustments(teamid: string, gameids: string[]) : Promise<GenericAPIArrayResponse<StatAdjustment>> {
    const body = {
      teamid,
      gameids
    }
    const url = `${this.apiHelper.apiUrl()}/teams/stat-adjustments`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.put<GenericAPIArrayResponse<StatAdjustment>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getTeamTodo(teamid: string) : Promise<GenericAPIResponse<TeamTodo>> {
    const url = `${this.apiHelper.apiUrl()}/teams/todo/${teamid}`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.get<GenericAPIResponse<TeamTodo>>(url, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  completeTodoTask(teamid: string, type: TodoType) : Promise<GenericAPIResponse<TeamTodo>> {
    const url = `${this.apiHelper.apiUrl()}/teams/todo/${teamid}`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {type};

    return lastValueFrom(this.http.post<GenericAPIResponse<TeamTodo>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  uncompleteTodoTask(teamid: string, type: TodoType) : Promise<GenericAPIResponse<TeamTodo>> {
    const url = `${this.apiHelper.apiUrl()}/teams/todo/${teamid}`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {type};

    return lastValueFrom(this.http.put<GenericAPIResponse<TeamTodo>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getAllPropsectData(sportid: Sport, gradYears: string[]) : Promise<GenericAPIArrayResponse<User>> {
    const url = `${this.apiHelper.apiUrl()}/teams/prospects`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {sportid, gradYears};

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<User>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getTeamMetrics(teamid: string, rangeStart?: Date, rangeEnd?: Date) : Promise<GenericAPIResponse<TeamMetrics>> {
    const url = `${this.apiHelper.apiUrl()}/teams/metrics/${teamid}`;
    const headers = this.apiHelper.apiHeaderWithToken();
		const body = {rangeStart, rangeEnd};

    return lastValueFrom(this.http.post<GenericAPIResponse<TeamMetrics>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getTeamMetricsForTeamlevel(teamlevelid: string, rangeStart: Date, rangeEnd: Date, conferenceid?: string, countryid?: string, stateid?: string) : Promise<GenericAPIArrayResponse<TeamMetrics>> {
    const url = `${this.apiHelper.apiUrl()}/teams/metrics/${teamlevelid}`;
    const headers = this.apiHelper.apiHeaderWithToken();
		const body = {rangeStart, rangeEnd, conferenceid, countryid, stateid};

    return lastValueFrom(this.http.put<GenericAPIArrayResponse<TeamMetrics>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }
}
