<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="'over'" [(opened)]="sideNavOpen">
    <mat-toolbar class="center-container" style="height: 75px;">
      <img src="assets/images/qwikcut-q-logo.png" style="height: 75%;" *ngIf="!apsThemeActive">
      <img src="assets/images/aps/aps_logo.png" style="height: 75%;" *ngIf="apsThemeActive">
    </mat-toolbar>
    <mat-nav-list>
      <a mat-list-item [routerLink]="'/third-party-dashboard'" (click)="sideNavOpen = false" *ngIf="thirdPartyUser">
        <mat-icon style="margin-right: 10px;">analytics</mat-icon>
        Third-Party Dashboard
      </a>
      <ng-container *ngIf="user?.team && !isShooter && !isStatManager && !isStatistician && !isFan && !isRecruiter">
        <a mat-list-item [routerLink]="'/home'" (click)="sideNavOpen = false" *ngIf="hasHomePage">
          <mat-icon style="margin-right: 10px;">home</mat-icon>
          Home
        </a>
        <a mat-list-item (click)="navigateToMyVideos(); sideNavOpen = false" *ngIf="(!isParent || !!teamSettings?.parentAbilities?.watchFilm) && !isProspect">
          <mat-icon style="margin-right: 10px;">ondemand_video</mat-icon>
          My Video
        </a>
        <a mat-list-item [routerLink]="'/my-video-continuous/stats'" (click)="sideNavOpen = false" *ngIf="sportid !== 'wrestling' && continuousSports.includes(sportid) && (!isParent || !!teamSettings?.parentAbilities?.viewStats) && !isProspect">
          <mat-icon style="margin-right: 10px;">leaderboard</mat-icon>
          Team Stats
        </a>
        <a mat-list-item [routerLink]="'/manage-video'" (click)="sideNavOpen = false" *ngIf="elevatedPriv && !leaguePoolAdmin">
          <mat-icon style="margin-right: 10px;">video_settings</mat-icon>
          Manage Video
        </a>
        <a mat-list-item [routerLink]="'/my-team'" (click)="sideNavOpen = false" *ngIf="!leaguePoolAdmin && !isProspect">
          <mat-icon style="margin-right: 10px;">groups</mat-icon>
          My Team
        </a>
        <a mat-list-item [routerLink]="'/team-stats'" (click)="sideNavOpen = false" *ngIf="sportid === 'football' && !leaguePoolOnly && (!isParent || !!teamSettings?.parentAbilities?.viewStats) && !isProspect">
          <mat-icon style="margin-right: 10px;">leaderboard</mat-icon>
          Team Stats
        </a>
        <a mat-list-item [routerLink]="'/my-fans'" (click)="sideNavOpen = false" *ngIf="canSwitchTeams || user?.currentUserRole?.role === 'teamadmin'">
          <mat-icon style="margin-right: 10px;">scoreboard</mat-icon>
          My Fans
        </a>
        <!-- <a mat-list-item href="#" (click)="sideNavOpen = false">
          <mat-icon style="margin-right: 10px;">connect_without_contact</mat-icon>
          My Fans
        </a> -->
        <a mat-list-item [routerLink]="'/playbook'" *ngIf="sportid === 'football' && !leaguePoolOnly && (!isParent || !!teamSettings?.parentAbilities?.viewPlaybook) && !isProspect" (click)="sideNavOpen = false">
          <mat-icon style="margin-right: 10px;">style</mat-icon>
          Playbook
        </a>
        <a mat-list-item [routerLink]="'/game-exchanges'" (click)="sideNavOpen = false" *ngIf="elevatedPriv && !leaguePoolOnly">
          <mat-icon style="margin-right: 10px;">markunread_mailbox</mat-icon>
          Exchange Video
        </a>
				<!-- <a mat-list-item [routerLink]="'/qwikcut-camera'" (click)="sideNavOpen = false" *ngIf="elevatedPriv && !leaguePoolOnly">
					<mat-icon style="margin-right: 10px;">videocam</mat-icon>
					QwikCut Camera(s)
				</a> -->
				<a mat-list-item [routerLink]="'/qwikcut-camera'" (click)="sideNavOpen = false" *ngIf="isAdmin">
					<mat-icon style="margin-right: 10px;">videocam</mat-icon>
					QwikCut Camera(s)
				</a>
      </ng-container>
      <a mat-list-item [routerLink]="'/recruiting-database'" (click)="sideNavOpen = false" *ngIf="isAdmin || isRecruiter">
        <mat-icon style="margin-right: 10px;">travel_explore</mat-icon>
        Recruiting Database
      </a>
      <a mat-list-item [routerLink]="'/saved-prospects'" (click)="sideNavOpen = false" *ngIf="isAdmin || isRecruiter">
        <mat-icon style="margin-right: 10px;">save</mat-icon>
        Saved Prospects
      </a>
      <a mat-list-item [routerLink]="'/stat-dashboard'" (click)="sideNavOpen = false" *ngIf="isStatManager">
        <mat-icon style="margin-right: 10px;">analytics</mat-icon>
        Stat Dashboard
      </a>
      <a mat-list-item [routerLink]="'/scouting/dashboard/trimming'" (click)="sideNavOpen = false" *ngIf="isStatManager">
        <mat-icon style="margin-right: 10px;">analytics</mat-icon>
        Trimming/ODK Dashboard
      </a>
      <a mat-list-item [routerLink]="'/qwikbuild-dashboard'" (click)="sideNavOpen = false" *ngIf="isStatManager">
        <mat-icon style="margin-right: 10px;">view_timeline</mat-icon>
        QwikBuild Dashboard
      </a>
      <!-- <a mat-list-item [routerLink]="'/film-review-dashboard'" (click)="sideNavOpen = false" *ngIf="isStatManager">
        <mat-icon style="margin-right: 10px;">preview</mat-icon>
        Film Review Dashboard
      </a> -->

      <a mat-list-item [routerLink]="'/scouting/evaluation-dashboard'" (click)="sideNavOpen = false" *ngIf="isScoutManager">
        <mat-icon style="margin-right: 10px;">inventory</mat-icon>
        Evaluation Dashboard
      </a>
      <a mat-list-item [routerLink]="'/scouting/evaluation-assignments'" (click)="sideNavOpen = false" *ngIf="isScout">
        <mat-icon style="margin-right: 10px;">assignment</mat-icon>
        My Assignments
      </a>
      <a mat-list-item [routerLink]="'/scouting/personnel'" (click)="sideNavOpen = false" *ngIf="isScoutManager">
        <mat-icon style="margin-right: 10px;">groups</mat-icon>
        Scout Personnel
      </a>
      <a mat-list-item [routerLink]="'/scouting/setup'" (click)="sideNavOpen = false" *ngIf="isScoutAdmin">
        <mat-icon style="margin-right: 10px;">settings</mat-icon>
        Scouting Configuration
      </a>
			<a mat-list-item [routerLink]="'/scouting/aps-athlete'" (click)="sideNavOpen = false" *ngIf="isProspect">
				<mat-icon style="margin-right: 10px;">home</mat-icon>
				My Evaluations
			</a>

      <a mat-list-item [routerLink]="'/camop-view-payroll'" (click)="sideNavOpen = false" *ngIf="isShooter">
        <mat-icon style="margin-right: 10px;">receipt_long</mat-icon>
        My Payroll
      </a>
      <a mat-list-item [routerLink]="'/camop-assignments'" (click)="sideNavOpen = false" *ngIf="isShooter">
        <mat-icon style="margin-right: 10px;">assignment</mat-icon>
        My Assignments
      </a>
      <a mat-list-item [routerLink]="'/analyst-dashboard/tasks'" (click)="sideNavOpen = false" *ngIf="isStatManager || isStatistician">
        <mat-icon style="margin-right: 10px;">assignment</mat-icon>
        Analyst Dashboard
      </a>
      <a mat-list-item [routerLink]="'/employee-documents'" (click)="sideNavOpen = false" *ngIf="isStatManager || isStatistician || isShooter">
        <mat-icon style="margin-right: 10px;">picture_as_pdf</mat-icon>
        Documents
      </a>
      <ng-container *ngIf="isFan">
        <a mat-list-item [routerLink]="'/fan'" (click)="sideNavOpen = false">
          <mat-icon style="margin-right: 10px;">home</mat-icon>
          Home
        </a>
        <a mat-list-item [routerLink]="'/fan/find-teams'" (click)="sideNavOpen = false">
          <mat-icon style="margin-right: 10px;">search</mat-icon>
          Find Teams and Games
        </a>
      </ng-container>
      <a mat-list-item [routerLink]="'/resources'" (click)="sideNavOpen = false" *ngIf="hasResources">
        <mat-icon style="margin-right: 10px;">history_edu</mat-icon>
        Resources
      </a>
      <a mat-list-item [routerLink]="'/highlights'" (click)="sideNavOpen = false" *ngIf="!isDemo && !leaguePoolOnly && !isShooter && !isStatManager && !isStatistician && !isRecruiter && !isScout && !isProspect && (!isParent || !!teamSettings?.parentAbilities?.watchFilm)" 
          matTooltip="View or build your next great highlight!" matTooltipPosition="right">
        <mat-icon style="margin-right: 10px;">movie</mat-icon>
        My Highlights
      </a>
      <a mat-list-item [routerLink]="'/hub'" (click)="sideNavOpen = false" matTooltip="View highlights from all QwikCut users" matTooltipPosition="right">
        <mat-icon style="margin-right: 10px;">movie_filter</mat-icon>
        Highlight Hub
      </a>
      <a mat-list-item [routerLink]="'/products'" (click)="sideNavOpen = false">
        <mat-icon style="margin-right: 10px;">shopping_cart</mat-icon>
        Marketplace
      </a>
      <a mat-list-item [routerLink]="'/videos-review'" (click)="sideNavOpen = false" *ngIf="isShooter">
        <mat-icon style="margin-right: 10px;">video_call</mat-icon>
        Video Review
      </a>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content class="fill-container">
    <div class="container" style="height: 100%;">

      <mat-toolbar class="toolbar flex-row" color="accent">
        <button
          type="button"
          aria-label="Toggle Side Menu"
          mat-icon-button
          (click)="drawer.toggle()"
          *ngIf="signedIn && !isStatistician">
          <mat-icon>menu</mat-icon>
        </button>
  
        <img src="assets/images/qwikcut-q-logo.png" style="height: 75%; cursor: pointer;" [routerLink]="'/'" *ngIf="!apsThemeActive">
				<img src="assets/images/aps/aps_logo_horizontal.png" style="height: 75%; cursor: pointer;" [routerLink]="'/scouting/aps-athlete'" *ngIf="apsThemeActive">

        <ng-container *ngIf="signedIn">
          <button mat-button [matMenuTriggerFor]="accountMenu" style="font-size: 1rem; max-width: 30vw;">
            <div class="flex-row" style="align-items: center;">
              <div class="button-text" style="flex: 1;">{{ getUserRoleTitle() }}</div>
              <mat-icon mat-list-icon>keyboard_arrow_down</mat-icon>
            </div>
          </button>
          <mat-menu #accountMenu="matMenu" xPosition="after" class="account-menu">
            <ng-container *ngFor="let section of userRoleSections">
              <mat-label class="account-section-header">{{ section.name }}</mat-label>
              <button mat-menu-item class="account-menu-btn" *ngFor="let userRole of section.userRoles" (click)="switchUserRole(userRole)" [disabled]="isCurrentUserRole(userRole)">
                <div class="flex-row" style="align-items: flex-start;">
                  <mat-icon>{{ getSportIconName(userRole) }}</mat-icon>
                  <div class="flex-column" style="align-items: flex-start;">
                    <mat-label style="font-size: larger; font-weight: bold;">{{ prettifyRole(userRole.role) }}</mat-label>
                    <mat-label *ngIf="userRole.team">{{ userRole.team.name + (userRole.team.teamlevel ? ' (' + userRole.team.teamlevel.name + ')' : '') }}</mat-label>
                    <mat-label *ngIf="userRole.team && userRole.team.conference">{{ userRole.team.conference.name }}</mat-label>
                    <mat-label *ngIf="userRole.leaguePool">{{ userRole.leaguePool.name }}</mat-label>
                    <mat-label *ngIf="userRole.thirdParty">{{ userRole.thirdParty.name }}</mat-label>
                  </div>
                </div>
              </button>
              <mat-divider style="margin-bottom: 15px;"></mat-divider>
            </ng-container>
          </mat-menu>

          <ng-container *ngIf="canSwitchTeams && organizationTeams?.length">
            <button mat-button [matMenuTriggerFor]="accountMenu" style="font-size: 1rem; max-width: 30vw; margin-left: 15px;">
              <div class="flex-row" style="align-items: center;">
                <div class="button-text" style="flex: 1;">{{ getCurrentTeamTitle() }}</div>
                <mat-icon mat-list-icon>keyboard_arrow_down</mat-icon>
              </div>
            </button>
            <mat-menu #accountMenu="matMenu" xPosition="after" class="account-menu">
              <ng-container *ngFor="let team of organizationTeams">
                <button mat-menu-item class="account-menu-btn" (click)="switchTeam(team)" [disabled]="user?.team?._id === team._id">
                  <div class="flex-row" style="align-items: flex-start;">
                    <div class="flex-column" style="align-items: flex-start;">
                      <mat-label style="font-size: larger; font-weight: bold;">{{ team.name }}</mat-label>
                      <mat-label *ngIf="team.teamlevel">{{ team.teamlevel.name }}</mat-label>
                    </div>
                  </div>
                </button>
              </ng-container>
            </mat-menu>
          </ng-container>
        </ng-container>

        <span class="spacer"></span>
        <ng-container *ngIf="signedIn">
					<div  class="flex-row" style="width: unset; align-items: center; margin: 0 10px; background-color: white; border-radius: 5px; height: 80%;">
						<ng-container *ngIf="!showingZohoChat">
							<mat-label style="color: black; margin: 0 5px; font-size: medium;">Need Support?</mat-label>
							<button mat-flat-button color="primary" class="zoho-chat-btn" (click)="toggleZohoChat()">Let's Chat</button>
						</ng-container>
						<ng-container *ngIf="showingZohoChat">
							<mat-label style="color: black; margin: 0 5px; font-size: medium;">Done Chatting?</mat-label>
							<button mat-flat-button color="primary" class="zoho-chat-btn" (click)="toggleZohoChat()">Hide Chat</button>
						</ng-container>
					</div>

          <div class="flex-column" style="margin-right: 10px; cursor: pointer; width: unset;" (click)="navigateToTeamStorage()" *ngIf="showStorage">
            <div class="flex-row center-container">
              <a style="font-size: small;" *ngIf="!(isHandset$ | async)">Storage: {{ totalStorageHours?.toFixed(1) }} / {{ totalStorageLimit }} hours</a>
              <a style="font-size: small;" *ngIf="(isHandset$ | async)">{{ totalStorageHours?.toFixed(1) }}/{{ totalStorageLimit }} hrs</a>
            </div>
            <div class="flex-row center-container" style="margin-top: 3px;">
              <mat-progress-bar [color]="getStorageBarColor()" mode="determinate" [value]="(totalStorageHours * 100 / totalStorageLimit)" class="progress-bar" [style.width]="(isHandset$ | async) ? '4rem' : '8rem'"></mat-progress-bar>
            </div>
          </div>

          <button mat-button [routerLink]="'/admin-dashboard'" *ngIf="isAdmin">Admin</button>
      
					<ng-container *ngIf="(elevatedPriv || isShooter || (isParent && !!teamSettings?.parentAbilities?.uploadFilm)) && !isStatistician && !isStatManager">
						<button mat-flat-button color="primary" [routerLink]="'/upload-video'" *ngIf="!(isHandset$ | async)">
							<mat-icon>cloud_upload</mat-icon>
							Upload
						</button>
						<button mat-icon-button color="primary" [routerLink]="'/upload-video'" *ngIf="(isHandset$ | async)">
							<mat-icon>cloud_upload</mat-icon>
						</button>
					</ng-container>

          <button mat-icon-button [matMenuTriggerFor]="chatMenu" #chatMenuTrigger="matMenuTrigger" [style.margin-left]="(isHandset$ | async) ? '0' : '15px'" style="position: relative;" *ngIf="!apsThemeActive">
            <mat-icon>message</mat-icon>
            <div class="count-lastmessage" *ngIf="filterConversations && filterConversations.length > 0">{{ filterConversations.length }}</div>
          </button>
          <mat-menu #chatMenu="matMenu" (closed)="onChatMenuClosed()">
						<app-messenger *ngIf="user" [user]="user" [elevatedPriv]="elevatedPriv"></app-messenger>
          </mat-menu>

          <button mat-icon-button [matMenuTriggerFor]="settingsMenu" [style.margin-left]="(isHandset$ | async) ? '0' : '15px'">
            <mat-icon>account_circle</mat-icon>
          </button>
          <mat-menu #settingsMenu="matMenu">
            <button mat-menu-item [routerLink]="'/user-account'">
              <mat-icon>manage_accounts</mat-icon>
              Manage Account
            </button>
            <button mat-menu-item (click)="manageUserNotifications()" *ngIf="!isProspect">
              <mat-icon>notifications</mat-icon> Manage Notifications
            </button>
            <button mat-menu-item [routerLink]="'/user-payments'">
              <mat-icon>payments</mat-icon>
              Payments
            </button>
            <a href="https://support.qwikcut.com" target="_blank" style="text-decoration: none;">
              <button mat-menu-item>
                <mat-icon>school</mat-icon>
                Knowledge Base
              </button>
            </a>
            <button mat-menu-item (click)="logout()">
              <mat-icon>logout</mat-icon>
              Logout
            </button>
          </mat-menu>
        </ng-container>
      </mat-toolbar>

      <!-- This is so the content scrolls separate from the toolbar -->
      <div class="flex-fill" style="flex: 1;">
        <div class="container" style="height: 100%;">
          <div class="fill-container">
            <router-outlet class="container"></router-outlet>
          </div>
        </div>
      </div>

    </div>
  </mat-sidenav-content>

</mat-sidenav-container>
