import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, isDevMode, OnInit, Output } from '@angular/core';
import { AthleteMetrics } from 'src/app/models/athlete-metrics';
import { User } from 'src/app/models/user';
import { ApiHelperService } from 'src/app/services/api-helper.service';
import { AthletemetricsService } from 'src/app/services/athletemetrics.service';
import { QwikboxService } from 'src/app/services/qwikbox.service';
import { TeamService } from 'src/app/services/team.service';
import { UserService } from 'src/app/services/user.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-user-hub-settings',
  templateUrl: './user-hub-settings.component.html',
  styleUrls: ['./user-hub-settings.component.css']
})
export class UserHubSettingsComponent implements OnInit {
  
  @Input() athlete: User;
  @Input() metrics: AthleteMetrics
  @Output() metricsChange: EventEmitter<AthleteMetrics> = new EventEmitter<AthleteMetrics>();

  loadingMetrics: boolean = false;
  uploadingBanner: boolean = false;
  uploadingLogo: boolean = false;
  uploadProgress: string = "0";

  constructor(
    private userService: UserService, 
    private teamService: TeamService,
    private metricsService: AthletemetricsService, 
    private helperService: ApiHelperService, 
    private qwikboxService: QwikboxService
  ) { }

  ngOnInit(): void {    
  }
  
  getBannerImage(): string {
    const defaultImage = "/assets/images/banner-default.png";
    const parentFolder = isDevMode() ? 'dev_social_media' : "social_media";    

    if(!this.metrics || !this.metrics.profile || !this.metrics.profile.banner ) {
      return defaultImage;
    }

    return `https://d2htis0rx2m2xo.cloudfront.net/${parentFolder}/${this.athlete._id}/${this.metrics.profile.banner}`;
  }

  getLogo(): string {
    const defaultImage = "/assets/images/portrait-placeholder.png";
    const parentFolder = isDevMode() ? 'dev_social_media' : "social_media"; 
    
    if(!this.metrics || !this.metrics.profile || !this.metrics.profile.picture) {
      return defaultImage;
    }

    return `https://d2htis0rx2m2xo.cloudfront.net/${parentFolder}/${this.athlete._id}/${this.metrics.profile.picture}`;
  }

  toggleUploadingSpinner(uploadTypeId: number) {
    //0 = banner image, 1 = logo, future uploads to increase id count
    if(uploadTypeId == 0) {
      this.uploadingBanner = !this.uploadingBanner;
    } else if (uploadTypeId == 1) {
      this.uploadingLogo = !this.uploadingLogo;
    }

    if(!this.uploadingBanner && !this.uploadingLogo) {
      this.uploadProgress = "0";
    }
  }

  getNameForTitle(): string {
    if (!this.athlete) { return ''; }

    //If window is 768 or less stack it
    const divider = +window.innerWidth > 768 ? " " : "<br>";

    let nick = this.metrics?.nickName ? `${divider}"${this.metrics.nickName}"${divider}` : ' ';
    return this.athlete.firstname + nick + this.athlete.lastname;
  }

  onBannerChanage(event: Event, uploadTypeId: number) {

    if(this.uploadingBanner || this.uploadingLogo) { return; }

    const input = event.target as HTMLInputElement;
    if(!input.files || !input.files.length) { return; }
    
    const file = input.files[0];
    const expriration = 43200; //12 hours
    const contentType = file.type;
    const name = uuidv4();
    const parentFolder = isDevMode() ? 'dev_social_media' : "social_media"; 
    const s3Key = `${parentFolder}/${this.athlete._id}/${name}`; // Qwikbox (bucket comes from presigned url call) -> social media (or dev version) folder -> user id -> filename
    
    this.qwikboxService.getPresignedUrlForUpload(s3Key, expriration, contentType).then( result => {
      this.toggleUploadingSpinner(uploadTypeId);
      this.teamService.uploadFiletoS3(result.result, contentType, file).subscribe({
        next: (event: HttpEvent<any>) => {
          
          if(event.type === HttpEventType.UploadProgress) {
            //progress
            this.uploadProgress = ((event.loaded / event.total) * 100).toFixed(2);
          } else if(event.type === HttpEventType.Response) {
            this.toggleUploadingSpinner(uploadTypeId);
            //0 = banner image, 1 = profile, future uploads to increase id count
            if(uploadTypeId == 0) {
              this.metrics.profile.banner = name;
            } else if (uploadTypeId == 1) {
              this.metrics.profile.picture = name;
            }

            this.metricsChange.emit(this.metrics);
          }
        },
        error: (error) => {
          console.error(error);
          this.helperService.showErrorResponse("There was an error uploading your file");
        }
      });
    }).catch(error => {
      console.error(error);
      this.helperService.showErrorResponse("There was an error preparing your file for upload");
    });
    
  }

  getRouterLink() {
    //Team detail
    if(this.athlete) {
      const uname = this.getStringForRouterLink(`${this.athlete.firstname}-${this.athlete.lastname}`);
    return ['/hub/detail', this.athlete._id, uname];
    }
    return ['#'];    
  }

  getDisplayUrl() {
    const uname = this.getStringForRouterLink(`${this.athlete.firstname}-${this.athlete.lastname}`);
    return `${this.getOrigin()}/hub/detail/${this.athlete._id}/${uname}`
  }

  getStringForRouterLink(input: string): string {
    const regex = / /gm;
    return String(input).replace(regex, '-'); //Replace all spaces with dashes, dont' want %20's.
  }

  getOrigin(): string {
    if(window) { return window.location.origin; }
    return ""; // Else no window object, may not be being run in a browser
  }


}
