import { Game, GameFolder } from './game';
import { Clip } from './clip';
import { Roster } from './roster'
import { User } from './user';
import { Favorite } from './favorites';


export class APIResponse {
  success: boolean;
  games?: any;
  game?: any;
  clips?: any;
  roster?: any;
  coaches?: any;
  user?: any;
  token?: any;
  conferences?: any;
  teamlevel?: any;
  teamlevels?: any;
  organizations?: any;
  apiData?: any;
  teams?: any;
  url?: any;
  stats?: any;
  shares?: any;
  membersInfo?: any;
  groups?: any;
  favorite?: any;
  favorites?: any;
  urls?: any;
  profile?: any;
  allusers?: any;
  users?: any;
  grades?: any;
  data?: any;
  shooters?: any;
  assignments?: any;
  reason?: any;
  shooter?: any;
  gameids?: any;
  userids?: any;
  version?: any;
  link?: any;
  contacts?: any;
  // When it is an error response, it contains a message.
  message?: string;
  inactives?: any;
  competitions?: any;
  signature?: string;
  teamfields?: any;
  accessKey?: any;
  secretKey?: any;
  orgs: any;
  states: any;
  presets?: any;
  signedUrl?: string;
  athletes?: any[];
  folders?: GameFolder[];
}


export interface LoginApiResponse {
  success: boolean;
  user?: User;
  token?: string;
}

export interface GenericAPIResponse<T> {
  success: boolean;
  result: T;
  message?: string;
}

export interface GenericAPIArrayResponse<T> {
  success: boolean;
  results: T[];
  message?: string;
}

export interface GenericAPIDoubleResponse<T, K> {
  success: boolean;
  results: T[];
	result: K;
  message?: string;
}

export interface GenericAPIInsertUpdateResponse<T> {
  message?: string;
  success: boolean;
  result?: T;
}

export interface GenericAPIPagedArrayResponse<T> {
  success: boolean;
  results: T[];
  pageSize: number;
  totalCount: number;
  message?: string;
}
