import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import {throwError as observableThrowError, lastValueFrom } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ApiHelperService } from './api-helper.service';
import { GenericAPIArrayResponse, GenericAPIResponse, LoginApiResponse } from '../models/api-response';

@Injectable({
  providedIn: 'root'
})
export class QwikboxService {

  constructor(
    private http: HttpClient,
    private apiHelper: ApiHelperService,
    private router: Router
  ) { }

  guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  markVideoReady(gameid: string, onlymerge = true, isZip = false, isEndzone = false): Promise<GenericAPIResponse<undefined>> {
    let body = {
      gameid,
      onlymerge,
      isZip,
      isEndzone
    }

    const url = `${this.apiHelper.apiUrl()}/qwikbox/videoReady`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<undefined>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getAwsCredentials(): Promise<any> {
    const url = `${this.apiHelper.apiUrl()}/qwikbox/getCredentials`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<any>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getPresignedUrlForUpload(filename: string, expiration: number, contentType: string): Promise<GenericAPIResponse<string>> {
    const url = `${this.apiHelper.apiUrl()}/qwikbox/getPresignedUrlForUpload`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {
      filename: filename,
      expiration: expiration,
      contentType: contentType,
      bucket: 'qwikbox'
    };

    return lastValueFrom(this.http.put<GenericAPIResponse<string>>(url, body, { headers }).pipe(
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      })));
  }

  getMultipartUploadPresignedUrls(filekey: string, parts: number, contentType: string): Promise<GenericAPIResponse<{uploadId: string, urls: Record<number, string>}>> {
    const url = `${this.apiHelper.apiUrl()}/qwikbox/getMultipartUploadPresignedUrls`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {
      filekey,
      parts,
      contentType: contentType
    };

    return lastValueFrom(this.http.put<GenericAPIResponse<{uploadId: string, urls: Record<number, string>}>>(url, body, { headers }).pipe(
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      })));
  }

  completeMultipartUpload(filekey: string, uploadId: string, parts: {ETag: string, PartNumber: number}[]): Promise<GenericAPIResponse<undefined>> {
    const url = `${this.apiHelper.apiUrl()}/qwikbox/complete-multipart`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {
      filekey,
      uploadId,
      parts
    };

    return lastValueFrom(this.http.post<GenericAPIResponse<undefined>>(url, body, { headers }).pipe(
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      })));
  }

  abortMultipartUpload(filekey: string, uploadId: string): Promise<GenericAPIResponse<undefined>> {
    const url = `${this.apiHelper.apiUrl()}/qwikbox/abort-multipart`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {
      filekey,
      uploadId
    };

    return lastValueFrom(this.http.post<GenericAPIResponse<undefined>>(url, body, { headers }).pipe(
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      })));
  }

  mediaconvertEncodeCustom(filename: string, storagename: string, audio: boolean = false): Promise<GenericAPIResponse<undefined>> {
    const url = `${this.apiHelper.apiUrl()}/qwikbox/custom-media-convert`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {
      filename,
      storagename,
			audio
    };

    return lastValueFrom(this.http.post<GenericAPIResponse<undefined>>(url, body, { headers }).pipe(
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      })));
  }
}
