import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../shared_modules/material/material.module';
import { SharedQwikcutModule } from 'src/app/shared_modules/qwikcut/shared-qwikcut.module'
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';

import { MessengerComponent } from './messenger.component';
import { ConversationComponent } from './conversation/conversation.component';


@NgModule({
  declarations: [
    MessengerComponent,
		ConversationComponent
  ],
	exports: [
		MessengerComponent,
		ConversationComponent
	],
  imports: [
    CommonModule,
    MaterialModule,
		SharedQwikcutModule,
    PickerModule,
    EmojiModule
  ]
})
export class MessengerModule { }
