import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import {throwError as observableThrowError, lastValueFrom } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ApiHelperService } from './api-helper.service';
import { GenericAPIArrayResponse, GenericAPIResponse, LoginApiResponse } from '../models/api-response';
import { TeamNotificationSettings, TeamSettings } from '../models/team';

@Injectable({
  providedIn: 'root'
})
export class TeamsettingsService {

  constructor(
    private http: HttpClient,
    private apiHelper: ApiHelperService,
    private router: Router
  ) { }

  getTeamSettings(teamid: string): Promise<GenericAPIResponse<TeamSettings>> {
    const url = `${this.apiHelper.apiUrl()}/teams/settings/team/${teamid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIResponse<TeamSettings>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  updateTeamSettings(teamid: string, update: Partial<TeamSettings>): Promise<GenericAPIResponse<TeamSettings>> {
    const body = {update};

    const url = `${this.apiHelper.apiUrl()}/teams/settings/team/${teamid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.put<GenericAPIResponse<TeamSettings>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  addCustomGrade(name: string, gradingStrategyId: string): Promise<GenericAPIResponse<TeamSettings>> {
    const body = {
      name,
      gradingStrategyId
    };

    const url = `${this.apiHelper.apiUrl()}/teams/settings/customgrades`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<TeamSettings>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  removeCustomGrade(_id: string): Promise<GenericAPIResponse<TeamSettings>> {
    const url = `${this.apiHelper.apiUrl()}/teams/settings/customgrades/${_id}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.delete<GenericAPIResponse<TeamSettings>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  updateCustomGrade(_id: string, update: any): Promise<GenericAPIResponse<TeamSettings>> {
    const body = {
      update
    };

    const url = `${this.apiHelper.apiUrl()}/teams/settings/customgrades/${_id}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.put<GenericAPIResponse<TeamSettings>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getTeamNotificationSettings(teamid: string): Promise<GenericAPIResponse<TeamNotificationSettings>> {
    const url = `${this.apiHelper.apiUrl()}/teams/notifications/${teamid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIResponse<TeamNotificationSettings>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  submitTeamNotificationsSettings(teamid: string, update: Partial<TeamNotificationSettings>): Promise<GenericAPIResponse<TeamNotificationSettings>> {
    const body = {update};
    const url = `${this.apiHelper.apiUrl()}/teams/notifications/${teamid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<TeamNotificationSettings>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }
}
