import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OverlayModule} from '@angular/cdk/overlay';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';

//Services
import { ApiHelperService } from '../app/services/api-helper.service';
import { LocalStorageService } from '../app/services/local-storage.service';
import { UserService } from '../app/services/user.service';
import { DialogService } from './services/dialog.service';

//shared modules
import { MaterialModule } from './shared_modules/material/material.module';
import { SharedQwikcutModule } from './shared_modules/qwikcut/shared-qwikcut.module'

//Guards
import { AuthGuard } from './guards/auth.guard';
import { TeamGuard } from './guards/team.guard';

import { NavComponent } from './components/nav/nav.component';
import { MessengerModule } from './components/messenger/messenger.module';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
		OverlayModule,
		MessengerModule,
    BrowserAnimationsModule,
    MaterialModule,
		SharedQwikcutModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-center',
      preventDuplicates: true
    }),
    NgxMatNativeDateModule
  ],
  providers: [
    AuthGuard,
    TeamGuard,
    ApiHelperService,
    LocalStorageService,
    UserService,
    DialogService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
