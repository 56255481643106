import { Injectable, isDevMode, OnDestroy, HostListener } from '@angular/core';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
import { io, Socket, ManagerOptions } from 'socket.io-client'
import { throwError as observableThrowError, Observable, Subscription, Subject } from 'rxjs';
import { Stream } from 'src/app/models/streams';
import { Angle, Clip } from '../models/clip';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { NotificationService } from 'src/app/services/notification.service';
import { User } from '../models/user';
import { Conversation, Message } from '../models/chat';
import { QwikcutCamera } from '../models/qwikcut-camera';

const STATISTICIAN_STATUS_ROOM_NAME = 'statistician-online-status';

@Injectable({
  providedIn: 'root',
})
export class SocketIOService implements OnDestroy {
  static socket: Socket;
	static idleTimeout: any;
	static isIdle: boolean = false;
  userUpdateSub: Subscription;
  lastUser: User;
  connectionIsActive: boolean = true;

  private activeConnectionChange = new Subject<boolean>();

  constructor(
    private localStorageService: LocalStorageService
  ) {
    this.setupSocketIo();
  }
  // @HostListener('window:beforeunload')
  ngOnDestroy(): void {
    if (this.userUpdateSub) this.userUpdateSub.unsubscribe();
    if (SocketIOService.socket) {
			SocketIOService.socket.disconnect();
      SocketIOService.socket = null;
    }
  }

  getSocketUrl(): string {
    if (isDevMode()) {
      return environment.local ? 'http://localhost:8080' : 'https://devapi.qwikcut.com/';
			// return 'https://devapi.qwikcut.com/';
      // return 'https://api.qwikcut.com/';
			// return 'http://192.168.0.221:8080/'; //local network testing
    } else {
      return 'https://api.qwikcut.com/';
    }
  }

  setupSocketIo() {
		if (SocketIOService.socket?.active && SocketIOService.socket?.id) return;

    SocketIOService.socket = io(this.getSocketUrl(), {
      transports: ['websocket'],
    });

    SocketIOService.socket.on('connect', () => {
			const user = this.localStorageService.getUser();
			this.processUserConnection(user);
    });

    this.userUpdateSub = this.localStorageService
      .listenForUserUpdate()
      .subscribe({
        next: (user) => {
					this.processUserConnection(user);
        },
      });

		this.onAppInteraction();
  }

	processUserConnection(user: User) {
		if (user?.currentUserRole?.role === 'fan') {
			this.listenForActiveConnections(user);
			this.emitConnectionEvent(user);
		}
		else if (_.includes(['statsapi', 'statmanager'], user?.currentUserRole?.role)) {
			this.emitStatisticianConnectionEvent(user);
		}
		else if (this.lastUser?.currentUserRole?.role) {
			if (this.lastUser.currentUserRole.role === 'fan') {
        this.emit('userDisconnect', this.lastUser._id);
			}
			else if (_.includes(['statsapi', 'statmanager'], this.lastUser.currentUserRole.role)) {
        this.emit('statisticianDisconnect', this.lastUser._id);
			}
		}
		this.lastUser = user;
	}

  listenForChatMessage(roomName: string) {
    return this.listen(roomName);
  }

  joinRoom(conversationId: string, userId: string) {
    SocketIOService.socket.emit('joinRoom', conversationId, userId);
  }

  leaveRoom(conversationId: string, userId: string) {
    SocketIOService.socket.emit('leaveRoom', conversationId, userId);
  }

  joinRoomLiveStream(gameid: string, userid: string) {
    SocketIOService.socket.emit('joinRoomLiveStream', gameid, userid);
  }

  leaveRoomLiveStream(gameid: string, userid: string) {
    SocketIOService.socket.emit('leaveRoomLiveStream', gameid, userid);
  }

	listenForStatisticianStatus(userid: string): Observable<{status: 'Online' | 'Offline' | 'Idle', idleTime?: Date, userid: string}> {
		SocketIOService.socket.emit('join-statistican-status', userid);
		return this.listen('statistician-status');
	}

	stopListeningForStatisticianStatus(userid: string) {
		SocketIOService.socket.emit('leave-statistican-status', userid);
	}

  listenForActiveConnections(user: User) {
    if (!SocketIOService.socket) return;
    if (this.lastUser) SocketIOService.socket.off(`activeConnections-${this.lastUser._id}`);

    if (!user) return;
    SocketIOService.socket.on(`activeConnections-${user._id}`, (socketids: string[]) => {
      this.connectionIsActive = _.includes(socketids, SocketIOService.socket.id);
      this.emitActiveConnectionChange();
    });
  }

  emitConnectionEvent(user: User) {
    if (user) {
      this.emit('userConnect', user._id);
    }
    if (this.lastUser) {
      if (this.lastUser._id !== user?._id) {
        this.emit('userDisconnect', this.lastUser._id);
      }
    }
  }
	
	emitStatisticianConnectionEvent(user: User) {
    if (user) {
      this.emit('statisticianConnect', user._id);
    }
    if (this.lastUser) {
      if (this.lastUser._id !== user?._id) {
        this.emit('statisticianDisconnect', this.lastUser._id);
      }
    }
	}

	onAppInteraction() {
		const user = this.localStorageService.getUser();
		if (!_.includes(['statsapi', 'statmanager'], user?.currentUserRole?.role)) return;
		// console.log('App Interaction');
		if (SocketIOService.isIdle) {
      this.emit('statisticianActive', user._id);
		}
		SocketIOService.isIdle = false;
		if (SocketIOService.idleTimeout) {
			clearTimeout(SocketIOService.idleTimeout);
			SocketIOService.idleTimeout = null;
		}
		SocketIOService.idleTimeout = setTimeout(() => {
			console.log('User is idle!');
			SocketIOService.isIdle = true;
      this.emit('statisticianIdle', user._id);
		}, 1000 * 60 * 5); //5 mins
	}

  activateConnection() {
		const user = this.localStorageService.getUser();
		if (!user?._id) return;
    this.emit('activateConnection', user._id);
  }

  listenForActiveConnectionChange(): Observable<boolean> {
    return this.activeConnectionChange.asObservable();
  }

  emitActiveConnectionChange() {
    this.activeConnectionChange.next(this.connectionIsActive);
  }

  emit(eventName: string, data?: any) {
    if (!SocketIOService.socket) return;
    SocketIOService.socket.emit(eventName, data);
  }

  listen(eventName: string): Observable<any> {
    if (!SocketIOService.socket) return null;
    return new Observable((observer) => {
      SocketIOService.socket.on(eventName, (data) => {
        observer.next(data);
      });
      return () => {
        SocketIOService.socket.removeListener(eventName);
      };
    });
  }

  listenForGameClips(gameid: string): Observable<Clip> {
    return this.listen(`${gameid}-clips`);
  }

  listenForGameStreamEvents(gameid: string): Observable<Stream> {
    return this.listen(`${gameid}-stream`);
  }

  listenForUpdatedUser(userid: string): Observable<User> {
    return this.listen(`${userid}-user-update`);
  }

  getGameCaptureEventName(gameid: string): string {
    return `${gameid}-game-capture`;
  }

  listenForGameCapture(
    gameid: string
  ): Observable<{ clipnumber: number; angle: Angle; starting: boolean }> {
    return this.listen(this.getGameCaptureEventName(gameid));
  }

  listenForNewMessage(conversationid: string): Observable<{
    text: string;
    sender: string;
    type: string;
    conversationid: string;
    id: string;
  }> {
    return this.listen(`${conversationid}-message`);
  }

	emitGameCaptureConnect(gameid: string, angle: Angle) {
    SocketIOService.socket.emit('gameCaptureConnect', gameid, angle);
	}

	emitGameCaptureDisconnect() {
    SocketIOService.socket.emit('gameCaptureDisconnect');
	}

  listenForGameCaptureConnect(gameid: string): Observable<{gameid: string, angle: Angle, socketid: string, disconnect: boolean}> {
    return this.listen(`gameCaptureConnect-${gameid}`);
  }

  listenForCameraUpdate(cameraId: string): Observable<QwikcutCamera> {
    return this.listen(`camera-update-${cameraId}`);
  }
}
