import { ComponentType } from '@angular/cdk/portal';
import { Component, Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import * as _ from 'lodash';
import { Annotation } from '../models/annotation';
import { AthleteMetrics, Person } from '../models/athlete-metrics';
import { Conference, Sport } from '../models/conference';
import { DownloadRequest } from '../models/downloads';
import { ClipInfo, Game, GameFolder, GameManagerMatchupGroup, GameRoster } from '../models/game';
import { GradingGrade, GradingGroup, GradingPosition, GradingStrategy, GradingString } from '../models/grading';
import { TeamLibraryFile } from '../models/medialibrary';
import { HubHighlightRecord } from '../models/hub';
import { Cutup, Playlist, PlaylistClip } from '../models/playlist';
import { College, CollegeCoachTwitter, Recruiter, RecruitingContact, RecruitmentAttribute, RecruitmentShare } from '../models/recruitment';
import { Report, StatReport, StatReportGame } from '../models/reports';
import { GameStats, StatColumn, StatColumnPreset, StatNote } from '../models/stats';
import { CustomGrade, Team, TeamGroup, TeamNotificationSettings, TeamSettings } from '../models/team';
import { SavedCard, User, UserNotificationSettings, UserProfile, UserRoleValue } from '../models/user';
import { Reaction } from '../models/chat';
import { Angle, Clip } from '../models/clip';
import { AvailableHighlightMusicItem, Highlight, HighlightClip } from '../models/highlight';
import { Organization } from '../models/organization';
import { PlaybookClip, PlaybookFolder, PlaybookPlay, PlaybookScript, PlaybookType } from '../models/playbook';
import { StripePayment, StripePaymentExtraInfo } from '../models/payments';
import { BreakdownPurchase, BreakdownRequest } from '../models/breakdowns';
import { School } from '../models/school';
import { Field, FieldPhoto, Region } from '../models/locations';
import { ShooterAssignment, ShooterInfo, WeatherDelay } from '../models/shooters';
import { TeamPersonnel } from '../models/gamepersonnel';
import { HighlightPreviewDialogComponent } from '../components/dialogs/highlight-preview-dialog/highlight-preview-dialog.component';
import { GameRating, LeaguePool } from '../models/leaguepool';
import { EmployeeDocument, Tutorial } from '../models/admin';
import { Favorite } from '../models/favorites';
import { FanSubscription, FanTournament } from '../models/fans';
import { Product } from '../models/product';
import { SingleInputDialog } from '../components/dialogs/single-input-dialog/single-input-dialog.component';
import { Conversation } from '../models/chat';
import { CamopNote } from 'src/app/models/shooters';
import { String } from 'lodash';
import { BillboardPost } from '../models/billboard';
import { QwikcutCamera, QwikcutCameraSoftware } from '../models/qwikcut-camera';
import { Tournament } from '../models/showcase';
import { ThirdParty, ThirdPartyTournament, ThirdPartyTournamentRequest } from '../models/third_parties';
import { InvitationCampaign } from '../models/invitation_campaigns';
import { ApsScoutingCategory, ScoutingEvaluation, ScoutingEvaluationRequest } from '../models/scouting';
import { ZortsPlayerCard } from '../models/zorts';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  private onSubmit = new Subject<any>();

  constructor(private dialog: MatDialog) {}

  readonly useBlur: boolean = true;

  listenForSubmit(): Observable<any> {
		return this.onSubmit.asObservable();
  }

  emitSubmit() {
   	this.onSubmit.next(true);
  }

  async openHighlightMusicDialog(): Promise<AvailableHighlightMusicItem> {
    const dialogName = 'highlight-music-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );

    const dialogComponent = chunk['HighlightMusicDialogComponent'] as  ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = false;
    dialogConfig.data = {};
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openHighlightPreviewDialog(highlight: Highlight): Promise<any> {
    const dialogName = 'highlight-preview-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = chunk['HighlightPreviewDialogComponent'] as  ComponentType<unknown>;
    if(!dialogComponent) { return; }
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = false;
    dialogConfig.data = {
      highlight
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openHighlightAddVideoDialog(hideTeamLibrary?: boolean, customButtonText?: string, customToolTipText?: string): Promise<HighlightClip[]> {
    const dialogName = 'highlight-add-video-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = chunk['HighlightAddVideoDialogComponent'] as  ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = false;
    dialogConfig.data = {
      hideTeamLibrary,
      customButtonText,
      customToolTipText
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openConfirmationDialog(body: string, title: string = '', confirmText: string = 'Confirm', cancelText: string = 'Cancel'): Promise<boolean> {
    const dialogName = 'confirmation-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      title,
      body,
			confirmText,
			cancelText
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openHighlightVideoDialog(highlight: HubHighlightRecord): Promise<string> {
    const dialogName = 'highlight-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = false;
    dialogConfig.data = {
      highlight
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openUserHubSettingsDialog(user: User, metrics: AthleteMetrics): Promise<string> {
    const dialogName = 'user-hub-settings-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = false;
    dialogConfig.data = {
      user: user,
      metrics: metrics
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openCamopNotesDialog(gameId: string): Promise<CamopNote[]> {
    const dialogName = 'camop-note-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.data = {
      gameId,
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openAdminNotesDialog(gameId: string) {
    const dialogName = 'admin-notes-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.data = { gameId };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openSimpleInputDialog(title: string, display: string, value?: string | number, type: 'text' | 'number' = 'text'): Promise<string> {
    const dialogName = 'simple-input-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      title,
      display,
      value,
      type
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openSingleInputDialog(
    singleInputDialog: SingleInputDialog
  ): Promise<string> {
    const dialogName = 'single-input-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = singleInputDialog;
    return firstValueFrom(
      this.dialog.open(dialogComponent, dialogConfig).afterClosed()
    );
  }

  openNewCategoryDialog(newCategoryDialog: {
    value?: string;
    type?: 'text' | 'number';
    validator: (value: string) => boolean;
  }): Promise<string> {
    return this.openSingleInputDialog({
      ...newCategoryDialog,
      title: 'New Category',
      display: 'Category Name',
      errorMessage: 'Name has been used for another Category',
    });
  }

  openEditCategoryDialog(newCategoryDialog: {
    value?: string;
    type?: 'text' | 'number';
    validator: (value: string) => boolean;
  }): Promise<string> {
    return this.openSingleInputDialog({
      ...newCategoryDialog,
      title: 'New Category',
      display: 'Category Name',
      errorMessage: 'Name has been used for another Category',
    });
  }

  async openGradingGroupDialog({
    title,
    value,
    type = 'text',
    groupList = [],
  }: {
    title: string;
    value?: string | number;
    type?: 'text' | 'number';
    groupList: GradingGroup[];
  }): Promise<string> {
    const dialogName = 'grading-group-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      title,
      value,
      type,
      groupList,
    };
    return firstValueFrom(
      this.dialog.open(dialogComponent, dialogConfig).afterClosed()
    );
  }

  async openGradingPositionDialog({
    title,
    positionName,
    type = 'text',
    groupList = [],
    selectedGroupId,
    positions = [],
  }: {
    title: string;
    positionName?: string | number;
    type?: 'text' | 'number';
    groupList: GradingGroup[];
    selectedGroupId: string;
    positions: GradingPosition[];
  }): Promise<{positionName: string, selectedGroupId: string}> {
    const dialogName = 'grading-position-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      title,
      positionName,
      type,
      groupList,
      selectedGroupId,
      positions,
    };
    return firstValueFrom(
      this.dialog.open(dialogComponent, dialogConfig).afterClosed()
    );
  }

  async openGradingStringDialog({
    title,
    value,
    type = 'text',
    stringList = [],
  }: {
    title: string;
    value?: string | number;
    type?: 'text' | 'number';
    stringList: GradingString[];
  }): Promise<string> {
    const dialogName = 'grading-string-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      title,
      value,
      type,
      stringList,
    };
    return firstValueFrom(
      this.dialog.open(dialogComponent, dialogConfig).afterClosed()
    );
  }

  async openSimpleDateInputDialog(title: string, display: string, value?: Date, includeTime?: boolean): Promise<Date> {
    const dialogName = 'simple-date-input-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      title,
      display,
      value,
			includeTime
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openTextBoxDialog(title: string, display: string, value?: string, readonly: boolean = false): Promise<string> {
    const dialogName = 'text-box-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      title,
      display,
      value,
      readonly
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openSimpleDropdownDialog(title: string, display: string, options: string[], value?: string, allowCustom?: boolean): Promise<string> {
    const dialogName = 'simple-dropdown-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      title,
      display,
      options,
      value,
      allowCustom
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openSimpleDropdownMultiDialog(title: string, display: string, options: {display: string, value: string}[], values: string[] = []): Promise<string[]> {
    const dialogName = 'simple-dropdown-multi-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      title,
      display,
      options,
			values
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openForgotPasswordDialog(email?: string): Promise<any> {
    const dialogName = 'forgot-password-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.data = email;
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openCreateGameDialog(user: User, seasonid: number, createEvent: boolean = false, parentfolderid?: string, hudlImport: boolean = false, teamid?: string, opponent?: string): Promise<Game> {
    const dialogName = 'create-game-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      user,
      seasonid,
      createEvent,
      parentfolderid,
      hudlImport,
			teamid,
			opponent
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openEditGameDialog(user: User, game: Game): Promise<Game> {
    const dialogName = 'edit-game-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      user,
      game
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openDownloadGameDialog(user: User, games: Game[]): Promise<DownloadRequest> {
    const dialogName = 'download-game-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      user,
      games
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openFolderSelectDialog(user: User): Promise<GameFolder> {
    const dialogName = 'folder-select-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      user
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openPlaylistSelectDialog(user: User): Promise<Playlist[]> {
    const dialogName = 'playlist-select-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      user
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openGameSelectDialog(user: User, teamid?: string): Promise<Game> {
    const dialogName = 'game-select-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      user,
      teamid
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openHudlImportDialog(user: User, game: Game): Promise<boolean> {
    const dialogName = 'hudl-import-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      user,
      game
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openHudlImportSeasonDialog(user: User, teamid: string, sportid: Sport): Promise<boolean> {
    const dialogName = 'hudl-import-season-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      user,
			teamid,
			sportid
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openImportMultipleGamesDialog(user: User, teamid: string, sportid: Sport, seasonid: number, parentfolderid: string): Promise<boolean> {
    const dialogName = 'import-multiple-games-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      user,
			teamid,
			sportid,
			seasonid,
			parentfolderid
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageFolderDialog(user: User, seasonid: number, folder?: GameFolder, parentfolderid?: string): Promise<GameFolder> {
    const dialogName = 'manage-folder-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      user,
      seasonid,
      folder,
      parentfolderid
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManagePlaylistDialog(user: User, seasonid?: number, playlist?: Playlist, parentfolderid?: string, clips?: PlaylistClip[]): Promise<Playlist> {
    const dialogName = 'manage-playlist-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      user,
      seasonid,
      playlist,
      parentfolderid,
      clips
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openViewPlaylistDialog(playlist: Playlist): Promise<any> {
    const dialogName = 'view-playlist-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      playlist
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageStatPresetsDialog(teamid: string, sportid: Sport, statPresets: StatColumnPreset[], statColumns: StatColumn[]): Promise<StatColumnPreset[]> {
    const dialogName = 'manage-stat-presets-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      teamid,
      sportid,
      statPresets,
      statColumns
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openImportBreakdownDialog(teamid: string, gameid: string, breakdownIsCsv: boolean): Promise<GameStats> {
    const dialogName = 'import-breakdown-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      teamid,
      gameid,
      breakdownIsCsv
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openImportBreakdownContinuousDialog(teamid: string, gameid: string, sportid: Sport): Promise<GameStats> {
    const dialogName = 'import-breakdown-continuous-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      teamid,
      gameid,
      sportid
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageCustomReportDialog(user: User, statColumns: StatColumn[], report?: Report): Promise<Report> {
    const dialogName = 'manage-custom-report-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      user,
      statColumns,
      report
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageSavedStatReportDialog(seasonid: number, games: StatReportGame[], playlistid?: string, statName?: string, reportOn?: string[], filters?: {name: string, values: any[]}[], originalSavedReport?: StatReport): Promise<StatReport> {
    const dialogName = 'manage-saved-stat-report-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[1] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      seasonid,
			games,
			playlistid,
			statName,
			reportOn,
			filters,
      originalSavedReport
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageTeamUserDialog(userRoles: UserRoleValue[], teamid?: string, originalUser?: User, sportid?: Sport, leaguepoolid?: string, payForAccess?: boolean, parentPrice?: number, thirdpartyid?: string): Promise<User> {
    const dialogName = 'manage-team-user-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      teamid,
      userRoles,
      originalUser,
      sportid,
      leaguepoolid,
			payForAccess,
      parentPrice,
			thirdpartyid
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageTeamGroupDialog(teamid: string, coaches: User[], athletes: User[], parents: User[], originalGroup?: TeamGroup): Promise<TeamGroup> {
    const dialogName = 'manage-team-group-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      teamid,
      coaches,
      athletes,
      parents,
      originalGroup
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageAnnotationsDialog(user: User, gameid: string, type: 'game' | 'clip' | 'group', clipnumber?: number, angle?: Angle, groupTime?: number): Promise<Annotation> {
    const dialogName = 'manage-annotations-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      user,
      gameid,
			type,
			clipnumber,
			angle,
			groupTime
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openStatAdjustmentDialog(teamid: string, gameid: string, playernumber: number, columnName: string, originalValue: number, adjustedValue: number): Promise<{adjustedValue: number, adjustmentAmount: number}> {
    const dialogName = 'stat-adjustment-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      teamid,
      gameid,
      playernumber,
      columnName,
      originalValue,
      adjustedValue
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openAthleteMetricsDialog(userid: string, teamid: string, sportid: Sport, elevatedPriv: boolean, metricsid?: string, isAdmin: boolean = false, coachData?: Person): Promise<AthleteMetrics> {
    const dialogName = 'athlete-metrics-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      userid,
      teamid,
      sportid,
      elevatedPriv,
			metricsid,
			isAdmin,
			coachData
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageRecruitingAttributesDialog(teamid: string): Promise<RecruitmentAttribute[]> {
    const dialogName = 'manage-recruiting-attributes-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      teamid
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openRecruitmentDialog(teamid: string, user: User, athlete: User): Promise<RecruitmentShare> {
    const dialogName = 'recruitment-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      teamid,
      user,
      athlete
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openRecruitingContactDialog(user: User, originalContact?: RecruitingContact): Promise<RecruitingContact> {
    const dialogName = 'manage-recruiting-contact-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      user,
      originalContact
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openCustomGradeDialog(teamid: string, gradingStrategies: GradingStrategy[], defaultGrade: boolean, defaultStrategyId: string, originalCustomGrade?: CustomGrade): Promise<TeamSettings> {
    const dialogName = 'custom-grade-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      teamid,
      gradingStrategies,
      defaultGrade,
      defaultStrategyId,
      originalCustomGrade
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openGradingScaleDialog(teamid: string, originalGradingScale?: GradingStrategy): Promise<GradingStrategy> {
    const dialogName = 'grading-scale-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      teamid,
      originalGradingScale
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageBreakdownDataDialog(teamid: string, sportid: Sport, existingStatColumns: StatColumn[], originalColumn?: StatColumn): Promise<StatColumn[]> {
    const dialogName = 'manage-breakdown-data-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      teamid,
      sportid,
      existingStatColumns,
      originalColumn
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openUploadMediaLibraryFilesDialog(teamid: string): Promise<TeamLibraryFile[]> {
    const dialogName = 'upload-media-library-files-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      teamid,
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openViewedClipDialog(userid: string): Promise<undefined> {
    const dialogName = 'viewed-clips-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      userid
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openImportUsersDialog(teamid: string, sportid: Sport, role: UserRoleValue, parentPrice?: number): Promise<User[]> {
    const dialogName = 'import-users-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      teamid,
      sportid,
			role,
			parentPrice
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openEditPersonnelDialog(teamid: string, roster: User[], selectedAthletes: number[], limit?: number, includeUnknown: boolean = false): Promise<{roster: User[], selectedAthletes: number[]}> {
    const dialogName = 'edit-personnel-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      teamid,
      roster,
      selectedAthletes,
      limit,
      includeUnknown
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openEditContinuousStatDialog(stat: any, homeRoster: User[], awayRoster: User[], game: Game): Promise<any> {
    const dialogName = 'edit-continuous-stat-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      stat,
      homeRoster,
      awayRoster,
      game
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openChangeAngleOrderDialog(angles: Angle[]): Promise<Angle[]> {
    const dialogName = 'change-angle-order-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      angles
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openWrestlingWinDialog(): Promise<number> {
    const dialogName = 'wrestling-win-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openEditClipInfoDialog(game: Game, clipInfo?: ClipInfo, homeRoster?: User[], awayRoster?: User[]): Promise<ClipInfo> {
    const dialogName = 'edit-clip-info-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      game,
      clipInfo,
      homeRoster,
      awayRoster
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageConferenceDialog(conference?: Conference): Promise<Conference> {
    const dialogName = 'manage-conference-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      conference
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageOrganizationDialog(organization?: Organization): Promise<Organization> {
    const dialogName = 'manage-organization-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      organization
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageTeamDialog(team?: Team): Promise<Team> {
    const dialogName = 'manage-team-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      team
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openSharePlaybookPlayDialog(user: User, plays: PlaybookPlay[], folders?: PlaybookFolder[]): Promise<{userids: string[], groupids: string[]}> {
    const dialogName = 'share-playbook-play-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      user,
      plays,
      folders
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openSendPlaybookPlaysDialog(user: User, plays: PlaybookPlay[]): Promise<any> {
    const dialogName = 'send-playbook-plays-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      user,
      plays
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openEditScriptColumnsDialog(script: PlaybookScript): Promise<PlaybookScript> {
    const dialogName = 'edit-script-columns-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      script
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openViewPlaybookClipsDialog(clips: PlaybookClip[]): Promise<any> {
    const dialogName = 'view-playbook-clips-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      clips
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManagePlaybookFolderDialog(teamid: string, playbookType: PlaybookType, playids?: string[], folder?: PlaybookFolder): Promise<PlaybookFolder> {
    const dialogName = 'manage-playbook-folder-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
			teamid,
			playbookType,
			playids,
			folder
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openDownloadPlaylistDialog(playlist: Playlist): Promise<any> {
    const dialogName = 'download-playlist-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      playlist
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openAddCardDialog(user: User = null, email: string = null, buttonText?: string): Promise<SavedCard> {
    const dialogName = 'add-card-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      user,
      email,
      buttonText
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openMakePaymentDialog(user: User, amount: number, description: string, type: number, extrainfo?: StripePaymentExtraInfo): Promise<StripePayment> {
    const dialogName = 'make-payment-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      user,
      amount,
      description,
      type,
      extrainfo
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openRequestBreakdownDialog(user: User, game: Game, teamid?: string, teamlevelid?: string): Promise<BreakdownRequest> {
    const dialogName = 'request-breakdown-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      user,
      game,
      teamid,
      teamlevelid
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openPurchaseBreakdownPlanDialog(user: User, sportid: Sport, team: Team, isAdmin?: boolean): Promise<BreakdownPurchase> {
    const dialogName = 'purchase-breakdown-plan-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      user,
      sportid,
      team,
      isAdmin
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageBreakdownPlansDialog(user: User, team: Team): Promise<undefined> {
    const dialogName = 'manage-breakdown-plans-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      user,
      team
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openEditBreakdownPlanDialog(purchase: BreakdownPurchase, sportid: Sport): Promise<BreakdownPurchase> {
    const dialogName = 'edit-breakdown-plan-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
			purchase,
			sportid
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openAssignmentGamesDialog(assignment: ShooterAssignment): Promise<undefined> {
    const dialogName = 'assignment-games-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      assignment
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openAdminManageUserDialog(user: User): Promise<User> {
    const dialogName = 'manage-user-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      user
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageSchoolOrganizationsDialog(school: School): Promise<School> {
    const dialogName = 'manage-school-organizations-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      school
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageSchoolDialog(school?: School): Promise<School> {
    const dialogName = 'manage-school-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      school
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openLinkOrganizationToSchoolDialog(schoolid: string): Promise<Organization> {
    const dialogName = 'link-organization-to-school-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      schoolid
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openSyncSchoolsToConferenceDialog(): Promise<boolean> {
    const dialogName = 'sync-schools-to-conference-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {};
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageFieldDialog(field?: Field): Promise<Field> {
    const dialogName = 'manage-field-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      field
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageRegionDialog(region?: Region): Promise<Region> {
    const dialogName = 'manage-region-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      region
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageCamopDialog(camop?: ShooterInfo): Promise<ShooterInfo> {
    const dialogName = 'manage-camop-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      camop
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openGameInfoDialog(game: Game, breakdownRequest: BreakdownRequest): Promise<undefined> {
    const dialogName = 'game-info-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      game,
      breakdownRequest
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openViewStatNotesDialog(statNotes: StatNote[], isEmployee: boolean = false): Promise<number> {
    const dialogName = 'view-stat-notes-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      statNotes,
      isEmployee
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openFormationSelectDialog(previousBackfield?: string, previousFormation?: string): Promise<{formation: string, backfield: string}> {
    const dialogName = 'formation-select-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.position = {right: '10px', bottom: '10px'};
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      previousBackfield,
      previousFormation
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openTeamSelectDialog(sport?: Sport, includeFilters: boolean = false): Promise<Team> {
    const dialogName = 'team-select-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      sport,
      includeFilters
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openUserSelectDialog(teamid?: string, userRoles?: UserRoleValue[]): Promise<User> {
    const dialogName = 'user-select-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      teamid,
			userRoles
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageCutupDialog(user: User, cutup: Cutup): Promise<undefined> {
    const dialogName = 'manage-cutup-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      user,
      cutup
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openBaseballBaserunnersDialog(baseRunnerInfo: {playernumber: number, newbase: number}[], athletes: User[], teamid: string): Promise<{playernumber: number, newbase: number}[]> {
    const dialogName = 'baseball-baserunners-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      baseRunnerInfo,
      athletes
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openBaseballGameFinishedDialog(game: Game, homeTeamPersonnel: TeamPersonnel, awayTeamPersonnel: TeamPersonnel, homeRoster: User[], awayRoster: User[], homeTeamWinning: boolean): Promise<{homeTeamPersonnel: TeamPersonnel, awayTeamPersonnel: TeamPersonnel}> {
    const dialogName = 'baseball-game-finished-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      game,
      homeTeamPersonnel,
      awayTeamPersonnel,
      homeRoster,
      awayRoster,
      homeTeamWinning
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageStorageDialog(team: Team): Promise<undefined> {
    const dialogName = 'manage-storage-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      team
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openCamopScheduleGameDialog(user: User): Promise<ShooterAssignment> {
    const dialogName = 'camop-schedule-game-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      user
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openTableDialog(tablename: string, columns: {display: string, value: string}[], rows: any[]): Promise<undefined> {
    const dialogName = 'table-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      tablename,
      columns,
      rows
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openEditLeaguePoolDialog(pool?: LeaguePool): Promise<LeaguePool> {
    const dialogName = 'edit-league-pool-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      pool
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageLeaguePoolTeamsDialog(user: User, leaguePool: LeaguePool): Promise<undefined> {
    const dialogName = 'manage-league-pool-teams-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      user,
			leaguePool
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openAddOrgsToLeaguePoolDialog(leaguepoolid: string): Promise<undefined> {
    const dialogName = 'add-orgs-to-league-pool-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      leaguepoolid
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openGameViewsDialog(game: Game): Promise<undefined> {
    const dialogName = 'game-views-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      game
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openRateVideoDialog(game: Game, gameRating?: GameRating): Promise<Game> {
    const dialogName = 'rate-video-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      game,
      gameRating
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openEditTeamOffenseDefenseDialog(team: Team): Promise<Team> {
    const dialogName = 'edit-team-offense-defense-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      team
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openLeaguePoolMessageDialog(leaguepoolid: string): Promise<undefined> {
    const dialogName = 'league-pool-message-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      leaguepoolid
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openLeaguePoolSettingsDialog(leaguepoolid: string): Promise<LeaguePool> {
    const dialogName = 'league-pool-settings-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      leaguepoolid
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openMatchupGamesDialog(group: GameManagerMatchupGroup): Promise<GameManagerMatchupGroup> {
    const dialogName = 'matchup-games-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      group
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openGamesInfoDialog(gameids: string[]): Promise<undefined> {
    const dialogName = 'games-info-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      gameids
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openAdminEditGameDialog(game?: Game, sportid?: Sport, fieldid?: string, subfield?: string, gameDate?: Date, seasonid?: number): Promise<Game> {
    const dialogName = 'admin-edit-game-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      game,
      sportid,
      fieldid,
      subfield,
      gameDate,
      seasonid
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openConfirmGamesDialog(user: User, games: Game[]): Promise<Game[]> {
    const dialogName = 'confirm-games-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      user,
      games
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openAdjustCamopPayDialog(assignmentids: string[], amount?: number, reason?: string, explanation?: string): Promise<{amount: number, reason: string, explanation: string} | null> {
    const dialogName = 'adjust-camop-pay-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      assignmentids,
      amount,
      reason,
      explanation
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageTutorialDialog(originalTutorial?: Tutorial): Promise<Tutorial> {
    const dialogName = 'manage-tutorial-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      originalTutorial
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openFanSubscriptionDialog(teamid: string, user: User, isAdmin: boolean = false): Promise<FanSubscription> {
    const dialogName = 'fan-subscription-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      teamid,
      user,
      isAdmin
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageFanSubscriptionsDialog(user: User): Promise<undefined> {
    const dialogName = 'manage-fan-subscriptions-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      user
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageFanRevenueDialog(team: Team): Promise<undefined> {
    const dialogName = 'manage-fan-revenue-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      team
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openFilmReviewVideoDialog(currentClip: Clip, allClips: Clip[], userRole: string = ''): Promise<undefined> {
    const dialogName = 'film-review-video-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      currentClip,
      allClips,
      userRole
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openCamopFilmReviewDialog(userid: string): Promise<undefined> {
    const dialogName = 'camop-filmreview-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      userid
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openEditMatchupDateDialog(date: Date, timeDifference?: number): Promise<{date: Date, timeDifference: number}> {
    const dialogName = 'edit-matchup-date-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      date,
      timeDifference
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageEmployeeDocumentDialog(document?: EmployeeDocument): Promise<EmployeeDocument> {
    const dialogName = 'manage-employee-document-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      document
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageRecruiterDialog(originalRecruiter?: Recruiter): Promise<Recruiter> {
    const dialogName = 'manage-recruiter-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      originalRecruiter
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openImportRecruitersDialog(leads: boolean = false): Promise<boolean> {
    const dialogName = 'import-recruiters-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      leads
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageCollegeDialog(originalCollege?: College): Promise<College> {
    const dialogName = 'manage-college-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      originalCollege
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openImportCollegesDialog(): Promise<boolean> {
    const dialogName = 'import-colleges-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {};
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openSelectCollegeDialog(twitterOnly: boolean = true): Promise<{college: College, athlete: User}> {
    const dialogName = 'select-college-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      twitterOnly
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openSelectRecruitersDialog(showAll: boolean = false): Promise<{recruiters: Recruiter[], athlete: User}> {
    const dialogName = 'select-recruiters-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      showAll
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageProductDialog(originalProduct?: Product): Promise<Product> {
    const dialogName = 'manage-product-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      originalProduct
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openBuildProspectSheetDialog(): Promise<boolean> {
    const dialogName = 'build-prospect-sheet-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openRecruitmentEmailDialog(user: User, athlete: User, highlight: Highlight, recruiters: Recruiter[]): Promise<RecruitmentShare> {
    const dialogName = 'recruitment-email-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      user,
      athlete,
      highlight,
      recruiters
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openGameRosterDialog(teamid: string, gameid: string): Promise<GameRoster> {
    const dialogName = 'game-roster-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
			teamid,
			gameid
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openVideoEmbedDialog(embed: string): Promise<undefined> {
    const dialogName = 'video-embed-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
			embed
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openImportProspectsDialog(sportid?: Sport): Promise<boolean> {
    const dialogName = 'import-prospects-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
			sportid
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openUploadFieldPhotoDialog(fieldid: string): Promise<FieldPhoto> {
    const dialogName = 'upload-field-photo-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
			fieldid
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openViewFieldPhotosDialog(fieldid: string): Promise<FieldPhoto> {
    const dialogName = 'view-field-photos-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
			fieldid
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openClipNotesDialog(
    athlete: User,
    userId: string,
    clipNumber: number,
    currentGame: GradingGrade,
    coaches: User[],
  ): Promise<GradingGrade> {
    const dialogName = 'clip-notes-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      athlete: athlete,
      userId: userId,
      clipNumber: clipNumber,
      currentGame: currentGame,
      coaches: coaches,
    };
    return firstValueFrom(
      this.dialog.open(dialogComponent, dialogConfig).afterClosed()
    );
  }

  async openShooterNotesDialog(notes : CamopNote[], elevatedPriv: boolean, role: string) {
    const dialogName = 'shooter-notes-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.data = {
      notes,
      elevatedPriv,
      role,
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openPreviewSetUpPhotoDialog(photoUrl: string, locationGps: string) {
    const dialogName = 'preview-setup-photo-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.data = {photoUrl, locationGps};
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openCamopDialog(camopIds: string[], camOpObject: Record<ShooterInfo['userid'], ShooterInfo>, camOps: ShooterInfo[]) {
    const dialogName = 'camop-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.data = {
      camopIds,
      camOpObject,
      camOps,
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }
	async openCreateConversationDialog(teamId: string, users: User[], teamGroups: TeamGroup[]): Promise<Conversation> {
		const dialogName = 'conversation-dialog';
		const chunk = await import(
			`../components/dialogs/${dialogName}/${dialogName}.component`
		);
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
		dialogConfig.data = { title: 'New Conversation', teamId, users, teamGroups };
			return firstValueFrom(
				this.dialog.open(dialogComponent, dialogConfig).afterClosed()
			);
	}

  async openWeatherDelaysDialog(weatherDelays: WeatherDelay[] = []): Promise<WeatherDelay[] | null> {
    const dialogName = 'weather-delays-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
		dialogConfig.data = { weatherDelays };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openEditConversationDialog(teamId: string, users: User[], teamGroups: TeamGroup[], conversation: Conversation): Promise<Conversation> {
    const dialogName = 'conversation-dialog';
		const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
		dialogConfig.data = { title: 'Edit Conversation', teamId, users, teamGroups, conversation };
    return firstValueFrom(
      this.dialog.open(dialogComponent, dialogConfig).afterClosed()
    );
	}

  async openCreateBillboardPostDialog(): Promise<BillboardPost> {
    const dialogName = 'billboard-post-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      title: 'Create Billboard Post',
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openEditBillboardPostDialog(post: BillboardPost): Promise<BillboardPost> {
    const dialogName = 'billboard-post-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      title: 'Edit Billboard Post',
      post: post,
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openEditBillboardPostContentDialog(content: string, type: string): Promise<BillboardPost> {
    const dialogName = 'billboard-post-content-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      content,
      type,
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

	async openShareGameDialog(user: User, games: Game[]): Promise<{shareUserids: string[], shareGroupids: string[]}> {
    const dialogName = 'share-game-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
			user,
			games
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openPreviewReactionDialog(reaction: Reaction, messageId: string, listUsersName: any): Promise<any> {
    const dialogName = 'preview-reaction-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );

    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = false;
    dialogConfig.data = {reaction, messageId, listUsersName};
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openScheduleMessageDialog(conversationId: string): Promise<any> {
    const dialogName = 'schedule-message-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );

    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = false;
    dialogConfig.data = {conversationId};
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openUploadCameraSoftwareDialog(software?: QwikcutCameraSoftware): Promise<QwikcutCameraSoftware> {
    const dialogName = 'upload-camera-software-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );

    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = false;
    dialogConfig.data = {software};
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageTeamNotificationsDialog(): Promise<TeamNotificationSettings> {
    const dialogName = 'manage-team-notifications-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );

    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = false;
    dialogConfig.data = {};
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageUserNotificationsDialog(userid: string, sportid: Sport): Promise<UserNotificationSettings> {
    const dialogName = 'manage-user-notifications-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );

    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = false;
    dialogConfig.data = {userid, sportid};
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageParentAbilitiesDialog(teamid: string, sportid: Sport): Promise<TeamSettings> {
    const dialogName = 'manage-parent-abilities-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );

    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = false;
    dialogConfig.data = {teamid, sportid};
		return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }
	
  async openManageTournamentDialog(tournament?: Tournament): Promise<Tournament> {
    const dialogName = 'manage-tournament-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      tournament
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openFanTournamentDialog(tournamentid: string, user: User, isAdmin: boolean = false): Promise<FanTournament> {
    const dialogName = 'fan-tournament-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      tournamentid,
      user,
      isAdmin
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openUserRolesDialog(): Promise<undefined> {
    const dialogName = 'user-roles-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openStatisticianRequestsDialog(statistician: User, sportid: Sport, rangeStart: Date, rangeEnd: Date): Promise<undefined> {
    const dialogName = 'statistician-requests-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
			statistician,
			sportid,
			rangeStart,
			rangeEnd
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageThirdPartyDialog(originalThirdParty?: ThirdParty): Promise<ThirdParty> {
    const dialogName = 'manage-third-party-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {originalThirdParty};
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }
	
  async openManageThirdPartyTournamentDialog(thirdPartyId: string, sports: Sport[] = [], tournament?: ThirdPartyTournament): Promise<ThirdPartyTournament> {
    const dialogName = 'manage-thirdparty-tournament-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
			thirdPartyId,
			sports,
      tournament
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openCompleteThirdPartyTournamentPurchaseDialog(request: ThirdPartyTournamentRequest, tournament: ThirdPartyTournament): Promise<{user: User, request: ThirdPartyTournamentRequest}> {
    const dialogName = 'complete-third-party-tournament-purchase-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
			request,
      tournament
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openThirdpartyTournamentGameInfoDialog(request: ThirdPartyTournamentRequest): Promise<any> {
    const dialogName = 'thirdparty-tournament-game-info-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
			request
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openTeamMetricsDialog(team: Team): Promise<undefined> {
    const dialogName = 'team-metrics-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
			team
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openThirdPartyTournamentLinkDialog(tournamentid: string): Promise<undefined> {
    const dialogName = 'third-party-tournament-link-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
			tournamentid
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageCoachTwitterDialog(originalCoachTwitter?: CollegeCoachTwitter): Promise<CollegeCoachTwitter> {
    const dialogName = 'manage-coach-twitter-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
			originalCoachTwitter
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openImportCoachTwittersDialog(): Promise<boolean> {
    const dialogName = 'import-coach-twitters-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {};
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageInvitationCampaignDialog(originalCampaign?: InvitationCampaign): Promise<InvitationCampaign> {
    const dialogName = 'manage-invitation-campaign-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
			originalCampaign
		};
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openCompleteInvitationCampaignDialog(campaign: InvitationCampaign, preview: boolean = false): Promise<InvitationCampaign> {
    const dialogName = 'complete-invitation-campaign-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
			campaign,
			preview
		};
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageQwikcutCameraDialog(camera: QwikcutCamera): Promise<QwikcutCamera> {
    const dialogName = 'manage-qwikcut-camera-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
			camera
		};
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageApsScoutingCategoryDialog(sportid: Sport, existingCategories: ApsScoutingCategory[], category?: ApsScoutingCategory): Promise<ApsScoutingCategory> {
    const dialogName = 'manage-aps-scouting-category-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
			sportid,
			existingCategories,
			category
		};
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openScoutingRequestCreateAccountDialog(request: ScoutingEvaluationRequest): Promise<{user: User, request: ScoutingEvaluationRequest}> {
    const dialogName = 'scouting-request-create-account-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
			request
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openScoutingRequestManageGamesDialog(user: User, request: ScoutingEvaluationRequest): Promise<ScoutingEvaluationRequest> {
    const dialogName = 'scouting-request-manage-games-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
			user,
			request
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManageScoutUserDialog(userRoles: UserRoleValue[], originalUser?: User, sportid?: Sport): Promise<User> {
    const dialogName = 'manage-scout-user-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      userRoles,
      originalUser,
      sportid
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openUserScoutEvaluationsdialog(userid?: string, requests: ScoutingEvaluationRequest[] = []): Promise<undefined> {
    const dialogName = 'user-scout-evaluations-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
			userid,
			requests
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openManagerUserProfileDialog(userid: string): Promise<UserProfile> {
    const dialogName = 'manage-user-profile-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
			userid
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openScoutingFeedbackPlaylistDialog(evalution: ScoutingEvaluation, clipIndex?: number): Promise<any> {
    const dialogName = 'scouting-feedback-playlist-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      evalution,
			clipIndex
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openZortsLoginDialog(): Promise<ZortsPlayerCard> {
    const dialogName = 'zorts-login-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }

  async openScoutingProsepctInfoDialog(request: ScoutingEvaluationRequest): Promise<ScoutingEvaluationRequest> {
    const dialogName = 'scouting-prospect-info-dialog';
    const chunk = await import(
      `../components/dialogs/${dialogName}/${dialogName}.component`
    );
    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    const dialogConfig = new MatDialogConfig();
    if (this.useBlur) dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      request
    };
    return firstValueFrom(this.dialog.open(dialogComponent, dialogConfig).afterClosed());
  }
}
