import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { Subject, Observable, throwError as observableThrowError, lastValueFrom } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ApiHelperService } from './api-helper.service';
import { GenericAPIArrayResponse, GenericAPIResponse, LoginApiResponse } from '../models/api-response';
import { AccountRequest, ClipView, EmailInfo, SavedCard, User, UserNotificationSettings, UserProfile, UserRole, UserRoleValue } from '../models/user';
import { UserSettings } from '../models/usersettings';
import { Login, TeamRequest } from '../models/team';
import { ClipPlaylist } from 'src/app/models/clip_playlist';
import { StripePayment, StripePaymentExtraInfo } from '../models/payments';
import { Tournament } from '../models/showcase';
import { Sport } from '../models/conference';
import { Favorite } from '../models/favorites';
import { continuousSports } from '../constants/sport.constants';
import { AthleteMetrics } from '../models/athlete-metrics';
import { InvitationCampaign } from '../models/invitation_campaigns';

@Injectable()
export class UserService {

  constructor(
    private http: HttpClient,
    private apiHelper: ApiHelperService,
    private router: Router
  ) { }


  private onLogout = new Subject();

  listenForLogout(): Observable<any> {
    return this.onLogout.asObservable();
  }

  emitLogout() {
    this.onLogout.next(null);
  }

  performInitialNavigation(user: User, currentRoute?: string) {
    let sportid: Sport;
    if (!user) return;

    let newRoute: string;
    if (user.currentUserRole?.role === 'shooter') newRoute = '/camop-assignments';
    // else if (user.currentUserRole?.role === 'statsapi') newRoute = '/analyst-dashboard';
    else if (user.currentUserRole?.role === 'statsapi') newRoute = '/analyst-dashboard/tasks';
    else if (user.currentUserRole?.role === 'statmanager') newRoute = '/stat-dashboard';
    else if (user.currentUserRole?.role === 'leagueadmin') newRoute = '/my-video';
    else if (user.currentUserRole?.role === 'fan') newRoute = '/fan';
		else if (user.currentUserRole?.role === 'recruiter') newRoute = '/recruiting-database';
		else if (['recruitingcoach'].includes(user.currentUserRole?.role)) newRoute = '/user-account';
		else if (user.currentUserRole?.role === 'prospect') newRoute = '/scouting/aps-athlete';
		else if (user.currentUserRole?.role === 'thirdparty') newRoute = '/third-party-dashboard';
		else if (['scoutadmin', 'scoutmanager'].includes(user.currentUserRole?.role)) newRoute = '/scouting/evaluation-dashboard';
		else if (user.currentUserRole?.role === 'scout') newRoute = '/scouting/evaluation-assignments';
    else {
      if (user.team?.conference) sportid = user.team.conference.sportid;
      else if (user.team?.sportid) sportid = user.team.sportid;
      else sportid = 'generic';
      
      const leaguePoolOnly = !_.includes(['admin', 'subadmin', 'junioradmin'], user.currentUserRole?.role) && !!user.team?.organization?.leaguepoolonly;
      const hasHomePage = !leaguePoolOnly && _.includes(['admin', 'subadmin', 'junioradmin', 'boardmember', 'teamadmin', 'coach', 'athlete', 'parent', 'thirdparty'], user.currentUserRole?.role);
      if (hasHomePage) newRoute = '/home';
      else if (!leaguePoolOnly && _.includes(continuousSports, sportid)) newRoute = '/my-video-continuous';
      else newRoute = '/my-video';
    }
    
    if (newRoute === currentRoute) window.location.reload();
    else this.router.navigate([newRoute]);
  }

  getUserRoleDisplay(userRole: UserRole) {
    if (!userRole || !userRole.role) return '';
    return this.prettifyRole(userRole.role);
  }
  
  prettifyRole(role: UserRoleValue): string {
    switch(role) {
      case 'subadmin':
        return 'Sub Admin';
      case 'junioradmin':
        return 'Junior Admin';
      case 'teamadmin':
        return 'Team Admin';
      case 'shooter':
        return 'Camera Operator';
      case 'statsapi':
        return 'Statistician';
      case 'statmanager':
        return 'Stat Manager';
      case 'single':
        return 'Single-User Account';
      case 'leagueadmin':
        return 'League Admin';
			case 'thirdparty':
				return 'Third Party';
			case 'scoutadmin':
				return 'Scout Admin';
			case 'scoutmanager': 
				return 'Scout Manager';
      default:
        return _.capitalize(role || '');
    }
  }
  
  //api calls
  authenticateUser(email: string, password: string): Promise<LoginApiResponse> {
    const url = `${this.apiHelper.apiUrl()}/users/authenticate`;
    const headers = this.apiHelper.apiHeader();
    const body = {email, password};

    return lastValueFrom(this.http.post<LoginApiResponse>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  loginAsUser(userid: string): Promise<LoginApiResponse> {
    const url = `${this.apiHelper.apiUrl()}/users/admin-login/${userid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<LoginApiResponse>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  switchUserRole(userRole: UserRole): Promise<GenericAPIResponse<User>> {
    const url = `${this.apiHelper.apiUrl()}/users/switch-role`;
    const headers = this.apiHelper.apiHeaderWithToken()
    const body = {userRole};

    return lastValueFrom(this.http.post<GenericAPIResponse<User>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getUser(): Promise<LoginApiResponse> {
    const url = `${this.apiHelper.apiUrl()}/user`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<LoginApiResponse>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        //console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getBoardmembersByOrgid(organizationid: string): Promise<GenericAPIArrayResponse<User>> {
    const body = {
      organizationid
    }
    const url = `${this.apiHelper.apiUrl()}/users/boardmembers-by-orgid`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<User>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  resendUserInvites(userids: string[], teamid: string): Promise<GenericAPIResponse<undefined>> {
    const body = {userids, teamid};
    const url = `${this.apiHelper.apiUrl()}/users/resend-invites`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.post<GenericAPIResponse<undefined>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  resendAllUserInvites(teamid: string): Promise<GenericAPIResponse<undefined>> {
    const body = {teamid};
    const url = `${this.apiHelper.apiUrl()}/users/resend-invites`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.put<GenericAPIResponse<undefined>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  setPassword(userid: string, password: string): Promise<GenericAPIResponse<User>> {
    const url = `${this.apiHelper.apiUrl()}/users/set-password`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {userid, password};

    return lastValueFrom(this.http.post<GenericAPIResponse<User>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  sendPasswordResetEmail(email: string): Promise<GenericAPIResponse<any>> {
    const url = `${this.apiHelper.apiUrl()}/users/password-reset`;
    const headers = this.apiHelper.apiHeader()
    const body = {email};

    return lastValueFrom(this.http.post<GenericAPIResponse<any>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getPasswordResetLink(email: string): Promise<GenericAPIResponse<string>> {
    const url = `${this.apiHelper.apiUrl()}/users/password-reset-link`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {email};

    return lastValueFrom(this.http.post<GenericAPIResponse<any>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  validatePasswordReset(token: string): Promise<GenericAPIResponse<any>> {
    const url = `${this.apiHelper.apiUrl()}/users/password-reset`;
    const headers = this.apiHelper.apiHeader();
    const body = {token};

    return lastValueFrom(this.http.put<GenericAPIResponse<any>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  createUsers(users: {username: string, firstname: string, lastname: string, phonenumber?: string, twitter?: string, role: UserRoleValue, teamid?: string, seasons?: number[], title?: string, leaguepoolid?: string, jerseyNumber?: number, subscriptionExpires?: Date, metrics?: AthleteMetrics, singleUser?: boolean, thirdpartyid?: string, teamname?: string}[], skipEmails: boolean = false): Promise<GenericAPIArrayResponse<User>> {
    const url = `${this.apiHelper.apiUrl()}/users/create`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {users, skipEmails};

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<User>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  searchUsers(searchText: string): Promise<GenericAPIArrayResponse<User>> {
    const url = `${this.apiHelper.apiUrl()}/users/search`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {searchText};

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<User>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getUserEmailData(userRoles: UserRoleValue[], sportid?: Sport, conferenceid?: string, countryid?: string, stateid?: string, organizationid?: string, subExpStartDate?: Date, subExpEndDate?: Date): Promise<GenericAPIArrayResponse<User>> {
    const url = `${this.apiHelper.apiUrl()}/users/get-email-data`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {
      userRoles,
      sportid,
      conferenceid,
      countryid,
      stateid,
      organizationid,
      subExpStartDate,
      subExpEndDate
    };

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<User>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  addDemoUser(username: string, firstname: string, lastname: string, sportid: string): Promise<GenericAPIResponse<User>> {
    const body = {
      username,
      firstname,
      lastname,
      sportid
    };
    const url = `${this.apiHelper.apiUrl()}/users/add-demo-account`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<User>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  updateUser(userid: string, update: any): Promise<GenericAPIResponse<User>> {
    const url = `${this.apiHelper.apiUrl()}/user`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {userid, update};

    return lastValueFrom(this.http.put<GenericAPIResponse<User>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  updateUserAndRole(userid: string, data: {teamid?: string, oldRole: UserRoleValue, username: string, firstname: string, lastname: string, phonenumber: string, newRole: UserRoleValue, seasons?: number[], 
		title?: string, leaguepoolid?: string, twitter?: string, jerseyNumber?: number, subscriptionExpires?: Date, singleUser?: boolean, thirdpartyid?: string, sportid?: Sport, positions?: string[], assignmentOrder?: {[position: string]: number}}): Promise<GenericAPIResponse<User>> {
    const url = `${this.apiHelper.apiUrl()}/user`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {
      userid,
      ...data
    };

    return lastValueFrom(this.http.post<GenericAPIResponse<User>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  updateUsers(updateData: {userid: string, update: any}[]): Promise<GenericAPIResponse<User>> {
    const url = `${this.apiHelper.apiUrl()}/users`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {updateData};

    return lastValueFrom(this.http.put<GenericAPIResponse<User>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  deleteUser(userid: string): Promise<GenericAPIResponse<undefined>> {
    const url = `${this.apiHelper.apiUrl()}/user/${userid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.delete<GenericAPIResponse<undefined>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }
  
  getUsersByIds(userids: string[]): Promise<GenericAPIArrayResponse<User>> {
    const body = {
      userids
    };
    const url = `${this.apiHelper.apiUrl()}/users/get-users-by-ids`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<User>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getListUsersName(userids: string[]): Promise<GenericAPIResponse<undefined>> {
    const body = {
      userids
    };
    const url = `${this.apiHelper.apiUrl()}/users/get-list-users-name`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<undefined>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  postUserSettings(userSettings: UserSettings): Promise<GenericAPIResponse<UserSettings>> {
    const body = {sports: userSettings.sports};

    const url = `${this.apiHelper.apiUrl()}/users/settings`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.post<GenericAPIResponse<UserSettings>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getUserSettings(): Promise<GenericAPIResponse<UserSettings>> {
    const url = `${this.apiHelper.apiUrl()}/users/settings`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.get<GenericAPIResponse<UserSettings>>(url, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  queryUsers(query: any): Promise<GenericAPIArrayResponse<User>> {
    const url = `${this.apiHelper.apiUrl()}/users`;
    const headers = this.apiHelper.apiHeader();

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<User>>(url, query, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  switchTeam(teamid: string): Promise<GenericAPIResponse<User>> {
    const url = `${this.apiHelper.apiUrl()}/users/switch-team`;
    const headers = this.apiHelper.apiHeaderWithToken()
    const body = {teamid};

    return lastValueFrom(this.http.post<GenericAPIResponse<User>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  addNewFanUser(email: string, firstname: string, lastname: string): Promise<GenericAPIResponse<User>> {
    const url = `${this.apiHelper.apiUrl()}/users/add-new-fan`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {
      email,
      firstname,
      lastname
    }

    return lastValueFrom(this.http.post<GenericAPIResponse<User>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getUserByUsername(username: string): Promise<GenericAPIResponse<User>> {
    const body = {
      username
    }
    const url = `${this.apiHelper.apiUrl()}/users/email-exists`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<User>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getUsersByTeamId(teamid: string): Promise<GenericAPIArrayResponse<User>> {
    const url = `${this.apiHelper.apiUrl()}/users/team/${teamid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIArrayResponse<User>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  saveLoginInfo(ip: string, region: string, city: string): Promise<GenericAPIResponse<Login>> {
    const url = `${this.apiHelper.apiUrl()}/users/login-info`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {
      ip,
      region,
      city
    }

    return lastValueFrom(this.http.post<GenericAPIResponse<Login>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getLoginInfo(userids: string[]): Promise<GenericAPIArrayResponse<Login>> {
    const url = `${this.apiHelper.apiUrl()}/users/login-info`;
    const headers = this.apiHelper.apiHeaderWithToken()
    const body = {userids};

    return lastValueFrom(this.http.put<GenericAPIArrayResponse<Login>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getUserById(userid: string): Promise<GenericAPIResponse<User>> {
    const url = `${this.apiHelper.apiUrl()}/users/get-user-by-id`;
    const headers = this.apiHelper.apiHeaderWithToken()
    const body = {
      userid
    }

    return lastValueFrom(this.http.post<GenericAPIResponse<User>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getPurchasedTournaments(): Promise<GenericAPIArrayResponse<Tournament>> {
    const url = `${this.apiHelper.apiUrl()}/users/purchased-tournaments`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIArrayResponse<Tournament>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  saveStripePayment(amount: number, description: string, token: string, customerid: string, type: number, extrainfo?: StripePaymentExtraInfo): Promise<GenericAPIResponse<StripePayment>> {
    const body = {
      amount,
      description,
      token,
      customerid,
      type,
      extrainfo
    }

    const url = `${this.apiHelper.apiUrl()}/users/stripe-payments`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<StripePayment>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getStripePayments(): Promise<GenericAPIArrayResponse<StripePayment>> {
    const url = `${this.apiHelper.apiUrl()}/users/stripe-payments`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIArrayResponse<StripePayment>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  deleteStripePayment(_id: string): Promise<GenericAPIResponse<any>> {
    const url = `${this.apiHelper.apiUrl()}/users/stripe-payments/${_id}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.delete<GenericAPIResponse<any>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getPurchasedGames(): Promise<GenericAPIArrayResponse<StripePayment>> {
    const url = `${this.apiHelper.apiUrl()}/users/purchased-games`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIArrayResponse<StripePayment>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getStripePaymentsByGameids(gameids: string[]): Promise<GenericAPIArrayResponse<StripePayment>> {
    const body = {
      gameids
    };
    const url = `${this.apiHelper.apiUrl()}/users/get-stripe-payments-by-gameids`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<StripePayment>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getStripePaymentsByTeamids(teamids: string): Promise<GenericAPIArrayResponse<StripePayment>> {
    const body = {teamids};
    const url = `${this.apiHelper.apiUrl()}/users/get-stripe-payments-by-teamids`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<StripePayment>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  saveCard(lastfour: string, cardtype: string, expirationdate: Date, token: string): Promise<GenericAPIResponse<SavedCard>> {
    const body = {
      lastfour,
      cardtype,
      expirationdate,
      token
    }
    const url = `${this.apiHelper.apiUrl()}/users/cards`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<SavedCard>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  createTemporaryCard(email: string, lastfour: string, cardtype: string, expirationdate: Date, token: string): Promise<GenericAPIResponse<SavedCard>> {
    const body = {
      email,
      lastfour,
      cardtype,
      expirationdate,
      token
    }
    const url = `${this.apiHelper.apiUrl()}/users/temp-card`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<SavedCard>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getCards(): Promise<GenericAPIArrayResponse<SavedCard>> {
    const url = `${this.apiHelper.apiUrl()}/users/cards`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIArrayResponse<SavedCard>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  deleteCard(_id: string): Promise<GenericAPIArrayResponse<any>> {
    const url = `${this.apiHelper.apiUrl()}/users/cards/${_id}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.delete<GenericAPIArrayResponse<any>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  chargeCreditCard(savedcardid: string, amount: number, description: string, type: number, extrainfo?: StripePaymentExtraInfo): Promise<GenericAPIResponse<StripePayment>> {
    const body = {
      savedcardid,
      amount,
      description,
      type,
      extrainfo
    }
    const url = `${this.apiHelper.apiUrl()}/users/charge-card`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<StripePayment>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  chargeCreditCardNonUser(email: string, customerid: string, amount: number, description: string, type: number, extrainfo?: StripePaymentExtraInfo): Promise<GenericAPIResponse<StripePayment>> {
    const body = {
      email,
      customerid,
      amount,
      description,
      type,
      extrainfo
    }
    const url = `${this.apiHelper.apiUrl()}/users/charge-card-non-user`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<StripePayment>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  mediaEncoder(filename: string, gameid: string, clipnumber: number, videoType: string, removeAudio: boolean = false): Promise<GenericAPIResponse<any>> {
    const body = {
      gameid,
      clipnumber,
      filename,
      videoType,
      removeAudio
    }

    const url = `${this.apiHelper.apiUrl()}/users/media-encode`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<any>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  imageEncoder(filename: string, storagename: string, invalidate: boolean = false): Promise<GenericAPIResponse<any>> {
    const body = {
      filename,
      storagename,
      invalidate
    };
    const url = `${this.apiHelper.apiUrl()}/users/image-encode`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.put<GenericAPIResponse<any>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  requestGameEmail(gamename: string, gamedate: Date, notes: string, email: string): Promise<GenericAPIResponse<any>> {
    const body = {
      gamename,
      gamedate,
      notes,
      email
    }
    const url = `${this.apiHelper.apiUrl()}/users/request-game`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.post<GenericAPIResponse<any>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }
  
  createAccountRequest(email: string, firstname: string, lastname: string, phonenumber: string, userrole: UserRoleValue, orgtype: string, sportid: Sport, 
        gender: string, teamname: string, division: string, countryid: string, stateid: string, extrainfo: string, invitecode?: string): Promise<GenericAPIResponse<AccountRequest>> {
    const body = {
      email,
      firstname,
      lastname,
      phonenumber,
      invitecode,
      userrole,
      orgtype,
      sportid,
      gender,
      teamname,
      division,
      countryid,
      stateid,
      extrainfo
    }
    const url = `${this.apiHelper.apiUrl()}/users/account-request`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.post<GenericAPIResponse<AccountRequest>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getAccountRequests(start: number = 0, pageSize: number = 50): Promise<GenericAPIArrayResponse<AccountRequest>> {
    const url = `${this.apiHelper.apiUrl()}/users/get-account-requests`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {start, pageSize};

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<AccountRequest>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getNumOfAccountRequests(): Promise<GenericAPIResponse<number>> {
    const url = `${this.apiHelper.apiUrl()}/users/get-account-requests`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIResponse<number>>(url, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  updateAccountRequest(_id: string, update: any): Promise<GenericAPIResponse<AccountRequest>> {
    const url = `${this.apiHelper.apiUrl()}/users/account-request`;
    const headers = this.apiHelper.apiHeaderWithToken()
    const body = {
      _id,
      update
    };

    return lastValueFrom(this.http.put<GenericAPIResponse<AccountRequest>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  sendAccountDenyEmail(email: string, message: string): Promise<GenericAPIResponse<any>> {
    const body = {
      email,
      message
    };
    const url = `${this.apiHelper.apiUrl()}/users/deny-email`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<any>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  createTeamRequest(email: string, firstname: string, lastname: string, teamid: string, userrole: UserRoleValue = 'athlete', phonenumber?: string, jerseyNumber?: number): Promise<GenericAPIResponse<TeamRequest>> {
    const body = {
      email,
      firstname,
      lastname,
      teamid,
      userrole,
			phonenumber,
			jerseyNumber
    };
    const url = `${this.apiHelper.apiUrl()}/users/team-request`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.post<GenericAPIResponse<TeamRequest>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getTeamRequests(teamid: string): Promise<GenericAPIArrayResponse<TeamRequest>> {
    let body = {teamid};

    const url = `${this.apiHelper.apiUrl()}/users/get-team-requests`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<TeamRequest>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  updateTeamRequest(_id: string, update: any): Promise<GenericAPIResponse<TeamRequest>> {
    const url = `${this.apiHelper.apiUrl()}/users/team-request`;
    const headers = this.apiHelper.apiHeaderWithToken()
    const body = {
      _id,
      update
    };

    return lastValueFrom(this.http.put<GenericAPIResponse<TeamRequest>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  approveTeamRequest(_id: string): Promise<GenericAPIResponse<User>> {
    const url = `${this.apiHelper.apiUrl()}/users/approve-team-request`;
    const headers = this.apiHelper.apiHeaderWithToken()
    const body = {
      _id
    };

    return lastValueFrom(this.http.post<GenericAPIResponse<User>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getUserIdByOldId(oldid: number): Promise<GenericAPIResponse<string>> {
    const url = `${this.apiHelper.apiUrl()}/users/get-userid-by-oldid/${oldid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIResponse<string>>(url, { headers }).pipe(
      map((res) => res),
      catchError((err) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  broadcastSocketio(eventName: string, data: any): Promise<GenericAPIResponse<undefined>> {
    const url = `${this.apiHelper.apiUrl()}/users/broadcast-socketio`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {eventName, data};

    return lastValueFrom(this.http.post<GenericAPIResponse<undefined>>(url, body, { headers }).pipe(
      map((res) => res),
      catchError((err) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getClipPlaylists(): Promise<GenericAPIArrayResponse<ClipPlaylist>> {
    const url = `${this.apiHelper.apiUrl()}/users/get-clip-playlist`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.get<GenericAPIArrayResponse<ClipPlaylist>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        return observableThrowError(err.message);
      })));
  }

  getUserNotificationSettings(userid: string): Promise<GenericAPIResponse<UserNotificationSettings>> {
    const url = `${this.apiHelper.apiUrl()}/users/notifications/${userid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIResponse<UserNotificationSettings>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  submitUserNotificationsSettings(userid: string, update: Partial<UserNotificationSettings>): Promise<GenericAPIResponse<UserNotificationSettings>> {
    const body = {update};
    const url = `${this.apiHelper.apiUrl()}/users/notifications/${userid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<UserNotificationSettings>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getUserProfile(userid: string): Promise<GenericAPIResponse<UserProfile>> {
    const url = `${this.apiHelper.apiUrl()}/users/profile/${userid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIResponse<UserProfile>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  submitUserProfile(userid: string, update: Partial<UserProfile>): Promise<GenericAPIResponse<UserProfile>> {
    const body = {update};
    const url = `${this.apiHelper.apiUrl()}/users/profile/${userid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<UserProfile>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  createInvitationCampaign(data: Partial<InvitationCampaign>): Promise<GenericAPIResponse<InvitationCampaign>> {
    const url = `${this.apiHelper.apiUrl()}/users/invitation-campaign`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<InvitationCampaign>>(url, data, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getAllInvitationCampaigns(): Promise<GenericAPIArrayResponse<InvitationCampaign>> {
    const url = `${this.apiHelper.apiUrl()}/users/invitation-campaign`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIArrayResponse<InvitationCampaign>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  updateInvitationCampaign(_id: string, update: Partial<InvitationCampaign>): Promise<GenericAPIResponse<InvitationCampaign>> {
    const url = `${this.apiHelper.apiUrl()}/users/invitation-campaign/${_id}`;
    const headers = this.apiHelper.apiHeaderWithToken();
		const body = {update};

    return lastValueFrom(this.http.put<GenericAPIResponse<InvitationCampaign>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  deleteInvitationCampaign(_id: string): Promise<GenericAPIResponse<undefined>> {
    const url = `${this.apiHelper.apiUrl()}/users/invitation-campaign/${_id}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.delete<GenericAPIResponse<undefined>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getActiveInvitationCampaign(coachid: string): Promise<GenericAPIResponse<InvitationCampaign>> {
    const url = `${this.apiHelper.apiUrl()}/users/invitation-campaign/${coachid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIResponse<InvitationCampaign>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  completeInvitationCampaign(_id: string): Promise<GenericAPIResponse<InvitationCampaign>> {
    const url = `${this.apiHelper.apiUrl()}/users/invitation-campaign/${_id}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<InvitationCampaign>>(url, {}, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getEmailInfo(email: string): Promise<GenericAPIResponse<EmailInfo>> {
		const body = {email};
    const url = `${this.apiHelper.apiUrl()}/users/email-info`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<EmailInfo>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  clearEmailInfo(email: string): Promise<GenericAPIResponse<EmailInfo>> {
		const body = {email};
    const url = `${this.apiHelper.apiUrl()}/users/email-info`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.put<GenericAPIResponse<EmailInfo>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }
}
