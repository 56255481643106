<mat-card class="card">
    <div class="card-header">
        <div mat-card-avatar>
            <a [routerLink]="getRouterLink()">
                <img *ngIf="profileImage; else noLogo" [src]="profileImage" class="profile-image" [style.borderColor]="primaryColor" onerror="this.src='/assets/images/portrait-placeholder.png';" >
                <ng-template #noLogo>
                    <div class="profile-image portrait-text">
                        {{getDefaultText() | uppercase}}
                    </div>
                </ng-template>
            </a>
        </div>
        <div class="header-title-section">
            <div class='card-title'>{{highlightRecord.highlight.title}}</div>      
            <div class='card-sub-title'>
                <div><a [routerLink]="getRouterLink()">{{getNameForTitle() | titlecase }}</a></div>
                <div>{{highlightRecord.highlight.updatedAt | date:'M/d/yyyy'}} - {{getViewsForTitle()}}</div>
            </div>        
        </div>    
        <!-- <a [routerLink]="'/products'" target="_blank" *ngIf="selected && highlightRecord?.sportid !== 'football'">
          <button mat-flat-button color="primary">WE'LL BUILD YOUR HIGHLIGHT REEL</button>
        </a> -->
        <button mat-button *ngIf="selected && !hideCloseButton" (click)="clickedClose()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="panel-body">
        <img *ngIf="highlightRecord && !selected" [src]="getThumbnailForHighlight()" class="panel-body-image" > 
        <button *ngIf="!selected" class="play_button" (click)="clickedPlay()">
            <fa-icon class="play-icon" [icon]="['fas', 'play-circle']"></fa-icon>
        </button>
        <video 
          *ngIf="selected" 
          class="highlight-video" 
          src="{{highlightRecord.highlight.highlightlink}}" 
          controls autoplay 
          poster="{{getThumbnailForHighlight()}}"
					playsinline
					webkit-playsinline
          appVideo
          ></video>
    </div>
    <mat-card-actions>
        <div class="action-groups">
            <div>
                <button mat-button (click)="updateReactions('like', !lsReaction.like)" [ngClass]="{'like': lsReaction.like}" matTooltip="Like this highlight" class="left-button">
                    <mat-icon class="icon-font">thumb_up</mat-icon> {{getCountForReaction('like')}}
                </button>
                <button mat-button (click)="updateReactions('love', !lsReaction.love)" [ngClass]="{'love': lsReaction.love}" matTooltip="Love this highlight" class="left-button">
                    <mat-icon class="icon-font">favorite</mat-icon> {{getCountForReaction('love')}}
                </button>
                <button mat-button (click)="updateReactions('fire', !lsReaction.fire)" [ngClass]="{'fire': lsReaction.fire}" matTooltip="This highlight is on fire!" class="left-button">
                    <mat-icon class="icon-font">local_fire_department</mat-icon> {{getCountForReaction('fire')}}
                </button>
            </div>

            <div>
                <button mat-icon-button aria-label="Download this highlight" (click)="downloadFile()" matTooltip="Download this Highlight">
                    <mat-icon class="icon-font">cloud_download</mat-icon>
                </button>
                <a href="{{getMailLinkForHighlight()}}" mat-icon-button aria-label="Email this highlight" matTooltip="Email a link to this highlight">
                    <mat-icon class="icon-font">email</mat-icon>
                </a>
                <button mat-icon-button aria-label="Copy a link to this highlight" (click)="copyHighlightLinkToClipboard(highlightRecord.highlight._id)" matTooltip="Copy a link to this highlight to the clipboard">
                    <mat-icon class="icon-font">content_copy</mat-icon>
                </button>
                <button mat-icon-button aria-label="Share on Facebook" (click)="shareToFacebook()" matTooltip="Share this highlight on Facebook">
                    <fa-icon [icon]="['fab', 'facebook']" class='icon-font'></fa-icon>
                </button>
                <a mat-icon-button aria-label="Share on Twitter" href="{{getTwitterUrl()}}" matTooltip="Share this highlight on Twitter">
                    <fa-icon [icon]="['fab', 'twitter']" class='icon-font'></fa-icon>
                </a>
            </div>
        </div>
    </mat-card-actions>
  </mat-card>