import * as _ from 'lodash';
import { Playlist } from './playlist';
import {Grade} from "./grading";
import * as Annotation from './annotation';
import * as moment from 'moment';
import { Team, TeamGroup } from './team';
import { Sport } from './conference';
import { Field } from './locations';
import { User } from './user';
import { Angle, Clip } from './clip';
import { StatReport } from './reports';
import { GameRating } from './leaguepool';
import { CamopNote, ShooterAssignment, ShooterInfo } from './shooters';
import { FilmReviewAssignment, FilmReviewData } from './filmreview';
import { BreakdownRequest } from './breakdowns';
import { AutoGameHighlight } from './highlight';
import { Tournament } from './showcase';

export interface Game {
	_id?: string;
	name?: string;
	extrainfo?: string;
	teamid?: Team['_id'];
	hometeamid?: Team['_id'];
	awayteamid?: Team['_id'];
	seasonid: number;
	gamedate: Date;
	location?: GameLocation;
	type: GameType;
	sportid: Sport;
	fieldid?: Field['_id'];
	subfield?: string;
	homescore?: number;
	awayscore?: number;
	videoready?: boolean;
	uploading?: boolean;
	merging?: boolean;
	streamuploaded?: boolean;
	streamavailable?: boolean;
	public?: boolean;
	ppv?: boolean;
	ppvPrice?: number;
	hidefromhome?: boolean;
	hidefromaway?: boolean;
	fieldLength?: number;
	folderid?: string; //convert gamefolders to ts
	canceled?: boolean;
	canceldate?: Date;
	archived?: boolean;
	qualityControl?: GameQualityControl[];
	createdByUserid?: User['_id'];
	oldparentgameid: number;
	oldid?: number;
	editProcessing?: boolean;
	editTotalClips?: number;
	editProcessedClips?: number;
	hideFromLeaguePool?: boolean;
	gameNotes?: string;
	uploadedAt?: Date;
	leaguePoolAngles?: Angle[];
	updatedAt?: Date;
	createdAt?: Date;
  adminNotes?: AdminNote[];
  enabledNoteForCoach: boolean;
  homeConfirm?: Confirm;
  awayConfirm?: Confirm;

	//not stored in db
	matchup?: string;
	team?: Team;
	hometeam?: Team;
	awayteam?: Team;
	field?: Field;
	hometeamname?: string;
	awayteamname?: string;
	scorestr?: string;
	createdByTeam?: boolean;
	isteamshare?: boolean;
	isCopy?: boolean;
	copyId?: string;
	angles?: Angle[];
	anglestr?: string;
	parentfolderid?: string;
	leaguepoolGame?: boolean;
	autoRecord?: boolean;
	twoMinJobID?: string;
	twoMinJobStatus?: TwoMinuteJobStatus;
	twoMinJobStatusDetails?: string;
	autoHighlight?: AutoGameHighlight;

	annotations: Annotation.Clip[];
	notes: any[];
	stats: any[];
	grade: Grade;
	favorites: any[]; // this is used to cache users favorites associated with the game
	hidefromathletes?: boolean;
  shareUserids?: User['_id'][];
  shareGroupids?: TeamGroup['_id'][];
	hiddenclips?: number[];
	bigplayclips?: number[];
	periodLength?: number;
	clips?: Clip[];
	numClips?: number;
	clipInfos?: {[clipnumber: number]: ClipInfo};
	gameViews?: GameView[];
	gameRatings?: GameRating[];
	gameRatingAverage?: number;
	confirmedUser?: User;
	confirmedTeam?: Team;
	views?: number;
	purchases?: number;
	filmReviewAssignment?: FilmReviewAssignment;
	filmReviewData?: FilmReviewData[];
	uploads?: GameUpload[];
	newCamop?: ShooterInfo;
	hasStream?: boolean;
	camopAssignment?: ShooterAssignment;
	camopAssignments?: ShooterAssignment[];
	breakdownRequest?: BreakdownRequest;
	thumbnail?: string;
	useForSeasonStats?: boolean;
  customUrl?: boolean;
}

export type GameType = 'game' | 'practice' | 'scout' | 'playbook';
export type GameLocation = 'home' | 'away' | 'neutral';

export interface GameQualityControl {
  id: number;
  name: string;
}

export interface ClipInfo {
  homeName?: string;
  awayName?: string;
  class?: string;
}

export interface GameCopy {
  _id?: string;
  teamid: Team['_id'];
  gameid: Game['_id'];
  seasonid: number;
  parentfolderid?: GameFolder['_id'];
}

export interface GameFolder {
  _id?: string;
  teamid: string;
  seasonid: number;
  name: string;
  date?: Date;
  parentfolderid?: GameFolder['_id'];
  oldgameid?: number;

  //not in database
  game?: Game;
  playlist?: Playlist;
  statReport?: StatReport;
  datestring?: string;
  children?: GameFolder[];
  playlists?: Playlist[];
  hideOptions?: boolean;
  depth?: number;
  loading?: boolean;
  notes?: CamopNote[];
}

export interface GameInfo {
  teamid: Team['_id'];
  gameid: Game['_id'];
  hidefromathletes?: boolean;
  hiddenclips?: number[]; //array of clipnumbers
  bigplayclips?: number[]; //array of clipnumbers
  parentfolderid?: GameFolder['_id'];
  periodLength?: number;
  clipInfos?: {[clipnumber: number]: ClipInfo};
  shareUserids?: User['_id'][];
  shareGroupids?: TeamGroup['_id'][];
	useForSeasonStats?: boolean;
}

export interface TeamShare {
  _id?: string;
  gameid: Game['_id'];
  teamid: Team['_id'];
  seasonid: number;
  exchangerequestid?: string;
  angles?: Angle[];
  createdAt?: Date;
  updatedAt?: Date;
}

export interface QCOption {
  id: number;
  name: 'Missed Scoreboards' | 'Missed Coin Toss' | 'Missed Shaking Hands' | 'Bad Timing' | 'Bad Framing' | 'Bad Flow' | 'No Issues';
}
export const qcOptions: QCOption[] = [
  {id: 1, name: 'Missed Scoreboards'},
  {id: 2, name: 'Missed Coin Toss'},
  {id: 3, name: 'Missed Shaking Hands'},
  {id: 4, name: 'Bad Timing'},
  {id: 5, name: 'Bad Framing'},
  {id: 6, name: 'Bad Flow'},
  {id: 7, name: 'No Issues'}
];

export enum TwoMinuteJobStatus {
	NOT_STARTED = 'NOT STARTED',
	IN_PROGRESS = 'IN PROGRESS',
	COMPLETED = 'COMPLETED',
	COMPLETED_WITH_ERRORS = "COMPLETED WITH ERRORS",
	ERROR = 'ERROR'
};

export const isHomeGame = (game: Game, user: User) => {
  if (!game || !user || !user.currentUserRole || _.includes(['admin', 'subadmin', 'junioradmin', 'statsapi', 'statmanager'], user.currentUserRole.role) || !user.team || game.isteamshare) return true;
  return (game.teamid === user.team._id && (game.location === 'home' || game.location === 'neutral')) || game.hometeamid === user.team._id;
}

export interface SeasonScheduleState {
  expandedNodeIds: string[];
  lastScrollPos: number;
}

export interface GameView {
  _id: string;
  userid: User['_id'];
  teamid: Team['_id'];
  gameid: Game['_id'];
  createdAt?: Date;
  updatedAt?: Date;

  //not in db
  teamname?: string;
  username?: string;
}

export interface GameManagerMatchupGroup {
  fieldid: string;
  date?: Date;
  field?: Field;
  subfield?: string;
  gameids: string[];
  matchup?: string;
  confname?: string;
	sportid?: Sport;
  assignments: ShooterAssignment[];
  declinedAssignments: ShooterAssignment[];
  hasNotes?: boolean;
  hasCamOpNotes?: boolean;
  requiredAngles: Angle[];
  confirmedGames: number;
  canceledGames: number;
  editingField?: boolean;
  working?: boolean;
  adminNotes: AdminNote[];
	tournament?: Tournament;
}

export interface AdminNote{
  _id?: string;
  userId: User['_id'];
  note: string;
  timestamps: Date;

  name?: string;
}


export interface Confirm {
  confirmed: boolean,
  confirmedDate: Date,
	confirmedByUserid: string,
  confirmedByTeamid: string,
	confirmedUser?: User;
}

export interface GameUpload {
  _id?: string;
  userid: User['_id'];
  gameid: Game['_id'];
  angle: Angle;
  numFiles: number;
  startingClipnumber: number;
  createdAt?: Date;
  updatedAt?: Date;

  //not in db
  user?: User;
}

export interface GameRoster {
	_id?: string;
	teamid: Team['_id'];
  gameid: Game['_id'];
	roster: {[userid: string]: number};
	updatedAt?: Date;
	createdAt?: Date;
}

export interface HudlImportGameInfo {
	depth?: number;
	name: string;
	date?: Date;
	type: GameType | 'folder';
	location?: GameLocation;
	hudlLink?: string;
	children?: HudlImportGameInfo[];
}

export interface ImportGameInfo {
	name: string;
	type: GameType;
	date?: Date;
	location?: GameLocation;
	customUrl?: string;
}

export interface ExchangeVideoPlayDataRaw {
	PlayNumber: {_text: string};
	Views: {
		View: {
			CameraView: {_text: string};
			Duration: {_text: string};
			MarkIn: {_text: string};
		}[]
	};
}

export interface ExchangeVideoPlayData {
	clipnumber: number;
	views: {
		cameraView: string;
		duration: number;
		startAt: number;
	}[]
}

export interface GameCaptureConnection {
	_id?: string;
	socketid: string;
  gameid: Game['_id'];
	angle: Angle;
	createdAt?: Date;
	updatedAt?: Date;
}