import { Component, Input, OnInit, Output, EventEmitter, isDevMode } from '@angular/core';
import * as _ from 'lodash';
import { HubHighlightRecord } from 'src/app/models/hub';
import { ApiHelperService } from 'src/app/services/api-helper.service';
import { DialogService } from 'src/app/services/dialog.service';
import { HighlightsService } from 'src/app/services/highlights.service';


//Used for loosly tracking clicks on highlight reactions
export interface LocalStorageHighlightReaction {
  id: string;
  like: boolean;
  love: boolean;
  fire: boolean;
}

@Component({
  selector: 'app-highlight-card',
  templateUrl: './highlight-card.component.html',
  styleUrls: ['./highlight-card.component.css']
})
export class HighlightCardComponent implements OnInit {

  profileImage: string;
  lsReaction: LocalStorageHighlightReaction;
  primaryColor: string = "#2e3338";
  
  @Input() hideCloseButton: boolean = false;
  @Input() selected: boolean = false;  
  @Output() closedCard: EventEmitter<HubHighlightRecord> = new EventEmitter<HubHighlightRecord>();
  
  _highlightRecord: HubHighlightRecord;
  get highlightRecord(): HubHighlightRecord {
    return this._highlightRecord;
  }
  @Input() set highlightRecord(value: HubHighlightRecord) {
    this._highlightRecord = value;
    
    this.getLSForHighlight();
    this.profileImage = this.getUserImage();

    if(!this._highlightRecord.highlight.reactionCounts) {
      //Set default - for old highlights
      this._highlightRecord.highlight.reactionCounts = {
        like: 0, love: 0, fire: 0
      }
    }
  }

  constructor(private api: HighlightsService, private _apiHelper: ApiHelperService, private dialog: DialogService) { }

  ngOnInit() {
		this.updateViews();
  }

  clickedPlay() {    
    this.dialog.openHighlightVideoDialog(this._highlightRecord);
  }

  clickedClose() {
    this.closedCard.emit(this.highlightRecord);
  }
 
  getDefaultText(): string {
    if (!this.highlightRecord.user) { return ''; }
    return this.highlightRecord.user.firstname.charAt(0) + this.highlightRecord.user.lastname.charAt(0);
  }

  getUserImage(): string | null {
    
    if(this.highlightRecord.user.officalHighlight) {
      this.primaryColor = "#507c23";
      return '/assets/images/qc88.png';
    }

    if(!this.highlightRecord.metric || !this.highlightRecord.metric.profile || !this.highlightRecord.metric.profile.picture ) {
      return null;
    }
    
    let parentFolder = 'social_media'
    if (window.location.href.includes('qwik-q-dev') || window.location.href.includes('localhost')) parentFolder = 'dev_social_media';
    return `https://d2htis0rx2m2xo.cloudfront.net/${parentFolder}/${this.highlightRecord.highlight.userid}/${this.highlightRecord.metric.profile.picture}`;
  }

  getNameForTitle(): string {
    if (!this.highlightRecord.user) { return ''; }

    if(this.highlightRecord.user.officalHighlight) {
      return 'Official QwikCut Highlight';
    }

    let nick = this.highlightRecord.metric?.nickName ? ' "' + this.highlightRecord.metric.nickName + '" ' : ' ';
    return this.highlightRecord.user.firstname + nick + this.highlightRecord.user.lastname;
  }

  getViewsForTitle() {
    const views = this.highlightRecord.highlight?.views || 0;
    return `${views} View${views == 1 ? '' : 's'}`;
  }

  getThumbnailForHighlight(): string {
    if(!this.highlightRecord.highlight?.highlightlink) { return ''; }
    const highlightName = this.highlightRecord.highlight._id;
    return _.split(this.highlightRecord.highlight.highlightlink, highlightName)[0] + `thumbnails/${highlightName}.jpg`
  }

  downloadFile() {
    window.open(this.highlightRecord.highlight.highlightlink, '_blank');
  }

  getMailLinkForHighlight(): string {
    if(!this.highlightRecord.highlight) { return ''; }
    const url = this.getShareUrl();
    const encodedName = encodeURIComponent(`${this.highlightRecord.highlight.title} - Video Highlight - Powered by QwikCut`)
    return `mailto:?subject=${encodedName}&body=${url}`;
  }

  getShareUrl(): string {
    if(!this.highlightRecord.highlight) { return ""; }
    const subdomain = isDevMode() ? 'dev-share' : 'share';
    return `https://${subdomain}.qwikcut.com/${this.highlightRecord.highlight._id}/${encodeURIComponent(this.highlightRecord.highlight.title)}`;    
  }

  getTitle() {
    if (!this.highlightRecord.highlight) return 'QwikCut Highlight'
    return this.highlightRecord.highlight.title
  }

  updateViews() {
		if (!this.highlightRecord?.highlight || !this.selected) return;
    //At a future date we will want to do more than check if play is hit, but for now that is good. Approved by Chad 
    this.api.incrementHighlightViews(this.highlightRecord.highlight._id)
      .then(_ => {})
      .catch(error => console.error(error));
  }

  copyHighlightLinkToClipboard(_id) {    
    const text = this.getShareUrl();
    var clipBoardElem = document.createElement("input");
    
    document.body.appendChild(clipBoardElem);
    clipBoardElem.value = text;
    clipBoardElem.select();
    
    var successfulCopy = document.execCommand('copy');
    document.body.removeChild(clipBoardElem)
    
    if(successfulCopy) {
      this._apiHelper.showSuccessResponse("Copied to Clipboard");
    } else {
      this._apiHelper.showErrorResponse("There was a problem trying to copy this link.");
    }
  }

  shareToFacebook() {
    const url = this.getShareUrl();
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`,'facebook-share-dialog',"width=626, height=436");
  }

  getTwitterUrl() {
    const link = this.getShareUrl();
    const encodedText = encodeURIComponent(this.getTitle() + " - ");
    const encodedUrl = encodeURIComponent(link);
    const encodedHashtags = encodeURIComponent("QwikCut");
    return `https://twitter.com/intent/tweet?hashtags=${encodedHashtags}&text=${encodedText}&url=${encodedUrl}`;
  }

  getRouterLink() {
    const user = this.highlightRecord.user;    
    const uname = this.getStringForRouterLink(`${user.firstname}-${user.lastname}`);
    return ['/hub/detail', this.highlightRecord.highlight.userid, uname];
  }

  getStringForRouterLink(input: string): string {

    if(this.highlightRecord.user.officalHighlight) {
      return 'QwikCut';
    }

    const regex = / /gm;
    return String(input).replace(regex, '-'); //Replace all spaces with dashes, dont' want %20's.
  }

  updateReactions(type: 'like' | 'love' | 'fire', value: boolean) {
    /*
    Per Chad 1 July 2021
    - No decrementing reaction count, only adding
    - Do not require user to be logged in, to add a reaction
    - Do not care if someone is able to spam like's button to create fake reactions
    */

    //Increment only positive values
    if(value) {
      this.api.incrementHighlightReactions(this.highlightRecord.highlight._id, type)
        .then(_ => {})
        .catch(error => console.error(error))  
    }

    let count = this.highlightRecord.highlight.reactionCounts;

    //Update the local side counts and value immeditly without requiring a round trip to refresh
    //Also gives the appearnce of decrementing to balance between chad's requirement above and user fustration
    switch (type) {
      case 'fire':
        this.lsReaction.fire = value;
        count.fire = value ? count.fire + 1 : count.fire - 1;
        break;

      case 'like':
        this.lsReaction.like = value;
        count.like = value ? count.like + 1 : count.like - 1;
        break;

      case 'love':
        this.lsReaction.love = value;
        count.love = value ? count.love + 1 : count.love - 1;
        break;
    
      default:
        break;
    }

    this.saveLSForHighlight();

  }

  getLSForHighlight() {
    //Get's local storage reaction object for given highlight, stored by the highlights id
    let objJSON = localStorage.getItem(this.highlightRecord.highlight._id);

    //No highlight stored
    if(!objJSON || objJSON == "") {
      this.lsReaction = this.getDefaultLSValue();
      return;
    }

    try {
      this.lsReaction = JSON.parse(objJSON);
    } catch (error) {
      this.lsReaction = this.getDefaultLSValue(); //Get default on error (improperly stored object or empty string)
    }
  }

  saveLSForHighlight() {
    localStorage.setItem(this.highlightRecord.highlight._id, JSON.stringify(this.lsReaction));
  }

  getDefaultLSValue(): LocalStorageHighlightReaction {
    return {
      id: this.highlightRecord.highlight._id,
      like: false,
      love: false,
      fire: false
    };
  }

  getCountForReaction(type: string): string {
    let display = ""; //Default no display for unset or zero reactions
    let counts = this.highlightRecord.highlight.reactionCounts;

    //No reaction counts set yet
    if(!counts) {
      return display;
    }

    switch (type) {
      case 'like':
        return counts.like > 0 ? `${counts.like}` : "";
      case 'love':
        return counts.love > 0 ? `${counts.love}` : "";
      case 'fire':
        return counts.fire > 0 ? `${counts.fire}` : "";
    
      default:
        break;
    }
    return display;
  }

}
