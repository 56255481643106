import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  HostListener,
  ViewChild,
  ElementRef,
  isDevMode,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatMenuTrigger } from '@angular/material/menu';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription, lastValueFrom, map } from 'rxjs';
import { User, UserRoleValue } from 'src/app/models/user';
import {
  Message,
  Conversation,
  Reaction,
  MessageFile,
} from 'src/app/models/chat';
import { ChatService } from '../../services/chat.service';
import { UserService } from '../../services/user.service';
import { SocketIOService } from '../../services/socket-io.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { ApiHelperService } from '../../services/api-helper.service';
import { DialogService } from 'src/app/services/dialog.service';
import { TeamService } from 'src/app/services/team.service';
import { TeamGroup } from 'src/app/models/team';
import { Emoji, EmojiData } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { QwikboxService } from 'src/app/services/qwikbox.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';

const MAX_FILE_SIZE = 50 *1024 * 1024;
const S3_URL = 'https://qwikbox.s3.amazonaws.com/';

@Component({
  selector: 'app-messenger',
  templateUrl: './messenger.component.html',
  styleUrls: ['./messenger.component.css'],
})
export class MessengerComponent implements OnInit, OnDestroy {
  @Input() user: User;
	@Input() elevatedPriv: boolean = false;

  conversations: Conversation[] = [];
  currentConvo: Conversation;
  // convoMessages: Message[] = [];
  // messageText: string;
	// currentEditMessage: Message;
  // chatMessageSub: Subscription;
  listConversationSub: Subscription;
  userSub: Subscription;
	openChatSub: Subscription;
	chatClosedSub: Subscription;

  readonly messageLimit: number = 100;
  messagesRequested: number = 0;
  loadingConvos: boolean = false;
  loadingMessages: boolean = false;
  loadingMoreMessages: boolean = false;
  sendingMessage: boolean = false;
  isLoadMore: boolean = false;
  isSelectEmoji: boolean = false;
  selectedMessage: string = '';
  isShowReactionIcon: string = '';
  selectionStart: number = 0;
  teamId: string;
  isActiveNotification: boolean = false;
  emojiHasShow: number = 3;
  isNotificationEnabled: boolean = true;

  users: User[] = [];
  teamGroups: TeamGroup[] = [];
  listUsersName = {};
  listEmojiReact = {};

  loading: boolean = false;

  @ViewChild('fileInput') fileInput!: ElementRef;

  @ViewChild('convoMenuTrigger') convoMenuTrigger: MatMenuTrigger;

  constructor(
    private userService: UserService,
    private teamService: TeamService,
    private chatService: ChatService,
    private socketIO: SocketIOService,
    private localStorageService: LocalStorageService,
    private apiHelper: ApiHelperService,
    private dialogService: DialogService,
    private qwikboxService: QwikboxService,
    private http: HttpClient,
  ) {}

  @HostListener('document:click')
  handleOnClick() {
    this.isSelectEmoji = false;
    this.selectedMessage = '';
  }

  ngOnInit(): void {
		this.user = this.localStorageService.getUser();
    this.getConversations();
    this.userSubHandle();
    this.conversationSubHandle();
		this.openChatSubHandle();
		this.chatClosedSubHandle();
  }

  ngOnDestroy(): void {
    if (this.currentConvo) this.socketIO.leaveRoom(this.currentConvo._id, this.user._id);
    if (this.userSub) this.userSub.unsubscribe();
    if (this.listConversationSub) this.listConversationSub.unsubscribe();
    if (this.openChatSub) this.openChatSub.unsubscribe();
		if (this.chatClosedSub) this.chatClosedSub.unsubscribe();
  }

  userSubHandle() {
    this.userSub = this.localStorageService
      .listenForUserUpdate()
      .subscribe((user) => {
        this.user = user;
				if (this.user?.currentUserRole) {
					this.teamId = this.user.currentUserRole.teamid;
	
					this.getTeamGroups();
					this.getUsers();
					this.getConversations();
				}
      });
  }

	openChatSubHandle() {
		if (this.openChatSub) this.openChatSub.unsubscribe();
		this.openChatSub = this.chatService
			.listenToOpenChat()
			.subscribe({
				next: chatData => {
					let conversationData: Partial<Conversation> = null;

					if (chatData.type === 'group' && chatData?.group?._id) {
						conversationData = {
							name: chatData.group.name,
							members: _.uniq([this.user._id, ...(chatData.group.groupmembers || [])]),
							groupId: chatData.group._id
						};
					}
					else if (chatData.type === 'user' && chatData?.user?._id) {
						conversationData = {
							name: null,
							members: [this.user._id, chatData.user._id],
							groupId: null
						};
					}

					this.chatService.getOrCreateConversation(conversationData.name, conversationData.members, this.user._id, conversationData.groupId)
					.then(res => {
						if (res.success && res.result) {
							const convo = res.result;
							convo.name = this.chatService.getConversationName(convo, this.user._id);
							if (_.findIndex(this.conversations, {_id: convo._id})) this.conversations.push(convo);
							this.openConversation(convo);
						}
					})
					.catch(err => this.apiHelper.showErrorResponse(err));
				}
			});
	}

	chatClosedSubHandle() {
		if (this.chatClosedSub) this.chatClosedSub.unsubscribe();
		this.chatClosedSub = this.chatService
			.listenToChatClosed()
			.subscribe({
				next: () => {
					if (this.currentConvo) this.closeConversation();			
				}
			});
	}

  conversationSubHandle() {
    const converationList = `${this.user._id}-listConversation`;
    this.listConversationSub = this.socketIO
      .listenForChatMessage(converationList)
      .subscribe({
        next: (conversationData: Conversation) => {
					if (!conversationData) return;
					if (!conversationData.name) conversationData.name = this.chatService.getConversationName(conversationData, this.user._id);
          const negativeIndex = -1;
          const indexUpdatedConversation = this.conversations.findIndex(
            (conversation) => {
              return conversation._id === conversationData._id;
            }
          );
          if (indexUpdatedConversation !== negativeIndex) {
            this.conversations[indexUpdatedConversation] = {
              ...this.conversations[indexUpdatedConversation],
              ...{ lastMessage: conversationData.lastMessage },
            };
            this.conversations = this.sortConversation(this.conversations);
          } else {
            this.conversations.unshift(conversationData);
          }
        },
      });
  }

  getConversations() {
    if (!this.user) return;
    this.loadingConvos = true;
    this.chatService.getConversations().then(async (conversationData) => {
      if (conversationData.success) {
        this.conversations = this.sortConversation(conversationData.results);
        this.conversations.forEach((conversation) => {
					if (!conversation.name) conversation.name = this.chatService.getConversationName(conversation, this.user._id);
        });
        this.loadingConvos = false;
      }
    });
  }

  sortConversation(conversations: Conversation[]) {
    return _.orderBy(
      conversations || [],
      [
        (conversation) =>
          conversation.lastMessage
            ? conversation.lastMessage.createdAt
            : conversation.createdAt,
      ],
      ['desc']
    );
  }

  async getUsers() {
    if (!this.teamId) return;

    const coachUserRoles: UserRoleValue[] = ['athlete', 'teamadmin', 'coach', 'parent'];
    const res = await this.teamService.getTeamUsers(
      this.teamId,
      coachUserRoles
    );
    if (!res?.success) return;

    const orderedUsers = _.orderBy(
      _.uniqBy(res.results, '_id'),
      (user) => _.toLower(`${user.lastname} ${user.firstname}`),
      'asc'
    );
    const index = orderedUsers.findIndex(({ _id }) => _id === this.user._id);
    if (index > -1) orderedUsers.splice(index, 1);

    this.users = orderedUsers;
  }

  async getTeamGroups() {
    if (!this.teamId) return;

    const res = await this.teamService.getGroups(this.teamId);
    if (!res?.success) return;

    const filteredGroup = _.filter(res.results || [], ({ groupmembers }) =>
      _.includes(groupmembers, this.user._id)
    );
    this.teamGroups = _.orderBy(filteredGroup || [], (group) =>
      _.toLower(group.name)
    );
  }

  isReadLastMessage(convo: Conversation) {
    if (!convo?.lastMessage) return true;
    return _.some(convo?.lastMessage?.readBy, (readBy) => {
      return readBy.userId === this.user._id;
    });
  }

  openConversation(convo: Conversation, event?: Event) {
		if (event) event.stopPropagation();
    if (!convo) return;
		this.currentConvo = convo;
  }

  closeConversation() {
    this.currentConvo = null;
    this.messagesRequested = 0;
  }

	sendDirectMessage(message: Message) {
		if (!this.currentConvo?._id || !message?.senderId || message.senderId === this.user?._id) return;
		this.chatService.getOrCreateConversation('', [this.user._id, message.senderId], this.user._id)
		.then(res => {
			if (res.success && res.result) {
				this.closeConversation();
				const convo = res.result;
				convo.name = this.chatService.getConversationName(convo, this.user._id);
				if (_.findIndex(this.conversations, {_id: convo._id}) === -1) this.conversations.push(convo);
				this.openConversation(convo);
			}
		})
		.catch(err => this.apiHelper.showErrorResponse(err));
	}

  formatDate(date: Date) {
    return moment(date).format('MMM D, h:mma');
  }

  async createConversations(event: Event) {
		event.stopPropagation();
    const conversation = await this.dialogService.openCreateConversationDialog(
      this.teamId,
      this.users,
      this.teamGroups
    );
    if (!conversation) return;

		this.chatService.getOrCreateConversation(conversation.name, conversation.members, this.user._id, conversation.groupId)
		.then(res => {
			if (res.success && res.result) {
				const convo = res.result;
				convo.name = this.chatService.getConversationName(convo, this.user._id);
				if (_.findIndex(this.conversations, {_id: convo._id})) this.conversations.push(convo);
				this.openConversation(convo);
			}
		})
		.catch(err => this.apiHelper.showErrorResponse(err));
  }

  async editConversations() {
    if (!this.currentConvo?._id) return;
    this.convoMenuTrigger?.closeMenu();
    const conversation = await this.dialogService.openEditConversationDialog(
      this.teamId,
      this.users,
      this.teamGroups,
      this.currentConvo
    );

    if (!conversation) return;
    const res = await this.chatService.updateConversation(
      this.currentConvo._id,
      conversation
    );
    if (!res?.success) return;
    const updatedConvo = res.result;
    this.currentConvo.name = updatedConvo.name;
    this.currentConvo.members = updatedConvo.members;
    this.currentConvo.users = updatedConvo.users;
    this.currentConvo.groupId = updatedConvo.groupId;
    this.currentConvo.group = updatedConvo.group;

    const index = _.findIndex(
      this.conversations,
      ({ _id }) => _id === this.currentConvo._id
    );
    if (index < 0) return;

    this.conversations[index] = this.currentConvo;
  }

  deleteConversation() {
		if (!this.currentConvo?._id || !(this.currentConvo.createdBy === this.user._id || this.currentConvo.members?.length === 2)) return;
    this.convoMenuTrigger?.closeMenu();
    this.dialogService.openConfirmationDialog('Are you sure you want to delete this conversation?')
		.then((confirm) => {
			if (confirm) {
				this.chatService
					.deleteConversation(this.currentConvo._id)
					.then((res) => {
						if (res.success) {
							this.getConversations();
							this.closeConversation();
						} else if (res.message) throw res.message;
					})
					.catch((err) => this.apiHelper.showErrorResponse(err));
			}
		});
  }

	leaveConversation() {
		if (!this.currentConvo?._id || !!this.currentConvo.groupId || this.currentConvo.members.length <= 2 || this.user?._id === this.currentConvo.createdBy) return;
    this.convoMenuTrigger?.closeMenu();
    this.dialogService.openConfirmationDialog('Are you sure you want to leave this conversation?')
		.then((confirm) => {
			if (confirm) {
				this.chatService
					.leaveConversation(this.currentConvo._id, this.user._id)
					.then((res) => {
						if (res.success) {
							this.getConversations();
							this.closeConversation();
						} else if (res.message) throw res.message;
					})
					.catch((err) => this.apiHelper.showErrorResponse(err));
			}
		});
	}

  openScheduleMessageDialog() {
    if (!this.currentConvo) return;
    this.dialogService.openScheduleMessageDialog(this.currentConvo._id);
  }

  toggleNotification() {
    let disabledNotifications = [];
    if (this.isNotificationEnabled) {
      if (!this.currentConvo.disabledNotifications) {
        disabledNotifications = [this.user._id];
      } else {
        this.currentConvo.disabledNotifications.push(this.user._id);
        disabledNotifications = _.uniq(this.currentConvo.disabledNotifications);
      }
    } else {
      disabledNotifications = _.without(
        this.currentConvo.disabledNotifications,
        this.user._id
      );
    }
    const conversationId = this.currentConvo._id;
    this.currentConvo.disabledNotifications = disabledNotifications;
    this.chatService.updateConversation(conversationId, this.currentConvo);
  }

  contactSupport() {
		const subject = 'Request to Restore Old Chat Conversations';
		const body = `User Email: ${this.user?.username}. Please do not change the subject line. Enter any additional info below. Thank you.`;
    window.open(`mailto:support@qwikcut.com?subject=${subject}&body=${body}%0D%0A%0D%0A%0D%0A`, '_blank');
  }
}