import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { FlashMessagesService } from 'angular2-flash-messages';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from './local-storage.service';
import { environment } from 'src/environments/environment';


@Injectable()
export class ApiHelperService {

  constructor(
    // private flashMessages: FlashMessagesService,
    private toastr: ToastrService,
    private localStorageService: LocalStorageService
  ) { }

  apiHeader(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return headers;
  }

  apiHeaderWithToken(token: string = null): HttpHeaders {
    let headers = this.apiHeader();
    if (!token) token = this.localStorageService.getToken();
    if (token) headers = headers.append('Authorization', token);
    return headers;
  }

  apiUrl(): string {
    if (isDevMode()) {      
      return environment.local ? 'http://localhost:8080/api' : 'https://devapi.qwikcut.com/api';
      // return 'https://devapi.qwikcut.com/api';
      // return 'https://api.qwikcut.com/api';
			// return 'http://192.168.0.221:8080/api'; //local network testing
    } else {
      return 'https://api.qwikcut.com/api';
    }
  }

  qwikcutEncoderUrl(): string {
    return 'https://upload.qwikcut.com/upload';
  }

  qwikcutEncoderUrlMerge(): string {
    return 'https://upload.qwikcut.com/combine';
  }

  qwikcutEncoderUrlReset(): string {
    return 'https://upload.qwikcut.com/reset';
    // return 'http://localhost:4567/reset';
  }

  qwikcutEncoderUrlPlaylist(): string {
    return 'https://upload.qwikcut.com/playlist';
  }

  qwikcutProfilePic(url?: string): string {
    // return 'https://d2htis0rx2m2xo.cloudfront.net/profile_pics'
    return `https://qwikbox.s3.amazonaws.com/profile_pics`
  }

  billboardContent(postContent: string): string {
    let folderBillboard = 'billboards';
    if (isDevMode()) {
      folderBillboard = 'dev_billboards';
    }

    return `https://d2htis0rx2m2xo.cloudfront.net/${folderBillboard}/${postContent}`;
  }

  showSuccessResponse(message: string): void {
    this.toastr.success(message, 'Success!');
  }

  showWarnResponse(message: string): void {
    this.toastr.warning(message, 'Warning!');
  }

  showInfoResponse(message: string): void {
    this.toastr.info(message, 'Info');
  }

  showErrorResponse(message: string): void {
    const stack = (new Error(message)).stack;
    console.error(message);
    console.warn(stack);
    this.toastr.error(message, 'Error!');
  }
}
