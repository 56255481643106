import { Sport } from "../models/conference";

export const continuousSports: Sport[] = ['basketball', 'volleyball', 'soccer', 'lacrosse', 'wrestling', 'fieldhockey', 'hockey', 'rugby', 'boxing', 'baseball', 'softball'];

export const clipSports: Sport[] = ['football', 'wrestling', 'band', 'generic'];

export const mainSports: {display: string, sportid: Sport}[] = [
  {display: 'Baseball', sportid: 'baseball'},
  {display: 'Basketball', sportid: 'basketball'},
  {display: 'Hockey', sportid: 'hockey'},
  {display: 'Field Hockey', sportid: 'fieldhockey'},
  {display: 'Football', sportid: 'football'},
  {display: 'Lacrosse', sportid: 'lacrosse'},
  {display: 'Soccer', sportid: 'soccer'},
  {display: 'Softball', sportid: 'softball'},
  {display: 'Volleyball', sportid: 'volleyball'},
  {display: 'Wrestling', sportid: 'wrestling'},
]

export const sports: {display: string, sportid: Sport}[] = [
    {display: 'Football', sportid: 'football'},
    {display: 'Basketball', sportid: 'basketball'},
    {display: 'Lacrosse', sportid: 'lacrosse'},
    {display: 'Soccer', sportid: 'soccer'},
    {display: 'Volleyball', sportid: 'volleyball'},
    {display: 'Baseball', sportid: 'baseball'},
    {display: 'Softball', sportid: 'softball'},
    {display: 'Field Hockey', sportid: 'fieldhockey'},
    {display: 'Hockey', sportid: 'hockey'},
    {display: 'Wrestling', sportid: 'wrestling'},
    {display: 'Rugby', sportid: 'rugby'},
    {display: 'Dance', sportid: 'dance'},
    {display: 'Tennis', sportid: 'tennis'},
    {display: 'Cheer', sportid: 'cheer'},
    {display: 'Track', sportid: 'track'},
    {display: 'Band', sportid: 'band'},
    {display: 'Boxing', sportid: 'boxing'},
    {display: 'Generic', sportid: 'generic'},
]