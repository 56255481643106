import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { SeasonScheduleState } from '../models/game';
import { User } from '../models/user';

@Injectable()
export class LocalStorageService {

  constructor() { }

  private userUpdate = new Subject<any>();
  private themeUpdate = new Subject<Theme>();

  listenForUserUpdate(): Observable<User> {
    return this.userUpdate.asObservable();
  }

  emitUserUpdate(user: User) {
    this.userUpdate.next(user);
  }

  setToken(token: string) {
    localStorage.setItem('token', token);
  }

  getToken(): string {
    return localStorage.getItem('token');
  }

  removeToken() {
    localStorage.removeItem('token');
  }

  setUser(user: User) {
    localStorage.setItem('user', JSON.stringify(user));
    this.emitUserUpdate(user);
  }

  getUser(): User {
    const userStr = localStorage.getItem('user');
    if (userStr) return JSON.parse(userStr);
    return null;
  }

  removeUser() {
    localStorage.removeItem('user');
    this.emitUserUpdate(null);
  }

  setTheme(theme: Theme) {
    localStorage.setItem('theme', theme);
  }

  getTheme(): Theme {
    return <Theme>localStorage.getItem('theme') || 'dark';
  }

  listenForThemeUpdate(): Observable<Theme> {
    return this.themeUpdate.asObservable();
  }

  private emitThemeUpdate(theme: Theme) {
    this.themeUpdate.next(theme);
  }

  setScheduleState(teamid: string, seasonStates: {[seasonid: number]: SeasonScheduleState}) {
    let scheduleState: {[teamid: string]: {[seasonid: number]: SeasonScheduleState}} = JSON.parse(localStorage.getItem('scheduleState'));
    if (!scheduleState) scheduleState = {};
    scheduleState[teamid] = seasonStates;
    localStorage.setItem('scheduleState', JSON.stringify(scheduleState));
  }

  getScheduleState(teamid: string): {[seasonid: number]: SeasonScheduleState} {
    const scheduleState: {[teamid: string]: {[seasonid: number]: SeasonScheduleState}} = JSON.parse(localStorage.getItem('scheduleState'));
    if (scheduleState && scheduleState[teamid]) return scheduleState[teamid];
    return {};
  }

}

export type Theme = 'dark' | 'light';
