import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ErrorAwareImageComponent } from 'src/app/components/highlights/highlight-editor/error-aware-image/error-aware-image.component';
import { VideoImageComponent } from 'src/app/components/highlights/highlight-editor/video-image/video-image.component';
import { HighlightCardComponent } from 'src/app/components/hub/highlight-card/highlight-card.component';
import { UserHubSettingsComponent } from 'src/app/components/my-team/user-hub-settings/user-hub-settings.component';
import { IconsModule } from '../icons/icons.module';
import { VolumeChartComponent } from 'src/app/components/volume-chart/volume-chart.component';
import { VerticalBarChartComponent } from 'src/app/components/vertical-bar-chart/vertical-bar-chart.component';
import { MaterialModule } from '../material/material.module';

@NgModule({
  declarations: [
      HighlightCardComponent,
      UserHubSettingsComponent,
      VideoImageComponent,
      ErrorAwareImageComponent,
			VolumeChartComponent,
			VerticalBarChartComponent
  ],
  imports: [
      MaterialModule,
      RouterModule,
      IconsModule      
  ],
  exports: [
    HighlightCardComponent,
    UserHubSettingsComponent,
    VideoImageComponent,
    ErrorAwareImageComponent,
		VolumeChartComponent,
		VerticalBarChartComponent
  ],
  providers: []
})
export class SharedQwikcutModule { }
