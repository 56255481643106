import { AssignmentStateValue } from "./breakdowns";
import { Angle } from "./clip";
import { Sport } from "./conference";
import { Game } from "./game";
import { ScoutingAssignmentInfo } from "./scouting";
import { Team } from "./team";
import { User } from "./user";


export interface Highlight {
    _id: string;
    music?: LegacyMusicItem[];       // No longer used, legacy support only
    titleInfo: HighlightTitleInfo;
    highlightlink?: string;
    ispublic: boolean;
    status: number;                 // 0 - Unpublished, 1 = In progress/building, 2 = Published
    title: string;
    userid: User['_id'];
    teamid: Team['_id'];
    sportid: Sport;
    musiclist: MusicItem[];
    clips: HighlightClip[];                   // The Clip class doesn't seem to exactly match, more work will have to be done here to get this type
    createdAt?: Date;
    updatedAt?: Date;
    info?: InfoObject;               // Doesn't appear to be used, here for possible backward comptability
    views: number;                  // Count of the number of times the highlight has been played. Captured from the onPlay event of the video element in the highlight viewer component
    reactionCounts?: {
        like: number;
        love: number;
        fire: number;
    };
}

// Highlight interface without _id for creating new objects
export interface NewHighlight extends Omit<Highlight, '_id'> {}

export interface LegacyMusicItem {
    key: string;
    name: string;
}

export enum OVERLAY_TYPE {
	NONE = 0,
	BASIC = 1,
	CUSTOM_LEGACY = 2,
	CUSTOM_OPTION_1 = 3,
	CUSTOM_OPTION_2 = 4,
	CUSTOM_OPTION_3 = 5,
	CUSTOM_OPTION_4 = 6,
	CUSTOM_OPTION_5 = 7,
	CUSTOM_OPTION_6 = 8
};

export interface HighlightTitleInfo {
    playername?: string;
    playerweight?: string;
    playerheight?: string;
    playerposition?: string;
    playerteam?: string;
    playerseason?: string;
		playergradyear?: string;
		playerlocation?: string;
		playertwitter?: string;
		includeimage?: boolean;
		imageurl?: string;
		backgroundcolor?: string;
    usetitleInfo?: boolean; /* Now deprecated in favor of overaly attribute below */
    overlay?: OVERLAY_TYPE; /* null = old highlight that hasn't used this property yet,  0 = None, 1 = Basic, 2 = Custom - more to come in the future */
}

export interface MusicItem {
    _id?: string;
    name: string;
    key: string;                    // Path to music file ex: https://d2htis0rx2m2xo.cloudfront.net/music/Boast 2.mp3
    startAt: number;
    endAt: number
}

export interface AvailableHighlightMusicItem {
    _id: string;
    location: string;       // S3 location of the mp3 file
    name: string;           // Display name for the item
    duration: number;       // Duration in seconds of the mp3 file
    premium: boolean;       // Premium items are planned on being paid for in the future
    order?: number;
}

export interface InfoObject {
    line1: string;
    line2: string;
    line3: string;
}

/* Highlight clip structure as used by Highlight Builder (qc-highlight-types.ts) */
export interface HighlightClip {
    isClip: boolean;
    isMediaImage: boolean;
    isMediaVideo: boolean;
    effects: HighlightEffect[];
    endzone: boolean;
    pandir: number;
    annotations: HighlightAnnoation[];
    _id?: string;
    cliplink: string;
    startAt: number;
    endAt: number;
    gameid: string;
    clipnumber: number;    
    clipid: string;
    name?: string;
    thumbnail_link?: string;
    rating?: number;

    //not in db
    invalid?: boolean;
}

export interface HighlightEffect {
    startAt: number;
    endAt: number;
    name: 'slowmotion'; // Slowmotion only enabled effect currently
}

export interface HighlightAnnotationImage {
    imageName: string;
    frame: HighlightAnnotationImageFrame;
}

export interface HighlightAnnotationImageFrame extends HighlightAnnotationPoint {
    w: number;
    h: number;
}

export interface HighlightAnnotationPoint {
    x: number;
    y: number;
}

export interface HighlightAnnoationDrawing {
    type: 'arrow';  //Arrow currently only supported value
    points: HighlightAnnotationPoint[];
}

export interface HighlightAnnoationEllipse {
    position: HighlightAnnotationPoint;
    radius: HighlightAnnotationPoint;
}

export interface HighlightAnnotationTextBoxes {
    //Not currently implemented
}

export interface HighlightAnnoation {
    time: number;
    drawings: HighlightAnnoationDrawing[];
    ellipses: HighlightAnnoationEllipse[];
    textboxes: HighlightAnnotationTextBoxes[];
    images: HighlightAnnotationImage[];    
}

export interface AutoGameHighlight {
	_id?: string;
	userid: User['_id'];
	teamid: Team['_id'];
	sportid: Sport;
	gameid: Game['_id'];
	seasonid: number;
	odkFilters?: string[];
	state?: AutoHighlightState;
	currentClipnumber?: number;
	clips: AutoGameHighlightClip[];
  musiclist?: MusicItem[];
	highlightid?: Highlight['_id'];
  trimmingAssignment?: QwikBuildAssignmentInfo;
	dateSubmitted?: Date;
	createdAt?: Date;
	updatedAt?: Date;

	//not in db
	game?: Game;	
	team?: Team;
	user?: User;
}

export interface AutoGameHighlightClip {
	clipnumber: number;
	angle: Angle;
	rating: number;
	startAt?: number;
	endAt?: number;
	trimStart?: number;
	trimEnd?: number;
	annotations: HighlightAnnoation[];
	effects: HighlightEffect[];
	cliplink: string;
}

export enum AutoHighlightState {
	SELECTING_FILTERS = 'Selecting Filters',
	SELECTING_CLIPS = 'Selecting Clips',
	EDITING_CLIPS = 'Editing Clips',
	FINALIZING = 'Finalizing',
	COMPLETE = 'Complete'
}

export interface QwikBuildAssignmentInfo {
  userid?: User['_id'];
  state?: AssignmentStateValue;
  startDate?: Date;
  endDate?: Date;
	assignDate?: Date;
  time?: number;
	trimmingState?: QwikBuildAssignmentState;
}

export enum QwikBuildAssignmentState {
	trimming = 'Trimming',
	identification = 'Identification',
	complete = 'complete'
}