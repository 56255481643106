import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'app-error-aware-image',
  templateUrl: './error-aware-image.component.html',
  styleUrls: ['./error-aware-image.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ErrorAwareImageComponent implements OnInit {

  errored: boolean = false;     // Used to shown broke image icon if no image or error
  toolTipText: string = '';     // Used to display error tooltip message
  
  @Input() imageSource: string; //URL for the image to load
 

  constructor() { }

  ngOnInit(): void {
  }

  onError() {
    this.errored = true;
    this.toolTipText = 'There was a problem loading this image. It may have been removed or deleted. Verify you can view it before using in your highlight';                
  }

}
