import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import {throwError as observableThrowError, lastValueFrom } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ApiHelperService } from './api-helper.service';
import { GenericAPIArrayResponse, GenericAPIInsertUpdateResponse, GenericAPIResponse, LoginApiResponse } from '../models/api-response';
import { AthleteMetrics } from '../models/athlete-metrics';

@Injectable({
  providedIn: 'root'
})
export class AthletemetricsService {

  constructor(
    private http: HttpClient,
    private apiHelper: ApiHelperService,
    private router: Router
  ) { }

  getMetricForAthlete(userid: string): Promise<GenericAPIResponse<AthleteMetrics>> {
    const url = `${this.apiHelper.apiUrl()}/metrics/user/${userid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIResponse<AthleteMetrics>>(url, { headers }));
  }

  getMetrics(_id: string): Promise<GenericAPIResponse<AthleteMetrics>> {
    const url = `${this.apiHelper.apiUrl()}/metrics/${_id}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIResponse<AthleteMetrics>>(url, { headers }));
  }

	getMetricsForUsers(userids: string[]): Promise<GenericAPIArrayResponse<AthleteMetrics>> {
    const url = `${this.apiHelper.apiUrl()}/metrics`;
    const headers = this.apiHelper.apiHeaderWithToken();
		const body = {userids};

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<AthleteMetrics>>(url, body, { headers }));
  }

  deleteMetrics(_id: string): Promise<GenericAPIResponse<undefined>> {
    const url = `${this.apiHelper.apiUrl()}/metrics/${_id}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.delete<GenericAPIResponse<undefined>>(url, { headers }));
  }

  insertMetric(metric: AthleteMetrics): Promise<GenericAPIInsertUpdateResponse<AthleteMetrics>> {
    const body = {
      metric: metric
    }

    const url = `${this.apiHelper.apiUrl()}/metrics/metric`;
    const headers = this.apiHelper.apiHeaderWithToken();
    return lastValueFrom(this.http.post<GenericAPIInsertUpdateResponse<AthleteMetrics>>(url, body, { headers }));
  }

  insertManyMetrics(data: AthleteMetrics[]): Promise<GenericAPIArrayResponse<AthleteMetrics>> {
    const body = {
      data
    }

    const url = `${this.apiHelper.apiUrl()}/metrics/insert-many`;
    const headers = this.apiHelper.apiHeaderWithToken();
    return lastValueFrom(this.http.post<GenericAPIArrayResponse<AthleteMetrics>>(url, body, { headers }));
  }

  updateMetric(id: string, metric: AthleteMetrics): Promise<GenericAPIInsertUpdateResponse<AthleteMetrics>>  {
    const body = {
      update: metric,
      _id: id
    }

    const url = `${this.apiHelper.apiUrl()}/metrics/metric`;
    const headers = this.apiHelper.apiHeaderWithToken();
    return lastValueFrom(this.http.put<GenericAPIInsertUpdateResponse<AthleteMetrics>>(url, body, { headers }));
  }

  updateMultipleMetrics(updateData: {userid: string, update: any}[]): Promise<GenericAPIResponse<AthleteMetrics>> {
    const url = `${this.apiHelper.apiUrl()}/metrics`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {updateData};

    return lastValueFrom(this.http.put<GenericAPIResponse<AthleteMetrics>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }
}
