import { Component, OnDestroy, OnInit, ViewChild, HostListener, HostBinding } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { OverlayContainer} from '@angular/cdk/overlay';
import { Subscription, debounceTime } from 'rxjs';
import { ApiHelperService } from '../../services/api-helper.service';
import { LocalStorageService, Theme } from '../../services/local-storage.service';
import { UserService } from '../../services/user.service';
import { ScreenService } from '../../services/screen.service';
import { TeamService } from '../../services/team.service';
import { TeamsettingsService } from '../../services/teamsettings.service';
import { StorageService } from '../../services/storage.service';
import { GameService } from '../../services/game.service';
import { SocketIOService } from '../../services/socket-io.service';
import { DialogService } from '../../services/dialog.service';
import { ChatService } from '../../services/chat.service';
import { NotificationService } from '../../services/notification.service';
import { ToastrService } from 'ngx-toastr';
import { User, UserRole, UserRoleValue } from 'src/app/models/user';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Sport } from 'src/app/models/conference';
import { continuousSports } from '../../constants/sport.constants';
import { GameStorageData, StorageAddition, Team, TeamSettings } from 'src/app/models/team';
import { MatMenuTrigger } from '@angular/material/menu';
import { Conversation } from 'src/app/models/chat';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css'],
})
export class NavComponent implements OnInit, OnDestroy {
  sideNavOpen: boolean = false;
  signedIn: boolean = false;
  userSub: Subscription;
  socketUserSub: Subscription;
  user: User;
  sportid: Sport;
  teamSettings: TeamSettings;
  organizationTeams: Team[] = [];
  theme: Theme = 'dark';
  readonly continuousSports = continuousSports;
  currentUrl: string;
  userRoleSections: UserRoleSection[] = [];

  isAdmin: boolean = false;
  elevatedPriv: boolean = false;
  canSwitchTeams: boolean = false;
  isParent: boolean = false;
  isProspect: boolean = false;
  isShooter: boolean = false;
  isStatistician: boolean = false;
  isStatManager: boolean = false;
	isScoutAdmin: boolean = false;
  isScoutManager: boolean = false;
  isScout: boolean = false;
  isFan: boolean = false;
  isDemo: boolean = false;
  isRecruiter: boolean = false;
  leaguePoolOnly: boolean = false;
  leaguePoolAdmin: boolean = false;
  hasHomePage: boolean = false;
  hasResources: boolean = false;
	thirdPartyUser: boolean = false;
  filterConversations: Conversation[];

  activeConnectionSub: Subscription;
  connectionIsActive: boolean = true;

  storageSub: Subscription;
  conversationSub: Subscription;
	openChatSub: Subscription;
  gameStorageData: GameStorageData[] = [];
  totalStorageHours: number = 0;
  totalStorageLimit: number = 50;
  showStorage: boolean = false;

	showingZohoChat: boolean = false;
	zohoChatWidget: HTMLScriptElement;

	apsThemeActive: boolean = false;
  @HostBinding('class') componentCssClass;

  @ViewChild('chatMenuTrigger') chatMenuTrigger: MatMenuTrigger;

  isHandset$ = this.screenService.isHandset$;
  readonly disableUserSync: boolean = false;

  @HostListener('document:visibilitychange', ['$event'])
  handleVisibilityChange(event: any): void {
    // this.socketIOService.ngOnDestroy();
    // this.socketIOService.setupSocketIo();
    // this.setupSubscriptions();
  }

  constructor(
    private screenService: ScreenService,
    private router: Router,
    private apiHelper: ApiHelperService,
    private localStorageService: LocalStorageService,
    private userService: UserService,
    private teamService: TeamService,
    private teamsettingsService: TeamsettingsService,
    private gameService: GameService,
    private storageService: StorageService,
    private dialogService: DialogService,
    private socketIOService: SocketIOService,
    private toastr: ToastrService,
    private chatService: ChatService,
    private notificationService: NotificationService,
		public overlayContainer: OverlayContainer
  ) {}

  ngOnInit()  {
    this.setupSubscriptions();
		this.notificationService.requestPermission();
    if (this.signedIn) {
      this.getUser();
    }

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.currentUrl = e.url;
				this.apsThemeActive = _.includes(['/scouting/aps-login', '/scouting/aps-athlete', '/scouting/signup'], this.currentUrl) || _.startsWith(this.currentUrl, '/scouting/evaluate-review');
				this.setTheme();
        if (e.url === '/') {
          if (this.signedIn)
            this.userService.performInitialNavigation(this.user);
          else this.logout();
        } else if (this.signedIn) {
          this.checkForTeamPayment();
          this.checkForUserAccessPayment();
          this.checkForTooManyActiveConnections();
        }
      }
    });

    window.addEventListener('storage', (ev: StorageEvent) => {
      if (ev.key === 'user' && !this.disableUserSync) {
        if (!ev.newValue) this.logout();
        else {
          const updatedUser: User = JSON.parse(ev.newValue);
          if (updatedUser?._id !== this.user?._id) {
            this.localStorageService.emitUserUpdate(updatedUser);
            this.userService.performInitialNavigation(
              updatedUser,
              this.currentUrl
            );
          }
        }
      }
    });
  }

	setTheme() {
		const theme = this.apsThemeActive ? 'aps-light-theme' : 'default-theme';
		const themeToRemove = !this.apsThemeActive ? 'aps-light-theme' : 'default-theme';
		if (this.overlayContainer.getContainerElement().classList.contains(themeToRemove)) this.overlayContainer.getContainerElement().classList.remove(themeToRemove);
		this.overlayContainer.getContainerElement().classList.add(theme);
    this.componentCssClass = theme;
	}

  setupSubscriptions() {
		// this.socketIOService.setupSocketIo();
    if (this.userSub) this.userSub.unsubscribe();
    this.userSub = this.localStorageService
      .listenForUserUpdate()
      .subscribe((user) => {
        this.user = user;
        this.setSport();
        this.checkIfSignedIn();
        this.organizeUserRoles();
        this.checkForTeamPayment(true);
        this.checkForUserAccessPayment(true);
        this.checkForGamesToConfirm();
				this.checkForActiveInvitationCampaign();
        this.getTeamStorageData();
      });

    if (this.storageSub) this.storageSub.unsubscribe();
    this.storageSub = this.storageService
      .listenForReloadStorageData()
      .subscribe(() => {
        this.getTeamStorageData();
      });

    if (this.activeConnectionSub) this.activeConnectionSub.unsubscribe();
    this.activeConnectionSub = this.socketIOService
      .listenForActiveConnectionChange()
      .subscribe({
        next: (active) => {
          this.connectionIsActive = active;
          this.checkForTooManyActiveConnections();
        },
      });

		if (this.openChatSub) this.openChatSub.unsubscribe();
		this.openChatSub = this.chatService
			.listenToOpenChat()
			.subscribe({
				next: chatData => {
					this.chatMenuTrigger?.openMenu();
				}
			});

    this.user = this.localStorageService.getUser();
    this.theme = this.localStorageService.getTheme();
    this.setSport();
    this.checkIfSignedIn();
    this.organizeUserRoles();
  }

  ngOnDestroy() {
    if (this.userSub) this.userSub.unsubscribe();
    if (this.socketUserSub) this.socketUserSub.unsubscribe();
    if (this.storageSub) this.storageSub.unsubscribe();
    if (this.activeConnectionSub) this.activeConnectionSub.unsubscribe();
		if (this.openChatSub) this.openChatSub.unsubscribe();
		if (this.conversationSub) this.conversationSub.unsubscribe();
  }

	onChatMenuClosed() {
		setTimeout(() => {
			this.chatService.emitChatClosed();
		}, 250);
	}

  listenForRemoteUserUpdate() {
    if (this.socketUserSub) this.socketUserSub.unsubscribe();
    if (!this.user?._id) return;
    this.socketUserSub = this.socketIOService
      .listenForUpdatedUser(this.user._id)
      .subscribe((updatedUser) => {
        if (updatedUser && !this.disableUserSync) {
          const skipNav =
            _.includes(this.currentUrl, 'admin-dashboard') &&
            _.includes(
              ['admin', 'subadmin', 'junioradmin'],
              updatedUser.currentUserRole?.role
            ) &&
            _.includes(
              ['admin', 'subadmin', 'junioradmin'],
              this.user?.currentUserRole?.role
            );
          this.localStorageService.setUser(updatedUser);
          if (!skipNav)
            this.userService.performInitialNavigation(
              updatedUser,
              this.currentUrl
            );
        }
      });
  }

  getUser() {
    this.userService
      .getUser()
      .then((res) => {
        if (res.success) {
          if (res.token) this.localStorageService.setToken(res.token);
          if (res.user) this.localStorageService.setUser(res.user);
					this.getConversations();
        } else this.logout();
      })
      .catch((err) => {
        console.log(err);
        this.logout();
      });
  }

  checkForTooManyActiveConnections() {
    if (!this.isFan) return;
    if (this.currentUrl !== '/active-connections' && !this.connectionIsActive)
      this.router.navigate(['/active-connections']);
    else if (
      this.currentUrl === '/active-connections' &&
      this.connectionIsActive
    )
      this.userService.performInitialNavigation(this.user);
  }

  setSport() {
    if (this.user && this.user.team && this.user.team.conference)
      this.sportid = this.user.team.conference.sportid;
    else if (this.user && this.user.team && this.user.team.sportid)
      this.sportid = this.user.team.sportid;
    else this.sportid = 'generic';
  }

  checkIfSignedIn() {
    this.signedIn = !!this.user;
    if (
      this.signedIn &&
      (!this.user?.currentUserRole ||
        (_.includes(
          [
            'admin',
            'subadmin',
            'junioradmin',
            'boardmember',
            'teamadmin',
            'coach',
            'athlete',
            'single',
            'demo',
          ],
          this.user?.currentUserRole?.role
        ) &&
          !this.user?.team))
    ) {
      this.apiHelper.showErrorResponse(
        'Your account currently does not belong to a team. Please contact your head coach so that they can add you!'
      );
      this.logout();
    }
  }

  checkForTeamPayment(showWarning: boolean = false) {
    if (this.user?.team) {
      if (
        !this.isAdmin &&
        (_.includes(this.currentUrl, 'my-video') ||
          _.includes(this.currentUrl, 'upload-video') ||
          _.includes(this.currentUrl, 'highlights') ||
          _.includes(this.currentUrl, 'playbook')) &&
        (!this.user.team.subscriptionExpires ||
          moment().isAfter(moment(this.user.team.subscriptionExpires)))
      )
        this.navigateToUserPayments();
      else if (
        this.elevatedPriv &&
        showWarning &&
        this.user.team.subscriptionExpires
      ) {
        const teamSubMoment = moment(this.user.team.subscriptionExpires);
        if (
          moment().add(1, 'month').isAfter(teamSubMoment) &&
          moment().isSameOrBefore(teamSubMoment)
        ) {
          this.toastr.warning(
            `Your team's subscription ends on ${moment(
              this.user.team.subscriptionExpires
            ).format('MMM Do, YYYY')}!`,
            'Subscription Expires Soon!'
          );
        }
      }
    }
  }

  checkForUserAccessPayment(showWarning: boolean = false) {
    if ((this.user?.team?.payForAccess && this.user?.currentUserRole?.role === 'athlete') || this.user?.currentUserRole?.role === 'parent') {
      if (
        !this.isAdmin &&
        (_.includes(this.currentUrl, 'my-video') ||
          _.includes(this.currentUrl, 'upload-video') ||
          _.includes(this.currentUrl, 'highlights') ||
          _.includes(this.currentUrl, 'playbook') ||
          _.includes(this.currentUrl, 'team-stats')) &&
        (!this.user.currentUserRole.subscriptionExpires ||
          moment().isAfter(
            moment(this.user.currentUserRole.subscriptionExpires)
          ))
      )
        this.navigateToUserPayments();
      else if (showWarning) {
        const userSubMoment = moment(
          this.user.currentUserRole.subscriptionExpires
        );
        if (
          moment().add(1, 'month').isAfter(userSubMoment) &&
          moment().isSameOrBefore(userSubMoment)
        ) {
          this.toastr.warning(
            `Your subscription ends on ${moment(
              this.user.currentUserRole.subscriptionExpires
            ).format('MMM Do, YYYY')}!`,
            'Subscription Expires Soon!'
          );
        }
      }
    }
  }

  organizeUserRoles() {
    if (!this.user || !this.user.userRoles || this.user.userRoles.length === 0)
      return;
    if (this.user.currentUserRole) {
      this.isAdmin = _.includes(
        ['admin', 'subadmin', 'junioradmin'],
        this.user.currentUserRole.role
      );
      this.isParent = this.user.currentUserRole.role === 'parent';
      this.isProspect = this.user.currentUserRole.role === 'prospect';
      this.isShooter = this.user.currentUserRole.role === 'shooter';
      this.isStatManager = this.user.currentUserRole.role === 'statmanager';
      this.isStatistician = this.user.currentUserRole.role === 'statsapi';
      this.isFan = this.user.currentUserRole.role === 'fan';
      this.isDemo = this.user.currentUserRole.role === 'demo';
      this.isRecruiter = this.user.currentUserRole.role === 'recruiter';
      this.isScoutAdmin = this.user.currentUserRole.role === 'scoutadmin';
      this.isScoutManager = this.isScoutAdmin || this.user.currentUserRole.role === 'scoutmanager';
			this.isScout = this.user.currentUserRole.role === 'scout' || this.isScoutManager;
			this.thirdPartyUser = this.user.currentUserRole.role === 'thirdparty';
      this.canSwitchTeams =
        this.isAdmin || _.includes(['boardmember', 'thirdparty'], this.user.currentUserRole.role);
      this.elevatedPriv =
        this.canSwitchTeams ||
        _.includes(
          ['teamadmin', 'coach', 'single'],
          this.user.currentUserRole.role
        );
      this.leaguePoolAdmin = this.user.currentUserRole.role === 'leagueadmin';
      this.leaguePoolOnly =
        (!!this.user.team?.organization?.leaguepoolonly &&
          !this.isAdmin &&
          !this.isShooter &&
          !this.isStatistician &&
          !this.isStatManager) ||
        this.leaguePoolAdmin;
      this.hasHomePage =
        !this.leaguePoolOnly &&
        _.includes(
          [
            'admin',
            'subadmin',
            'junioradmin',
            'boardmember',
            'teamadmin',
            'coach',
            'athlete',
            'parent',
						'thirdparty'
          ],
          this.user.currentUserRole.role
        );
      this.hasResources =
        (this.canSwitchTeams ||
          _.includes(
            ['teamadmin', 'coach', 'single', 'thirdparty', 'athlete'],
            this.user.currentUserRole.role
          )) &&
        this.user.team?.conference?.sportid === 'football' &&
        this.user.team?.conference?.type === 'highschool';
      this.getOrganizationTeams();
      
      if (this.isParent) this.getTeamSettings();
      else this.teamSettings = null;

      if (this.isDemo)
        this.toastr.warning(
          'You will not be able to make any changes, but you are free to look around!',
          'You are in a DEMO account!'
        );
    }

    this.userRoleSections = [];
    _.forEach(this.user.userRoles, (userRole) => {
      let sectionName = '';
      if (
        userRole &&
        !_.includes(['admin', 'subadmin', 'junioradmin', 'thirdparty'], userRole.role) &&
        userRole.team &&
        userRole.team.conference
      ) {
        sectionName = this.getSportDisplay(userRole.team.conference.sportid);
			}

      const sectionIdx = _.findIndex(this.userRoleSections, {
        name: sectionName,
      });
      if (sectionIdx > -1) this.userRoleSections[sectionIdx].userRoles.push(userRole);
      else {
        this.userRoleSections.push({
          name: sectionName,
          userRoles: [userRole],
        });
      }
    });

    this.userRoleSections = _.orderBy(this.userRoleSections, 'name', 'asc');
    _.forEach(this.userRoleSections, (section) => {
      section.userRoles = _.orderBy(
        section.userRoles,
        (userRole) => {
          if (_.includes(['admin', 'subadmin', 'junioradmin', 'thirdparty'], userRole.role))
            return -1;
          return _.toLower(userRole?.team?.name || '');
        },
        'asc'
      );
    });

    this.listenForRemoteUserUpdate();
  }

  getOrganizationTeams() {
    this.organizationTeams = [];
    if (!this.canSwitchTeams || !this.user?.team?.organizationid) return;
    this.teamService
      .getOrganizationTeams(this.user.team.organizationid)
      .then((res) => {
        if (res.success) {
          this.organizationTeams = res.results || [];
        }
      })
      .catch((err) => console.log(err));
  }

  getTeamSettings() {
    if (!this.user?.currentUserRole?.teamid) return;
    this.teamsettingsService.getTeamSettings(this.user.currentUserRole.teamid)
    .then(res => {
      if (res.success && res.result) {
        this.teamSettings = res.result;
      }
    })
    .catch(err => this.apiHelper.showErrorResponse(err));
  }

  checkForGamesToConfirm() {
		let userRoles: UserRoleValue[] = ['boardmember', 'teamadmin', 'coach'];
		if (this.user?.team?.conference?.boardmemberConfirm) userRoles = ['boardmember'];

    if (
      !this.user?.currentUserRole?.role ||
      !this.user?.currentUserRole?.teamid ||
      !_.includes(userRoles, this.user.currentUserRole.role)
    )
      return;
    this.gameService
      .getGamesToConfirm()
      .then((res) => {
        if (res.success && res.results?.length) {
          const unconfirmedGames = _.filter(res.results || [], (game) => {
            if (this.canSwitchTeams) {
              if (
                game.awayteam?.organizationid ===
                this.user?.team?.organizationid
              )
                return !game.awayConfirm?.confirmedByTeamid;
              else if (
                game.hometeam?.organizationid ===
                this.user?.team?.organizationid
              )
                return !game.homeConfirm?.confirmedByTeamid;
            } else {
              if (game.awayteamid === this.user?.currentUserRole?.teamid)
                return !game.awayConfirm?.confirmedByTeamid;
              else if (game.hometeamid === this.user?.currentUserRole?.teamid)
                return !game.homeConfirm?.confirmedByTeamid;
            }
            return false;
          });

          if (unconfirmedGames?.length > 0)
            this.dialogService
              .openConfirmGamesDialog(this.user, res.results)
              .catch((err) => console.log(err));
        } else if (res.message) throw res.message;
      })
      .catch((err) => console.log(err));
  }

	checkForActiveInvitationCampaign() {
		if (!this.user?._id) return;
		this.userService.getActiveInvitationCampaign(this.user._id)
		.then(res => {
			if (res.success && res.result) {
				this.dialogService.openCompleteInvitationCampaignDialog(res.result).catch(err => console.log(err));
			}
		})
		.catch(err => console.log(err));
	}

  switchUserRole(userRole: UserRole) {
    if (!userRole) return;
    this.userService
      .switchUserRole(userRole)
      .then((res) => {
        if (res.success) {
          if (res.result && this.disableUserSync) {
            this.localStorageService.setUser(res.result);
            this.userService.performInitialNavigation(
              res.result,
              this.currentUrl
            );
          }
        }
      })
      .catch((err) => {
        this.apiHelper.showErrorResponse(err);
      });
  }

  switchTeam(team: Team) {
    this.userService.switchTeam(team._id).then((res) => {
      if (res.success) {
        if (res.result && this.disableUserSync) {
          this.localStorageService.setUser(res.result);
          this.userService.performInitialNavigation(
            res.result,
            this.currentUrl
          );
        }
      }
    });
  }

  getTeamStorageData() {
    if (!this.user?.team) {
      this.showStorage = false;
      return;
    }
    this.showStorage = _.includes(
      [
        'admin',
        'subadmin',
        'junioradmin',
        'teamadmin',
        'boardmember',
        'single',
				'thirdparty'
      ],
      this.user.currentUserRole?.role
    );
    if (!this.showStorage) return;

    Promise.all([
      this.storageService.getTeamStorageData(this.user.team._id),
      this.storageService.getStorageAdditions(this.user.team._id),
    ])
      .then(([dataRes, additionRes]) => {
        if (dataRes.success) {
          this.gameStorageData = dataRes.results;
          let totalHours = 0;
          _.forEach(this.gameStorageData, (data) => {
            if (!data.archived) totalHours += data.hours;
          });
          this.totalStorageHours = totalHours;
        }

        this.totalStorageLimit =
          this.storageService.getDefaultStorageFromTeamLevel(
            this.user?.team?.teamlevel,
            this.user
          );
        if (additionRes.success) {
          const storageAdditions: StorageAddition[] = additionRes.results;
          if (storageAdditions && storageAdditions.length > 0) {
            _.forEach(storageAdditions, (addition) => {
              this.totalStorageLimit += addition.hours;
            });
          }
        }
      })
      .catch((err) => this.apiHelper.showErrorResponse(err));
  }

  getStorageBarColor(): 'primary' | 'warn' {
    if (this.totalStorageLimit) {
      const percentage = this.totalStorageHours / this.totalStorageLimit;
      if (percentage >= 0.85) return 'warn';
    }
    return 'primary';
  }

  navigateToMyVideos() {
    if (!this.leaguePoolOnly && _.includes(this.continuousSports, this.sportid))
      this.router.navigate(['/my-video-continuous']);
    else this.router.navigate(['/my-video']);
  }

  navigateToUserPayments() {
    this.router.navigate(['/user-payments']);
  }

  navigateToTeamStorage() {
    this.router.navigate(['/manage-storage']);
  }

  isCurrentUserRole(userRole: UserRole): boolean {
    if (!this.user || !this.user.currentUserRole || !userRole) return false;
    return (
      this.user.currentUserRole.role === userRole.role &&
      this.user.currentUserRole.teamid === userRole.teamid
    );
  }

  getUserRoleTitle(): string {
    if (!this.user || !this.user.currentUserRole) return '';
    let returnValue: string = this.prettifyRole(this.user.currentUserRole.role);
    if (this.user && this.user.team) {
      returnValue += ` - ${this.user.team.name}${
        !this.canSwitchTeams && this.user.team.teamlevel ? ` - ${this.user.team.teamlevel.name}` : ''
      }${
        this.user.team.conference ? ` (${this.user.team.conference.name})` : ''
      }`;
    } else if (this.user?.leaguePool) {
      returnValue += ` - ${this.user.leaguePool.name}`;
    }
    return returnValue;
  }

  getCurrentTeamTitle(): string {
    if (!this.user || !this.user.team?.teamlevel) return '';
    return `Current Team: ${this.user.team.teamlevel.name}`;
  }

  prettifyRole(role: UserRoleValue): string {
    return this.userService.prettifyRole(role);
  }

  getSportIconName(userRole: UserRole): string {
    let sportid: Sport = null;
    if (!userRole || !userRole.team || !userRole.team.conference) {
      if (userRole) {
        if (userRole.role === 'shooter') return 'videocam';
        else if (userRole.role === 'statsapi') return 'bar_chart';
        else if (userRole.role === 'leagueadmin') return 'admin_panel_settings';
        else if (userRole.role === 'recruiter') return 'search';
				else if (userRole.role === 'thirdparty') return '3p';
				else if (userRole.role === 'scoutadmin') return 'admin_panel_settings';
        else if (userRole.team && userRole.team.sportid)
          sportid = userRole.team.sportid;
        else return 'sports';
      } else return 'sports';
    }

    if (!sportid) sportid = userRole.team?.conference?.sportid;
    switch (sportid) {
      case 'football':
      case 'basketball':
      case 'soccer':
      case 'volleyball':
      case 'baseball':
      case 'rugby':
      case 'tennis':
      case 'hockey':
        return `sports_${sportid}`;
      case 'fieldhockey':
        return 'sports_hockey';
      case 'softball':
        return 'sports_baseball';
      case 'wrestling':
        return 'sports_kabaddi';
      case 'band':
        return 'music_note';
      case 'boxing':
        return 'sports_mma';
      default:
        return 'sports';
    }
  }

  getSportDisplay(sport: Sport): string {
    if (sport === 'fieldhockey') return 'Field Hockey';
    return _.capitalize(sport);
  }

  logout() {
		if (this.showingZohoChat) this.toggleZohoChat();
    this.userService.emitLogout();
    this.sideNavOpen = false;
    this.isAdmin = false;
    this.canSwitchTeams = false;
    this.elevatedPriv = false;
    this.localStorageService.removeToken();
    this.localStorageService.removeUser();
    this.router.navigate(['/login']);
  }

  getConversations() {
    if (!this.user) return;
    this.chatService.getConversations()
		.then(conversationData => {
      const conversations = conversationData.results || [];
      const filterConversations: Conversation[] = []
      _.forEach(conversations, (conversation) => {
          const isRead = this.isReadLastMessage(conversation);
          if (!isRead) {
            filterConversations.push(conversation)
          }
      });
      this.filterConversations = filterConversations;
      this.conversationSubHandle();
    });
  }

  conversationSubHandle() {
    if (!this.user) return;
		if (this.conversationSub) this.conversationSub.unsubscribe();
    this.conversationSub = this.socketIOService
    .listenForChatMessage(`${this.user._id}-listConversation`)
    .subscribe({
      next: (conversationHasMess: Conversation) => {
        const isRead = this.isReadLastMessage(conversationHasMess);
        const hasDisabled = _.includes(conversationHasMess?.disabledNotifications, this.user._id);
        const currentConversationIndex = _.findIndex(this.filterConversations, (conversation) => {
          return conversation._id === conversationHasMess._id;
        });
				
        if (!hasDisabled && !isRead) {
          this.notificationService.showNotification('You have a new QwikCut message.', {body: conversationHasMess?.lastMessage?.message || ''});
        }
        if (currentConversationIndex === -1 && !isRead) {
          this.filterConversations.push(conversationHasMess);
        } 
        if (currentConversationIndex !== -1 && isRead) {
          this.filterConversations.splice(currentConversationIndex, 1);
        }
      },
      error: (err) => console.log(err),
    });
  }

  isReadLastMessage(convo: Conversation) {
    if (!convo?.lastMessage?.readBy) return true;
    return _.some(convo.lastMessage.readBy, (readBy) => {
      return readBy?.userId === this.user?._id;
    });
  }

  manageUserNotifications() {
    if (!this.user?._id) return;
    this.dialogService.openManageUserNotificationsDialog(this.user._id, this.sportid).catch(err => console.log(err));
  }

	toggleZohoChat() {
		if (!this.showingZohoChat) {
			if (this.zohoChatWidget != null) {
				const chatButtonWidget = <HTMLDivElement>document.getElementsByClassName('zsiq_floatmain')[0];
				const chatWindowWidget = <HTMLDivElement>document.getElementsByClassName('zls-sptwndw')[0];
				if (chatButtonWidget) {
					chatButtonWidget.style.display = 'block';
					chatButtonWidget.click();
				}
				if (chatWindowWidget) chatWindowWidget.style.display = 'block';
				this.showingZohoChat = true;
			}
			else {
				const chatScriptLoc = 'assets/js/zoho-chat.js';
				this.zohoChatWidget = document.createElement('script');
				this.zohoChatWidget.src = chatScriptLoc;
				this.zohoChatWidget.type = 'text/javascript';
				this.zohoChatWidget.async = true;
				document.getElementsByTagName('head')[0].appendChild(this.zohoChatWidget);
				this.showingZohoChat = true;

				setTimeout(() => {
					try{
						const chatButtonWidget = <HTMLDivElement>document.getElementsByClassName('zsiq_floatmain')[0];
						if (chatButtonWidget) {
							chatButtonWidget.style.display = 'block';
							chatButtonWidget.click();
						}
					} catch(err) {console.log(err)};
				}, 1000);
			}
		}
		else if (this.zohoChatWidget != null) {
			const chatButtonWidget = <HTMLDivElement>document.getElementsByClassName('zsiq_floatmain')[0];
			const chatWindowWidget = <HTMLDivElement>document.getElementsByClassName('zls-sptwndw')[0];
			if (chatButtonWidget) chatButtonWidget.style.display = 'none';
			if (chatWindowWidget) chatWindowWidget.style.display = 'none';
			this.showingZohoChat = false;
		}
	}
}

interface UserRoleSection {
  name: string;
  userRoles: UserRole[];
}