import { Game } from "./game";
import { TeamGroup } from "./team";
import { User } from "./user";

export interface Message {
  _id?: string;
  conversationId: Conversation['_id'],
  senderId: User['_id'];
  message: string;
  readBy?: ReadBy[];
  firstName?: string,
  lastName?: string,
  reaction?: Reaction,
  createdAt?: Date;
  file?: MessageFile;

	//not in db
	user?: User;
}

export interface MessageFile {
	_id?: string;
  name: string;

	//not in db
  url?: string;
	type?: 'image' | 'video' | 'other';
}

export interface Conversation {
  _id: string;
  name: string;
  members?: User['_id'][];
  createdAt: Date;
  createdBy?: User['_id'];
  groupId?: TeamGroup['_id'];
	gameid?: Game['_id']
  lastMessage?: Message,
  disabledNotifications: User['_id'][]; 
  // TODO: update these with proper type when updating the functions
  avatar: any;
  subject: any;
  group: any;
  users?: {_id: User['_id'], firstname: string, lastname: string}[];
}

export interface ReadBy {
  userId: User['_id'];
  readAt: Date;
}

export interface ScheduleMessage {
  _id?: string,
  conversationId: Conversation["_id"];
  senderId: User["_id"];
  scheduleDateTime: Date;
  scheduleDateTimeConvert?: string;
  message: string;
}

export interface Reaction {[userId: string]: string}

export type ConversationType = 'group' | 'person';
