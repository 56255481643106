import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Material Design Modules
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faCircle as farCircle } from '@fortawesome/free-regular-svg-icons';
import { faSpinner, faCircle, faEdit, faTrashAlt, faFilm, faEnvelope, faCopy, faUndo, faAngleDoubleUp, faAngleDoubleDown, faSort, faCaretUp, faCaretDown, 
  faCloudDownloadAlt, faSave, faRunning, faTimesCircle, faUndoAlt, faCamera, faPlus, faPlayCircle, faStopCircle, faUpload, faMusic, faPhotoVideo,
  faStar, faFire, faPlusSquare, faStop, faMinus, faPlay, faCommentAlt, faImage, faTimes, faUserCheck, faUser, faHeart, faThumbsUp,
  faAddressCard, faVideo, faCertificate, faUsers, faUserAlt, faShare, faTag, faList, faTrophy, faClock, faFootballBall, faFutbol, 
  faBaseballBall, faBasketballBall, faVolleyballBall, faHockeyPuck, faImages, faCrosshairs  } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faInstagram, faSnapchat, faTiktok } from '@fortawesome/free-brands-svg-icons';


@NgModule({
  declarations: [],
  imports: [],
  exports: [
    CommonModule,
    FontAwesomeModule,
  ]
})
export class IconsModule { 
  constructor(private library: FaIconLibrary) {
    library.addIcons(faSpinner, faCircle, faEdit, faTrashAlt, faFilm, faEnvelope, faCopy, faUndo, 
      faAngleDoubleUp, faAngleDoubleDown, faSort, faCaretUp, faCaretDown, faCloudDownloadAlt, faSave, 
      faRunning, faTimesCircle, faUndoAlt, faCamera, faPlus, faFacebook, faTwitter, faPlayCircle, faUser,
      faStopCircle, faUpload, faMusic, faPhotoVideo, faStar, faFire, faPlusSquare, faStop, faMinus, 
      faPlay, faCommentAlt, farCircle, faImage, faTimes, faUserCheck, faInstagram, faSnapchat, faTiktok,
      faAddressCard, faVideo, faHeart, faThumbsUp, faCertificate, faUsers, faUserAlt, faShare, faTag, faList, faCrosshairs,
      faTrophy, faClock, faFootballBall, faFutbol, faBaseballBall, faBasketballBall, faVolleyballBall, faHockeyPuck, faImages );
  }
}
