import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { HighlightClip } from 'src/app/models/highlight';

@Component({
  selector: 'app-video-image',
  templateUrl: './video-image.component.html',
  styleUrls: ['./video-image.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class VideoImageComponent implements OnInit {

  imgSource: string;            // Src var for the generated data image
  loading: boolean = false;     // Used to show loading while making the image
  noImage: boolean = false;     // Used to shown broke image icon if no image or error
  toolTipText: string = '';     // Used to display error tooltip message
  _videoSrc: string;
  retries: number = 0;
  
  readonly defaultPosition: number = 0.001;           // Default position if not specified.
  @Input() position: number = this.defaultPosition;   // Position in the video to get the frame from.
  @Input() clip: HighlightClip;
	@Input() showPlayIcon: boolean = false;

  get videoSrc(): string {
    return this._videoSrc;
  }
  @Input() set videoSrc(value: string) {
    this.loadClip(value);
  }

  loadClip(value: string) {
    if (!value) return;

    this._videoSrc = value;
    this.loading = true;
    this.noImage = false;
    this.toolTipText = '';

    if (!document) return;
    const video = document.createElement('video');
    video.crossOrigin = 'anonymous';

    video.onloadedmetadata = () => {
      if (this.clip && !this.clip.endAt) this.clip.endAt = video.duration || 3;

      if(video.duration == 0) {
        this.noImage = true;
        if (this.clip) this.clip.invalid = true;
				this.loading = false;
        return;
      }
      
      // Set current time to anytime before the video duration, but at least defaultPosition.
      video.currentTime = Math.max(this.position < video.duration ? this.position : this.defaultPosition, this.defaultPosition);                
    };

    video.onseeked = () => {
      const canvas = document.createElement('canvas');
      canvas.height = video.videoHeight;
      canvas.width = video.videoWidth;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      this.imgSource = canvas.toDataURL();
      this.loading = false;
    }

    video.onerror = (e) => {
      console.error(e);
      this.loading = false;
      if (++this.retries <= 3) {
        console.log(`Loading ${value} again!`);
        video?.remove();
        this.loadClip(value);
      }
      else {
        if (this.clip && !this.clip.endAt) this.clip.endAt = video.duration || 3;
        this.noImage = true;
        this.toolTipText = 'There was a problem loading this clip. It may have been removed or deleted. Verify you can view it before using in your highlight';     
        if (this.clip) this.clip.invalid = true;   
      }        
    }

    video.src = value;
  }

  constructor() { }

  ngOnInit(): void {
    
  }


 
}
