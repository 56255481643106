<div class="flex-column">
	<div class="flex-row" style="justify-content: flex-end; align-items: center; margin: 10px 0;">
		<ng-container *ngFor="let item of key">
			<div style="width: 18px; height: 18px; margin: 0 5px 0 10px;" [style.background-color]="item.color"></div>
			<mat-label>{{item.display}}</mat-label>
		</ng-container>
	</div>
	<div class="flex-row" *ngFor="let item of data" style="margin-bottom: 5px;">
		<div class="flex-row" style="flex: 1; width: unset; justify-content: flex-end; align-items: center;">
			<mat-label style="text-align: right; font-weight: bold; padding-right: 10px;">{{ item.display }}</mat-label>
		</div>
		<div class="value-container">
			<div *ngFor="let value of item.values; let index = index;" [matTooltip]="value + ''" [style.background-color]="key[index]?.color ?? 'white'" [style.width]="(100 * value / maxValue) + '%'"></div>
		</div>
	</div>
</div>