import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import {throwError as observableThrowError, lastValueFrom, Subject, Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ApiHelperService } from './api-helper.service';
import { GenericAPIArrayResponse, GenericAPIResponse, LoginApiResponse } from '../models/api-response';
import { GameStorageData, StorageAddition, TeamUsageData } from '../models/team';
import { User } from '../models/user';
import { Teamlevel } from '../models/conference';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    private http: HttpClient,
    private apiHelper: ApiHelperService,
    private router: Router
  ) { }


  private onReloadStorageData = new Subject();

  getDefaultStorageFromTeamLevel(teamlevel: Teamlevel, user?: User): number {
    if (!teamlevel || (user && user.currentUserRole?.role) === 'single') return 25;
    if (_.includes(['VS', 'VR', 'VAR', 'VARSITY'], _.toUpper(teamlevel.abbrv))) return 100;
    return 50;
  }

  listenForReloadStorageData(): Observable<any> {
    return this.onReloadStorageData.asObservable();
  }

  emitReloadStorageData() {
    this.onReloadStorageData.next(null);
  }

  getTeamStorageData(teamid: string): Promise<GenericAPIArrayResponse<GameStorageData>> {
    const url = `${this.apiHelper.apiUrl()}/storage/data/${teamid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIArrayResponse<GameStorageData>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  isTeamOverStorageLimit(teamid: string): Promise<GenericAPIResponse<boolean>> {
    const url = `${this.apiHelper.apiUrl()}/storage/limit/${teamid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIResponse<boolean>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  createStorageAddition(teamid: string, hours: number, description: string, amountPaid: number = 0): Promise<GenericAPIResponse<StorageAddition>> {
    const body = {
      hours,
      description,
      amountPaid
    }
    const url = `${this.apiHelper.apiUrl()}/storage/additions/${teamid}`;
    const headers = this.apiHelper.apiHeaderWithToken();
    
    return lastValueFrom(this.http.post<GenericAPIResponse<StorageAddition>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getStorageAdditions(teamid: string): Promise<GenericAPIArrayResponse<StorageAddition>> {
    const url = `${this.apiHelper.apiUrl()}/storage/additions/${teamid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIArrayResponse<StorageAddition>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  deleteSeasonPractices(teamid: string, seasonid: number): Promise<GenericAPIResponse<undefined>> {
    const body = {
      teamid,
      seasonid
    }
    const url = `${this.apiHelper.apiUrl()}/storage/delete-practices`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<undefined>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  deleteSeasonScoutGames(teamid: string, seasonid: number): Promise<GenericAPIResponse<undefined>> {
    const body = {
      teamid,
      seasonid
    }
    const url = `${this.apiHelper.apiUrl()}/storage/delete-scout`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<undefined>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  archiveSeasonVideo(teamid: string, seasonid: number): Promise<GenericAPIResponse<undefined>> {
    const body = {
      teamid,
      seasonid
    }
    const url = `${this.apiHelper.apiUrl()}/storage/archive`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<undefined>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  restoreSeasonVideo(teamid: string, seasonid: number): Promise<GenericAPIResponse<undefined>> {
    const body = {
      teamid,
      seasonid
    }
    const url = `${this.apiHelper.apiUrl()}/storage/archive`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.put<GenericAPIResponse<undefined>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }
  
  getTeamUsage(teamid: string, months: number): Promise<GenericAPIResponse<TeamUsageData>> {
    const body = {
      teamid,
      months
    }
    const url = `${this.apiHelper.apiUrl()}/storage/usage`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<TeamUsageData>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }
}
