/*** Outside library ***/
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import * as _ from 'lodash';
import { User } from '../models/user';

/*** Our services ***/
import { LocalStorageService } from '../services/local-storage.service';
import { UserService } from '../services/user.service';

@Injectable()
export class TeamGuard implements CanActivate, CanActivateChild {

  user: User;

  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private localStorageService: LocalStorageService,
      private userService: UserService
  ) { 
    this.user = this.localStorageService.getUser();

    this.localStorageService.listenForUserUpdate()
    .subscribe(user => {
      this.user = user;
      this.checkAuth();
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkAuth(route, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkAuth(route, state);
  }

  checkAuth(route: ActivatedRouteSnapshot = this.route.snapshot, state: RouterStateSnapshot = this.router.routerState.snapshot): boolean {
    if ((this.user && this.user.team) || (_.includes(['admin', 'subadmin', 'junioradmin', 'single'], this.user?.currentUserRole?.role))) return true;
    else {
      this.userService.performInitialNavigation(this.user);
      return false
    }
  }
}