import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-volume-chart',
  templateUrl: './volume-chart.component.html',
  styleUrls: ['./volume-chart.component.css']
})
export class VolumeChartComponent implements OnInit, OnChanges {

	@Input() key: {display: string, color: string}[] = [];
	@Input() data: {display: string, values: number[]}[] = [];

	maxValue: number = 1;

  constructor() { }

  ngOnInit(): void {
		this.calculateMaxValue();
  }

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['data']) this.calculateMaxValue();
	}

	calculateMaxValue() {
		this.maxValue = 1;
		_.forEach(this.data ?? [], item => {
			let itemTotal = 0;
			_.forEach(item?.values, value => {
				itemTotal += value;
			});
			if (itemTotal > this.maxValue) this.maxValue = itemTotal;
		});
	}

}
