/*** Outside library ***/
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import * as _ from 'lodash';

/*** Our services ***/
import { LocalStorageService } from '../services/local-storage.service';
import { UserService } from '../services/user.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private localStorageService: LocalStorageService,
      private userService: UserService
  ) { 
    this.localStorageService.listenForUserUpdate()
    .subscribe(user => {
      this.checkAuth(this.route.snapshot, this.router.routerState.snapshot);
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkAuth(route, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkAuth(route, state);
  }

  checkAuth(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const user = this.localStorageService.getUser();
    if (user) {
      let shouldProceed = true;
      if (route.hasOwnProperty('data') && route.data.hasOwnProperty('roles')) {
        if (user.currentUserRole) {
          shouldProceed = _.indexOf(route.data['roles'], user.currentUserRole.role) > -1;
        }
        else shouldProceed = false;
      }

      if (shouldProceed) {
        return true;
      } else {
        // this.router.navigate(['login']);
        this.userService.performInitialNavigation(user);
        
        return false;
      }
    } else {
			if (_.includes(state.url, 'aps-athlete')) {
				this.router.navigate(['scouting/aps-login']);
			}
      else this.router.navigate(['login']);
      return false;
    }
  }
}