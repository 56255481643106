import { AthleteMetrics } from './athlete-metrics';
import { Conference, Sport, Teamlevel } from './conference';
import { Game } from './game';
import { LeaguePool } from './leaguepool';
import { Country, State } from './locations';
import { Organization } from './organization';
import { Team } from './team'
import { ThirdParty } from './third_parties';

export class User {
  _id: string;
  username: string;
  firstname: string;
  lastname: string;
  phonenumber?: string;
  twitter?: string;
  password?: string;
  lastLogin?: Date;
  userRoles: UserRole[];
  currentUserRole: UserRole;
	qcAnalyst?: boolean; //for statisticians
	ballerTvAnalyst?: boolean; //for statisticians
	taskRequestDate?: Date; //for statisticians
  isShowNotification?: boolean;
  oldids?: number[];
  oldshooterid?: number;

  //not stored in db
  team?: Team;
  metrics?: AthleteMetrics;
  userRoleDisplay?: string;
  relevantUserRole?: UserRole;
  title?: string;
  leaguePool?: LeaguePool;
  jerseyNumber?: number;

	//stat dashboard stuff
  rank?: number
	status?: 'Online' | 'Offline' | 'Idle';
	idleTime?: Date;
	breakdownsAssigned?: number;
	breakdownsFinished?: number;
	singleBreakdowns?: number;
	singleAvgTime?: string;
	fullBreakdowns?: number;
	fullAvgTime?: string;
	breakdownAccuracy?: string;
	currentBreakdownGame?: Game; //for statisticians
	thirdParty?: ThirdParty;
}

export interface UserRole {
  role: UserRoleValue;
  teamid?: Team['_id'];
  seasons?: number[];
  title?: string;
  leaguepoolid?: LeaguePool['_id'];
  jerseyNumber?: number;
	subscriptionExpires?: Date;
  singleUser?: boolean;
	thirdpartyid?: ThirdParty['_id'];
	teamname?: string;

	//scouting
	sportid?: Sport;
	positions?: string[];
	assignmentOrder?: {[position: string]: number};
  
  //not in db
  team?: Team;
  leaguePool?: LeaguePool;
	thirdParty?: ThirdParty;
}

export type UserRoleValue = 'admin' | 'subadmin' | 'junioradmin' | 'boardmember' | 'teamadmin' | 'coach' | 'athlete' | 'single' | 'statsapi' | 'statmanager' | 'shooter' | 'fan' | 'demo' | 
														'leagueadmin' | 'recruiter' | 'prospect' | 'recruitingcoach' | 'parent' | 'thirdparty' |  'scoutadmin' | 'scoutmanager' | 'scout';

export interface SavedCard {
  _id?: string;
  userid: User['_id'];
  lastfour: string;
  customerid: string;
  expirationdate: Date;
  cardtype?: string;
  token: string;
  default?: boolean;
  updatedAt?: Date;
  createdAt?: Date;
}

export const coachFootballRoles: string[] = [
  'Head Coach', 
  'Offensive Coordinator', 
  'Defensive Coordinator',
  'Athletic Director',
  'Special Teams Coordinator', 
	'Recruiting Coordinator',
  'Quarterbacks Coach',
  'Running Backs Coach',
  'Wide Receivers Coach',
  'Tight Ends Coach',
  'Offensive Line Coach',
  'Defensive Line Coach',
  'Linebackers Coach',
  'Defensive Backs Coach'
];

export interface ClipView {
  userid: User['_id'];
  teamid?: Team['_id'];
  gameid: Game['_id'];
  clipnumber: number;
  start: Date;
  end?: Date;
}

export interface WatchTimeData {
  [userid: string]: {
    timewatched: number, 
    timeWatchedGame: number,
    timeWatchedScout: number,
    timeWatchedPractice: number,
    avgTimeToPlay: number,
    clips: number,
    clipsGame: number,
    clipsScout: number,
    clipsPractice: number,
  }
} 

export interface ClipViewData {
  gameid: string;
  clipnumber: number;
  timewatched: number;
  game?: Game;
  gameType?: string;
  matchup?: string;
}

export interface Firebase {
  _id?: string;
  userid: User['_id'];
  token?: string;
  updatedAt?: Date;
}

export interface AccountRequest {
  _id?: string;
  email: string;
  firstname: string;
  lastname: string;
  userrole?: UserRoleValue;
  orgtype?: string;
  sportid: Sport;
  teamname?: string;
  division?: string;
  requestdate: Date;
  approved?: boolean;
  denied?: boolean;
  countryid?: Country['_id'];
  stateid?: State['_id'];
  gender?: string;
  invitecode?: string;
  extrainfo?: string;
  phonenumber?: string;

  //not in db
  state?: State;
  country?: Country;
}

export interface UserNotificationSettings {
  _id?: string;
  userid: User['_id'];
  gameUploads: UserNotificationOption;
  playlistShared: UserNotificationOption;
  playbookShared: UserNotificationOption;
  breakdowns: UserNotificationOption;
}

export class UserNotificationOption {
  email: boolean = true;
  push: boolean = true;
}

export type NotificiationType = 'gameUploads' | 'playlistShared' | 'playbookShared' | 'breakdowns';
export const notificationTypes: NotificiationType[] = ['gameUploads', 'playlistShared', 'breakdowns', 'playbookShared'];

export interface UserProfile {
	_id?: string;
	userid: User['_id'];
	profilePicExists?: boolean;
	description?: string;
}

export const getProfilePicSrc = (userid: string) => {
	const dir = (window.location.href.includes('qwik-q-dev') || window.location.href.includes('localhost')) ? "dev_profile_pictures" : "profile_pictures";
	return `https://d2htis0rx2m2xo.cloudfront.net/${dir}/${userid}`;
}

export interface EmailInfo {
	_id?: string;
	email: string;
	bounces: number;
	complaints: number;
}