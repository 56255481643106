<div
  class="flex-column"
  id="messenger-container"
  style="position: relative"
>
  <ng-container *ngIf="!currentConvo">
    <div class="flex-row center-container conversation-header">
      <mat-label style="flex: 1; margin: 0 10px; font-size: larger"
        >QwikCut Messenger</mat-label
      >
      <button
        mat-icon-button
        (click)="createConversations($event)"
        [disabled]="loadingConvos"
        matTooltip="Create New Conversation"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <div class="flex-fill" style="width: 100%">
      <div
        class="fill-container flex-column"
        *ngIf="!loadingConvos"
        style="overflow-x: hidden"
      >
        <mat-list
          *ngIf="conversations && conversations.length > 0"
          style="width: 100%; padding-top: 0"
        >
          <mat-list-item
            *ngFor="let convo of conversations"
            class="qr-list-item"
            (click)="openConversation(convo, $event)"
          >
            <mat-icon mat-list-icon>{{ convo.members?.length > 2 ? 'group' : 'person' }}</mat-icon>
            <div mat-line style="font-weight: bold">
              {{ convo.name || '' }}
            </div>
            <div mat-line>
              {{ convo?.lastMessage?.message }}
            </div>
            <div
              style="
                font-weight: lighter;
                min-width: 30%;
                text-align: right;
                font-size: smaller;
              "
              matSuffix
            >
              {{
                convo?.lastMessage
                  ? formatDate(convo.lastMessage.createdAt)
                  : formatDate(convo.createdAt)
              }}
            </div>
            <div
              *ngIf="!isReadLastMessage(convo)"
              style="
                font-size: 50px;
                color: #15c80f;
                margin-top: 2px;
                margin-left: 8px;
              "
            >
              &bull;
            </div>
          </mat-list-item>
        </mat-list>

				<ng-container *ngIf="!conversations?.length">
					<div class="flex-row center-container" style="margin-top: 10px;">
						<mat-label style="font-size: large; text-align: center;">No conversations started yet!</mat-label>
					</div>
					<div class="flex-row center-container" style="margin-top: 10px;">
						<button mat-stroked-button color="primary" (click)="createConversations($event)">Start Conversation</button>
					</div>
				</ng-container>
      </div>

      <div class="fill-container center-container" *ngIf="loadingConvos">
        <mat-spinner></mat-spinner>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="currentConvo">
		<app-conversation 
			[user]="user" 
			[elevatedPriv]="elevatedPriv" 
			[currentConvo]="currentConvo" 
			(onLeaveConversation)="leaveConversation()" 
			(onEditConversation)="editConversations()"
			(onDeleteConversation)="deleteConversation()"
			(onCloseConversation)="closeConversation()"
			(onToggleNotification)="toggleNotification()"
			(onSendDirectMessage)="sendDirectMessage($event)"
			style="flex: 1"
		></app-conversation>
  </ng-container>
</div>
