import {
  Component,
  OnInit,
  Input,
	Output,
	EventEmitter,
  OnDestroy,
  OnChanges,
	SimpleChanges,
  ViewChild,
  ElementRef
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatMenuTrigger } from '@angular/material/menu';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription, lastValueFrom, map } from 'rxjs';
import { User, UserRoleValue } from 'src/app/models/user';
import {
  Message,
  Conversation,
  Reaction,
  MessageFile,
} from 'src/app/models/chat';
import { ChatService } from '../../../services/chat.service';
import { UserService } from '../../../services/user.service';
import { SocketIOService } from '../../../services/socket-io.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { ApiHelperService } from '../../../services/api-helper.service';
import { DialogService } from 'src/app/services/dialog.service';
import { TeamService } from 'src/app/services/team.service';
import { TeamGroup } from 'src/app/models/team';
import { Emoji, EmojiData } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { QwikboxService } from 'src/app/services/qwikbox.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';

const MAX_FILE_SIZE = 50 *1024 * 1024;
const S3_URL = 'https://qwikbox.s3.amazonaws.com/';

@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.css']
})
export class ConversationComponent implements OnInit, OnDestroy, OnChanges {

	@Input() user: User;
  @Input() currentConvo: Conversation;
	@Input() elevatedPriv: boolean = false;

	@Output() onLeaveConversation = new EventEmitter();
	@Output() onEditConversation = new EventEmitter();
	@Output() onDeleteConversation = new EventEmitter();
	@Output() onCloseConversation = new EventEmitter();
	@Output() onToggleNotification = new EventEmitter();
	@Output() onSendDirectMessage = new EventEmitter<Message>();

	convoMessages: Message[] = [];
  messageText: string;
	currentEditMessage: Message;
  chatMessageSub: Subscription;
  listConversationSub: Subscription;
  userSub: Subscription;
	openChatSub: Subscription;
	chatClosedSub: Subscription;

  readonly messageLimit: number = 100;
  messagesRequested: number = 0;
  loadingConvos: boolean = false;
  loadingMessages: boolean = false;
  loadingMoreMessages: boolean = false;
  sendingMessage: boolean = false;
  isLoadMore: boolean = false;
  isSelectEmoji: boolean = false;
  selectedMessage: string = '';
  isShowReactionIcon: string = '';
  selectionStart: number = 0;
  teamId: string;
  isActiveNotification: boolean = false;
  emojiHasShow: number = 3;
  isNotificationEnabled: boolean = true;

  users: User[] = [];
  teamGroups: TeamGroup[] = [];
  listUsersName = {};
  listEmojiReact = {};

  currentUpload: FileUpload;
  loading: boolean = false;
	loadingReactions: boolean = false;
  file: MessageFile = null;

  @ViewChild('fileInput') fileInput!: ElementRef;

  @ViewChild('convoMenuTrigger') convoMenuTrigger: MatMenuTrigger;

  constructor(
    private userService: UserService,
    private teamService: TeamService,
    private chatService: ChatService,
    private socketIO: SocketIOService,
    private localStorageService: LocalStorageService,
    private apiHelper: ApiHelperService,
    private dialogService: DialogService,
    private qwikboxService: QwikboxService,
    private http: HttpClient
	) { }

  ngOnInit(): void {
		// this.openConversation(); //handled by ngOnChanges
  }

	ngOnDestroy(): void {
    this.socketIO.leaveRoom(this.currentConvo._id, this.user._id);
		if (this.chatMessageSub) this.chatMessageSub.unsubscribe();
	}
	
	ngOnChanges(changes: SimpleChanges): void {
		if (changes && changes['currentConvo'] && !_.isEqual(changes['currentConvo'].previousValue?._id, changes['currentConvo'].currentValue?._id)) this.openConversation();
	}

  openConversation() {
    if (!this.currentConvo) return;
    this.messageText = null;
    this.file = null;
    this.currentUpload = null;
    this.loadingMessages = true;
    this.isNotificationEnabled = !_.includes(this.currentConvo?.disabledNotifications, this.user._id);
    if (!this.isReadLastMessage()) {
      this.chatService.updateStatusMessage(this.currentConvo._id);
    }

    Promise.all([
      this.userService.getListUsersName(this.currentConvo.members),
      this.chatService.getMessages(this.currentConvo._id, this.messagesRequested),
    ])
      .then(([listUsers, messagesRes]) => {
        this.listUsersName = listUsers.result;
        this.convoMessages = _.orderBy(
          messagesRes.results || [],
          'createdAt',
          'asc'
        );

        this.messagesRequested = this.convoMessages.length;
        if (this.messageLimit === this.convoMessages.length) {
          this.isLoadMore = true;
        }
        this.chatMessageSubHandle();

				_.forEach(this.convoMessages, message => {
					this.assignMessageFileType(message);
				});
      })
      .catch((err) => this.apiHelper.showErrorResponse(err))
      .finally(() => (this.loadingMessages = false));
  }

  isReadLastMessage() {
    if (!this.currentConvo?.lastMessage) return true;
    return _.some(this.currentConvo?.lastMessage?.readBy, (readBy) => {
      return readBy.userId === this.user._id;
    });
  }

	leaveConversation(event: Event) {
    event.stopPropagation();
		this.onLeaveConversation.emit();
	}

	deleteConversation(event: Event) {
    event.stopPropagation();
		this.onDeleteConversation.emit();
	}

	editConversation(event: Event) {
    event.stopPropagation();
		this.onEditConversation.emit();
	}

	closeConversation() {
    event.stopPropagation();
		this.onCloseConversation.emit();
	}

	toggleNotification(event: Event) {
    event.stopPropagation();
		this.onToggleNotification.emit();
	}

	sendDirectMessage(event: Event, message: Message) {
    event.stopPropagation();
		this.onSendDirectMessage.emit(message);
	}

  loadMoreMessages() {
    if (!this.currentConvo._id || !this.convoMessages?.length) return;
    this.loadingMoreMessages = true;
    this.chatService
      .getMessages(this.currentConvo._id, this.messagesRequested)
      .then((res) => {
        const messages: Message[] = _.orderBy(
          res.results || [],
          'createdAt',
          'asc'
        );
				_.forEach(messages, message => {
					this.assignMessageFileType(message);
				});

        this.convoMessages = [...messages, ...this.convoMessages];
        this.messagesRequested = this.convoMessages.length;
        this.isLoadMore = true;
        if (res.results.length < this.messageLimit) {
          this.isLoadMore = false;
        }
      })
      .catch((err) => this.apiHelper.showErrorResponse(err))
      .finally(() => (this.loadingMoreMessages = false));
  }

	assignMessageFileType(message: Message) {
		if (message.file && !message.file.type) {
			const parts = _.split(message.file.name, '.');
			if (parts.length > 1) {
				const ext = _.toLower(parts[parts.length - 1]);
				if (_.includes(['png', 'jpg', 'jpeg', 'gif', 'bmp', 'tiff'], ext)) message.file.type = 'image';
				else if (_.includes(['mp4', 'avi', 'mov'], ext)) message.file.type = 'video';
				else message.file.type = 'other';
			}
		}
	}

  chatMessageSubHandle() {
    if (!this.currentConvo) return;
    this.socketIO.joinRoom(this.currentConvo._id, this.user._id);
    const roomName = `conversation-${this.currentConvo._id}`;
		if (this.chatMessageSub) this.chatMessageSub.unsubscribe();
    this.chatMessageSub = this.socketIO
      .listenForChatMessage(roomName)
      .subscribe({
        next: (msgData: Message) => {
					this.onMessageReceived(msgData);
        },
        error: (err) => console.log(err),
      });
  }

	onMessageReceived(message: Message) {
		if (!message?._id || !message?.senderId) return;
		this.assignMessageFileType(message);
		const currentMessageIndex = _.findIndex(this.convoMessages, {_id: message._id});
		if (currentMessageIndex > -1) {
			this.convoMessages[currentMessageIndex] = message;
		} 
		else {
			this.convoMessages.push(message);
			this.messagesRequested++;
			setTimeout(() => {
				const messageList = <HTMLDivElement>(
					document.getElementById('message-list')
				);
				if (messageList)
					messageList.scrollTo({ top: 0, behavior: 'smooth' });
			}, 100);
		}
	}

  sendMessage() {
    if (!this.currentConvo?._id || (!this.messageText && !this.currentUpload?.file) || this.sendingMessage || this.currentUpload?.isUploading) return;

    this.sendingMessage = true;
		if (this.currentEditMessage?._id) {
			this.chatService.editMessageText(this.currentEditMessage._id, this.messageText || '')
			.catch(err => console.log(err))
			.finally(() => this.afterMessageSent());
		}
		else  {
			this.chatService.createMessage(this.messageText || '', this.currentConvo._id, this.file)
			.then(res => {
				if (res.success && res.result) {
					const newMessage = res.result;
					this.onMessageReceived(newMessage);
				}
			})
			.catch(err => console.log(err))
			.finally(() => this.afterMessageSent());
		}
  }

	afterMessageSent() {
		this.sendingMessage = false;
		this.messageText = null;
		this.file = null;
		this.currentUpload = null;
		this.currentEditMessage = null;
		this.messagesRequested++;
		setTimeout(() => {
			const messageInput = <HTMLInputElement>(
				document.getElementById('message-input')
			);
			messageInput?.focus();
		}, 100);
	}

	deleteMessage(event: Event, message: Message) {
		event.stopPropagation();
		if (!this.currentConvo?._id || !message?._id || message.senderId !== this.user?._id) return;
		this.dialogService.openConfirmationDialog('Are you sure you want to delete this message?')
		.then(confirmed => {
			if (confirmed) {
				this.chatService.deleteMessage(message._id)
				.then(res => {
					if (res.success) {
						message.message = '(Deleted)';
					}
				})
      	.catch((err) => this.apiHelper.showErrorResponse(err))
			}
		})
	}

	editMessage(event: Event, message: Message) {
		event.stopPropagation();
		if (!this.currentConvo?._id || !message?._id || message.senderId !== this.user?._id) return;
		this.currentEditMessage = message;
		this.messageText = (message.message || '') + '';
		const messageInput = <HTMLInputElement>(
			document.getElementById('message-input')
		);
		messageInput?.focus();
	}

  clickEmoji(event: Event) {
    if (!this.selectedMessage) {
      event.stopPropagation();
    }
  }

  pickEmoji(event: Event) {
    event.stopPropagation();
    this.isSelectEmoji = !this.isSelectEmoji;
  }

  addEmoji(emoji: Emoji) {
    const emojiData = emoji.emoji as EmojiData;
    if (this.selectedMessage !== '') {
      this.addReactionEmoji(emojiData.native);
      this.isSelectEmoji = false;
    } else {
      const messageInput = <HTMLInputElement>(
        document.getElementById('message-input')
      );
      const currentMess = this.messageText ? this.messageText : '';
      const modifiedMessage =
        currentMess.slice(0, this.selectionStart) +
        emojiData.native +
        currentMess.slice(this.selectionStart);
      this.messageText = modifiedMessage;
      messageInput.focus();
    }
  }

  closeEmoji(event: Event) {
    event.stopPropagation();
    this.isSelectEmoji = false;
    this.selectedMessage = '';
  }

  showReactionIcon(messageId: string) {
    this.isShowReactionIcon = messageId;
  }

  hideReactionIcon() {
    this.isShowReactionIcon = '';
  }

  showReactionPopup(event: Event, messageId: string) {
    event.stopPropagation();
    this.selectedMessage = messageId;
  }

  addReactionEmoji(emoji: string) {
    const currentMessageIndex = this.convoMessages.findIndex((message) => {
      return this.selectedMessage === message._id;
    });
    const currentReaction = this.convoMessages[currentMessageIndex]?.reaction;
    const reaction = { ...currentReaction, ...{ [this.user._id]: emoji } };
    this.convoMessages[currentMessageIndex].reaction = reaction;
    this.chatService.addReactionEmoji(this.selectedMessage, reaction);
    this.selectedMessage = '';
  }

  async showDialogListReaction(reaction: Reaction, messageId: string) {
		if (this.loadingReactions) return;
		this.loadingReactions = true;
		let listUsersName: {[userid: string]: string} = this.listUsersName || {};
		if (this.currentConvo?.gameid) {
			const userids = _.uniq(_.compact(_.keys(reaction)));
			const res = await this.userService.getUsersByIds(userids);
			if (res.success && res.results?.length) {
				listUsersName = {};
				_.forEach(res.results, user => {
					listUsersName[user._id] = `${user.firstname} ${user.lastname}`
				});
			}
		}

    this.dialogService.openPreviewReactionDialog(
      reaction,
      messageId,
      listUsersName
    );
		this.loadingReactions = false;
  }

  setSelectionStart(event: any) {
    this.selectionStart = event.target?.selectionStart;
  }

  openFileSelector() {
    // Trigger the file input element
    this.fileInput.nativeElement.value = '';
    this.fileInput.nativeElement.click();
  }

  onFileSelected(event: any) {
    if (!event.target.files?.length) return;
    const file = event.target.files[0];
    if(file?.size > MAX_FILE_SIZE) {
      this.apiHelper.showErrorResponse('Maximum file size is 50MB');
      return;
    }
    this.currentUpload = new FileUpload(file);
    this.currentUpload.isUploading = true;

		const expiration = 43200; //12 hours
		const fileExtension = _.split(this.currentUpload.file.name, '.').pop();
    this.chatService.uploadMessageFile(this.currentUpload?.file?.name || '', expiration, fileExtension)
		.then((res) => {
			if (res.success && res.result) {
				this.uploadFile(res.result.file, res.result.uploadURL)
					.then((file) => {
						this.file = {
							name: this.currentUpload?.file?.name || '',
							url: res.result.url
						};
					})
					.catch((err) => {
						this.apiHelper.showErrorResponse(err);
						this.loading = false;
					});
			}
		})
		.catch((err) => {
			this.apiHelper.showErrorResponse(err);
			this.loading = false;
		});
  }

  cancelUpload() {
    this.currentUpload.progress = 0;
    this.currentUpload.numRetries = 0;
    this.currentUpload.isUploading = false;
    if (this.currentUpload.request) {
      this.currentUpload.request.unsubscribe();
      this.currentUpload.request = undefined;
    }
    this.loading = false;
    this.currentUpload.progress = 0;
    this.currentUpload.uploadFailure = false;
    this.currentUpload.uploadSuccess = false;
  }

  uploadFile(file: MessageFile, uploadURL: string): Promise<MessageFile> {
    return new Promise((resolve, reject) => {
      this.currentUpload.isUploading = true;
      this.currentUpload.progress = 0;
      this.currentUpload.uploadFailure = false;
      this.currentUpload.uploadSuccess = false;

      const contentType = this.currentUpload.file.type;

			this.currentUpload.request = this.teamService
				.uploadFiletoS3(uploadURL, contentType, this.currentUpload.file)
				.subscribe(
					(event: HttpEvent<any>) => {
						if (event.type === HttpEventType.UploadProgress) {
							if (event.total) {
								this.currentUpload.progress =
									(event.loaded / event.total) * 100;
							}
						} else if (event.type === HttpEventType.Response) {
							this.currentUpload.progress = 100;
							this.currentUpload.uploadSuccess = true;
							this.currentUpload.uploadFailure = false;
							this.currentUpload.isUploading = false;
							if (this.currentUpload.request) {
								this.currentUpload.request.unsubscribe();
								this.currentUpload.request = undefined;
							}
							resolve(file);
						}
					},
					(err) => {
						reject(err);
					}
				);
    });
  }

  fileUploadFailed(fileUpload: FileUpload) {
    fileUpload.progress = 0;
    fileUpload.isUploading = false;
    fileUpload.uploadSuccess = false;
    fileUpload.numRetries++;
    fileUpload.uploadFailure = fileUpload.numRetries >= 3;
    fileUpload.request = undefined;
  }

	parseAndLinkify(text: string) {
		// Regular expression for various URL formats, including fuzzy links
		const urlRegex = /(?:https?:\/\/)?(?:www\.)?([^\s]+\.[^\s]+)/gi;
	
		// Function to validate and normalize matched URLs
		const validateAndNormalizeUrl = (match: string) => {
			// Prepend protocol if missing
			const baseUrl = _.startsWith(match, "http") ? match : `https://${match}`;
	
			// Optionally trim whitespace
			const trimmedUrl = _.trim(baseUrl);
	
			// Return the normalized URL
			return trimmedUrl;
		};
	
		// Replace URLs with anchor tags
		const linkedText = _.replace(text, urlRegex, (match) => {
			const normalizedUrl = validateAndNormalizeUrl(match);
			if (!normalizedUrl) return match; // Skip invalid URLs
	
			return `<a href="${normalizedUrl}" target="_blank">${match}</a>`;
		});
	
		return linkedText;
	}

	getMessageContents(message: Message) {
		if (!message?.message) return '';
		return this.parseAndLinkify(message.message);
	}

  getFileUrl(path: string) {
    if (!path) return '';
    return `${S3_URL}${path}`;
  }

  formatDate(date: Date) {
    return moment(date).format('MMM D, h:mma');
  }

  getObjectKeys(object: Object) {
    return Object.values(object) || [];
  }
}


class FileUpload {
  file: File;
  src: string;
  progress: number = 0;
  isUploading: boolean = false;
  uploadSuccess: boolean = false;
  uploadFailure: boolean = false;
  numRetries: number = 0;
  request: Subscription | undefined;

  constructor(file: File) {
    this.file = file;
  }
}
