<div *ngIf="metrics" class="hub-container">
    <img [src]="getBannerImage()" onerror="this.src='/assets/images/banner-default.png';" class="banner" />
    <div class="section top-row-section">
        <div class="header-left">
            <img *ngIf="metrics?.profile?.picture; else noLogo" class="portrait-circle" [src]="getLogo()" onerror="this.src='/assets/images/portrait-placeholder.png';" />
            <ng-template #noLogo>
                <div class="portrait-text">
                    {{athlete.firstname.charAt(0) | uppercase}} {{athlete.lastname.charAt(0) | uppercase}}
                </div>
            </ng-template>                        
            <label class="header-left-upload mat-focus-indicator mat-flat-button mat-button-base">
                <input type="file" class="hidden" accept="image/*" (change)="onBannerChanage($event, 1)" >
                <span class="mat-button-wrapper" *ngIf="!uploadingLogo"><mat-icon>upload_file</mat-icon> Upload</span>
                <span class="mat-button-wrapper uploading" *ngIf="uploadingLogo"><mat-spinner [diameter]="20" class="mr5"></mat-spinner> {{uploadProgress}}%</span>
            </label>
        </div>
        <div class='header-right'>
            <div class="header-right-line-one">
                <div class="hub-header-text">{{getNameForTitle()}}</div>                
                <label class="header-left-upload mat-focus-indicator mat-flat-button mat-button-base line-one-button">
                    <input type="file" class="hidden" accept="image/*" (change)="onBannerChanage($event, 0)" >
                    <span class="mat-button-wrapper" *ngIf="!uploadingBanner"><mat-icon>upload_file</mat-icon> Upload Banner</span>
                    <span class="mat-button-wrapper uploading" *ngIf="uploadingBanner"><mat-spinner [diameter]="20" class="mr5"></mat-spinner> {{uploadProgress}}%</span>
                </label>    
            </div>
            <div class="header-right-line-two">
                <mat-form-field class="full-width" appearance="fill" *ngIf="metrics?.profile">
                    <mat-label>Quote:</mat-label>                    
                    <input matInput [(ngModel)]="metrics.profile.quote" placeholder='“I don’t count my situps. I only start counting once it starts hurting. ” -Muhammad Ali' >
                  </mat-form-field>                
            </div>
        </div>
    </div>
    <div class="section">
        <div class="section-content">            
            <span class="mat-caption">Recommended Images Sizes:</span>
            <ul class="size-ul">
                <li>Banner Image: 200px high by 1600px wide</li>
                <li>Team Logo: 512px high by 512px wide</li>
            </ul>
        </div>        
    </div>
    <div class="content-section">
        <hr>
        <div class="section-content">            
            <div>
                Your profile URL: <br>
                <a class="mat-body-strong" target="_blank" [routerLink]="getRouterLink()">{{getDisplayUrl()}}</a>
            </div>
        </div>        
    </div>
</div>
<div *ngIf="loadingMetrics" class="loading">
    <mat-spinner></mat-spinner>
</div>