
import { isDevMode, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { TeamGuard } from './guards/team.guard';
import { environment } from 'src/environments/environment';
import { UserRoleValue } from './models/user';

const devOnlyAuthGuardArray = environment.hubRequiresAuth ? [AuthGuard] : [];
const allRoles: UserRoleValue[] = ['admin', 'subadmin', 'junioradmin', 'boardmember', 'teamadmin', 'athlete', 'coach', 'shooter', 'statsapi', 'statmanager', 'single', 'fan', 'demo', 'leagueadmin', 'parent', 'thirdparty', 'prospect', 'recruiter', 'recruitingcoach', 'scoutadmin', 'scoutmanager', 'scout'];
const authRoles: UserRoleValue[] = ['admin', 'subadmin', 'junioradmin', 'boardmember', 'teamadmin', 'athlete', 'coach', 'shooter', 'statsapi', 'statmanager', 'single', 'demo', 'parent', 'thirdparty'];
const teamRoles: UserRoleValue[] = ['admin', 'subadmin', 'junioradmin', 'boardmember', 'teamadmin', 'athlete', 'coach', 'single', 'demo', 'parent', 'thirdparty'];
const elevatedPrivRoles: UserRoleValue[] = ['admin', 'subadmin', 'junioradmin', 'boardmember', 'teamadmin', 'coach', 'shooter', 'statsapi', 'statmanager', 'single', 'thirdparty'];
const editVideoRoles: UserRoleValue[] = ['admin', 'subadmin', 'junioradmin', 'boardmember', 'teamadmin', 'coach', 'shooter', 'thirdparty'];
const adminRoles: UserRoleValue[] = ['admin', 'subadmin', 'junioradmin'];
const teamLeaderRoles: UserRoleValue[] = ['admin', 'subadmin', 'junioradmin', 'boardmember', 'teamadmin', 'thirdparty'];
const shooterRoles: UserRoleValue[] = ['admin', 'shooter'];

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule) 
	},
  {
    path: 'password/:token',
    loadChildren: () => import('./components/reset-password/reset-password.module').then(m => m.ResetPasswordModule) 
	},
	...['my-video', 'my-video/:gameid', 'my-video/:gameid/:role', 'my-video/:gameid/:role/:trainingType/:trainingSubType', 'my-video/:gameid/:role/:trainingType/:trainingSubType/:userid'].map(path => ({
		path, 
    loadChildren: () => import('./components/my-video-clips/my-video-clips.module').then(m => m.MyVideoClipsModule),
    canActivate: [AuthGuard], data: { roles: [...authRoles, 'leagueadmin'] } 
	})),
  {
    path: 'my-team',
    loadChildren: () => import('./components/my-team/my-team.module').then(m => m.MyTeamModule),
    canActivate: [AuthGuard, TeamGuard], data: { roles: authRoles }
  },
  {
    path: 'my-video-continuous',
    loadChildren: () => import('./components/my-video-continuous/my-video-continuous.module').then(m => m.MyVideoContinuousModule),
    canActivate: [AuthGuard], data: { roles: authRoles }
  },
  {
    path: 'my-fans',
    loadChildren: () => import('./components/my-fans/my-fans.module').then(m => m.MyFansModule),
    canActivate: [AuthGuard], data: { roles: teamLeaderRoles }
  },
  {
    path: 'hub',
    loadChildren: () => import('./components/hub/hub.module').then(m => m.HubModule),
    canActivate: devOnlyAuthGuardArray, data: {roles: [...authRoles, 'leagueadmin'] }
  },
  {
    path: 'highlights',
    loadChildren: () => import('./components/highlights/highlights.module').then(m => m.HighlightsModule),
  },
  {
    path: 'upload-video',
    loadChildren: () => import('./components/upload-video/upload-video.module').then(m => m.UploadVideoModule),
    canActivate: [AuthGuard], data: { roles: [...elevatedPrivRoles, 'parent'] }
  },
  {
    path: 'upload-video/:teamid',
    loadChildren: () => import('./components/upload-video/upload-video.module').then(m => m.UploadVideoModule),
    canActivate: [AuthGuard], data: { roles: [...adminRoles, 'thirdparty'] }
  },
  {
    path: 'admin-dashboard',
    loadChildren: () => import('./components/admin-dashboard/admin-dashboard.module').then(m => m.AdminDashboardModule),
    canActivate: [AuthGuard], data: { roles: adminRoles }
  },
  {
    path: 'downloadlinks/:token/:downloadid',
    loadChildren: () => import('./components/download-links/download-links.module').then(m => m.DownloadLinksModule)
  },
  {
    path: 'playbook',
    loadChildren: () => import('./components/playbook/playbook.module').then(m => m.PlaybookModule),
    canActivate: [AuthGuard], data: { roles: teamRoles }
  },
  {
    path: 'edit-video/:gameid',
    loadChildren: () => import('./components/edit-video/edit-video.module').then(m => m.EditVideoModule),
    canActivate: [AuthGuard], data: { roles: elevatedPrivRoles }
  },
  {
    path: 'user-payments',
    loadChildren: () => import('./components/user-payments/user-payments.module').then(m => m.UserPaymentsModule),
    canActivate: [AuthGuard], data: { roles: allRoles }
  },
  {
    path: 'user-account',
    loadChildren: () => import('./components/user-account/user-account.module').then(m => m.UserAccountModule),
    canActivate: [AuthGuard], data: { roles: allRoles }
  },
  {
    path: 'manage-storage',
    loadChildren: () => import('./components/manage-storage/manage-storage.module').then(m => m.ManageStorageModule),
    canActivate: [AuthGuard, TeamGuard], data: { roles: elevatedPrivRoles }
  },
  {
    path: 'game-exchanges',
    loadChildren: () => import('./components/game-exchanges/game-exchanges.module').then(m => m.GameExchangesModule),
    canActivate: [AuthGuard, TeamGuard], data: { roles: elevatedPrivRoles }
  },
  {
    path: 'manage-video',
    loadChildren: () => import('./components/manage-video/manage-video.module').then(m => m.ManageVideoModule),
    canActivate: [AuthGuard], data: { roles: elevatedPrivRoles }
  },
  {
    path: 'camop-assignments',
    loadChildren: () => import('./components/camop-assignments/camop-assignments.module').then(m => m.CamopAssignmentsModule),
    canActivate: [AuthGuard], data: { roles: ['shooter'] }
  },
  {
    path: 'camop-view-payroll',
    loadChildren: () => import('./components/camop-view-payroll/camop-view-payroll.module').then(m => m.CamopViewPayrollModule),
    canActivate: [AuthGuard], data: { roles: ['admin','shooter'] }
  },
  {
    path: 'stat-dashboard',
    loadChildren: () => import('./components/admin-dashboard/stat-center/stat-center.module').then(m => m.StatCenterModule),
    canActivate: [AuthGuard], data: { roles: ['admin', 'subadmin', 'statmanager'] }
  },
  {
    path: 'film-review-dashboard',
    loadChildren: () => import('./components/admin-dashboard/film-review-dashboard/film-review-dashboard.module').then(m => m.FilmReviewDashboardModule),
    canActivate: [AuthGuard], data: { roles: ['admin', 'subadmin', 'statmanager'] }
  },
  {
    path: 'account-request',
    loadChildren: () => import('./components/account-request/account-request.module').then(m => m.AccountRequestModule)
  },
  {
    path: 'account-request-team',
    loadChildren: () => import('./components/account-request-team/account-request-team.module').then(m => m.AccountRequestTeamModule)
  },
  {
    path: 'leaderboards/conference/:conferenceid',
    loadChildren: () => import('./components/leaderboards/leaderboards.module').then(m => m.LeaderboardsModule)
  },
  {
    path: 'leaderboards/tournament/:tournamentid',
    loadChildren: () => import('./components/leaderboards/leaderboards.module').then(m => m.LeaderboardsModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuard], data: { roles: teamRoles }
  },
  {
    path: 'game-capture/:gameid',
    loadChildren: () => import('./components/game-capture/game-capture.module').then(m => m.GameCaptureModule),
    canActivate: [AuthGuard], data: { roles: editVideoRoles }
  },
  {
    path: 'streaming/:gameid',
    loadChildren: () => import('./components/streaming/streaming.module').then(m => m.StreamingModule),
    canActivate: [AuthGuard], data: { roles: editVideoRoles }
  },
  {
    path: 'film-review/:gameid',
    loadChildren: () => import('./components/film-review/film-review.module').then(m => m.FilmReviewModule),
    canActivate: [AuthGuard], data: { roles: [...adminRoles, 'statsapi', 'statmanager'] }
  },
  {
    path: 'clip-playlist/:gameid',
    loadChildren: () => import('./components/clip-playlist/clip-playlist.module').then(m => m.ClipPlaylistModule),
    canActivate: [AuthGuard], data: { roles: [...adminRoles, 'statsapi', 'statmanager'] }
  },
  {
    path: 'fan',
    loadChildren: () => import('./components/fan/fan.module').then(m => m.FanModule),  
	},
  {
    path: 'fan-signup',
    loadChildren: () => import('./components/fan-signup/fan-signup.module').then(m => m.FanSignupModule)
  },
  {
    path: 'employee-documents',
    loadChildren: () => import('./components/admin-dashboard/manage-employee-documents/manage-employee-documents.module').then(m => m.ManageEmployeeDocumentsModule),
    canActivate: [AuthGuard], data: { roles: ['admin', 'subadmin', 'junioradmin', 'statmanager', 'statsapi', 'shooter'] }
  },
  {
    path: 'products',
    loadChildren: () => import('./components/products/products.module').then(m => m.ProductsModule)
  },
  {
    path: 'prospects/:teamid',
    loadChildren: () => import('./components/prospects/prospects.module').then(m => m.ProspectsModule)
  },
  {
    path: 'team-stats',
    loadChildren: () => import('./components/team-stats/team-stats.module').then(m => m.TeamStatsModule),
    canActivate: [AuthGuard, TeamGuard], data: { roles: teamRoles }
  },
  {
    path: 'resources',
    loadChildren: () => import('./components/resources/resources.module').then(m => m.ResourcesModule),
    canActivate: [AuthGuard, TeamGuard], data: { roles: teamRoles }
  },
  {
		path: 'recruiting-database',
		loadChildren: () => import('./components/recruiting-database/recruiting-database.module').then(m => m.RecruitingDatabaseModule),
    canActivate: [AuthGuard], data: { roles: [...adminRoles, 'recruiter'] }
	},
  {
		path: 'recruiter-signup',
		loadChildren: () => import('./components/recruiter-signup/recruiter-signup.module').then(m => m.RecruiterSignupModule)
	},
  {
		path: 'saved-prospects',
		loadChildren: () => import('./components/saved-prospects/saved-prospects.module').then(m => m.SavedProspectsModule)
	},
  {
		path: 'prospect-info',
		loadChildren: () => import('./components/prospect-info/prospect-info.module').then(m => m.ProspectInfoModule)
	},
  {
		path: 'active-connections',
		loadChildren: () => import('./components/active-connections/active-connections.module').then(m => m.ActiveConnectionsModule),
    canActivate: [AuthGuard], data: { roles: allRoles }
	},
  {
		path: 'qwikcut-camera',
		loadChildren: () => import('./components/qwikcut-camera/qwikcut-camera.module').then(m => m.QwikcutCameraModule),
    canActivate: [AuthGuard, TeamGuard], data: { roles: adminRoles }
	},
	{
		path: 'featured-games',
		loadChildren: () => import('./components/featured-games/featured-games.module').then(m => m.FeaturedGamesModule)
	},
  { 
		path: 'auto-game-highlight/:gameid', 
		loadChildren: () => import('./components/auto-game-highlight/auto-game-highlight.module').then(m => m.AutoGameHighlightModule),
    canActivate: [AuthGuard, TeamGuard], data: { roles: teamRoles } 
	},
  {
    path: 'videos-review',
    loadChildren: () => import('./components/videos-review/videos-review.module').then(m => m.VideosReviewModule), 
    canActivate: [AuthGuard], data: { roles: shooterRoles } 
  },
  { 
		path: 'analyst-dashboard', 
		loadChildren: () => import('./components/analyst-dashboard/analyst-dashboard.module').then(m => m.AnalystDashboardModule) 
	},
  ...['third-party-dashboard', 'third-party-dashboard/:thirdpartyid'].map(path => ({ 
		path, 
		loadChildren: () => import('./components/third-party-dashboard/third-party-dashboard.module').then(m => m.ThirdPartyDashboardModule),
    canActivate: [AuthGuard], data: { roles: ['thirdparty', ...adminRoles] } 
	})),
  {
    path: 'manage-teams',
    loadChildren: () => import('./components/admin-dashboard/manage-teams/manage-teams.module').then(m => m.ManageTeamsModule),
    canActivate: [AuthGuard], data: { roles: ['admin', 'subadmin', 'junioradmin', 'thirdparty'] }
  },
  {
    path: 'manage-users',
    loadChildren: () => import('./components/admin-dashboard/manage-users/manage-users.module').then(m => m.ManageUsersModule),
    canActivate: [AuthGuard], data: { roles: ['admin', 'subadmin', 'junioradmin', 'thirdparty'] }
  },
  {
    path: 'manage-cutups',
    loadChildren: () => import('./components/admin-dashboard/manage-cutups/manage-cutups.module').then(m => m.ManageCutupsModule),
    canActivate: [AuthGuard], data: { roles: ['admin', 'subadmin', 'junioradmin', 'thirdparty'] }
  },
  {
    path: 'manage-thirdparty-tournaments/:thirdpartyid',
    loadChildren: () => import('./components/manage-thirdparty-tournaments/manage-thirdparty-tournaments.module').then(m => m.ManageThirdPartyTournamentsModule),
    canActivate: [AuthGuard], data: { roles: ['admin', 'subadmin', 'junioradmin', 'thirdparty'] }
  },
  {
    path: 'tournament-signup/:tournamentid',
    loadChildren: () => import('./components/thirdparty-tournament-signup/thirdparty-tournament-signup.module').then(m => m.ThirdPartyTournamentSignupModule)
  },
  {
    path: 'scouting',
    loadChildren: () => import('./components/aps-scouting/aps-scouting.module').then(m => m.ApsScoutingModule)
  },
  {
    path: 'qwikbuild-dashboard',
    loadChildren: () => import('./components/admin-dashboard/qwikbuild-dashboard/qwikbuild-dashboard.module').then(m => m.QwikBuildDashboardModule)
  },
  {
    path: 'process-qwikbuild-highlight/:id',
    loadChildren: () => import('./components/process-qwikbuild-highlight/process-qwikbuild-highlight.module').then(m => m.ProcessQwikBuildHighlightModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
