import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import {throwError as observableThrowError, lastValueFrom } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ApiHelperService } from './api-helper.service';
import { GenericAPIArrayResponse, GenericAPIResponse, LoginApiResponse } from '../models/api-response';
import { AutoGameHighlight, AutoGameHighlightClip, AvailableHighlightMusicItem, Highlight, NewHighlight } from '../models/highlight';
import { Sport } from '../models/conference';
import { AssignmentStateValue } from '../models/breakdowns';

@Injectable({
  providedIn: 'root'
})
export class HighlightsService {

  constructor(
    private http: HttpClient,
    private apiHelper: ApiHelperService,
    private router: Router
  ) { }

  queryHighlights(query: any): Promise<GenericAPIArrayResponse<Highlight>> {
    const body = {query};
    const url = `${this.apiHelper.apiUrl()}/highlights`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.put<GenericAPIArrayResponse<Highlight>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  createHighlight(highlight: NewHighlight): Promise<GenericAPIResponse<Highlight>> {
    const url = `${this.apiHelper.apiUrl()}/highlights`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.post<GenericAPIResponse<Highlight>>(url, highlight, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getHighlight(_id: string): Promise<GenericAPIResponse<Highlight>> {
    const url = `${this.apiHelper.apiUrl()}/highlights/${_id}`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.get<GenericAPIResponse<Highlight>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  searchHighlights(searchText: string): Promise<GenericAPIArrayResponse<Highlight>> {
    const url = `${this.apiHelper.apiUrl()}/highlights/search`;
    const headers = this.apiHelper.apiHeaderWithToken()
		const body = {searchText};

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<Highlight>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  updateHighlight(objectid: string, update: any, shouldBuild: boolean): Promise<GenericAPIResponse<Highlight>> {
    var body = {
      update,
      shouldBuild
    }
    const url = `${this.apiHelper.apiUrl()}/highlights/${objectid}`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.post<GenericAPIResponse<Highlight>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  incrementHighlightViews(objectid: string): Promise<GenericAPIResponse<undefined>> {
    const body = {
      id: objectid
    };
    const url = `${this.apiHelper.apiUrl()}/highlights/views`;
    return lastValueFrom(this.http.post<GenericAPIResponse<undefined>>(url, body));
  }

  incrementHighlightReactions(objectid: string, reactionType: 'like' | 'love' | 'fire'): Promise<GenericAPIResponse<undefined>> {
    const body = {
      id: objectid,
      type: reactionType
    };
    const url = `${this.apiHelper.apiUrl()}/highlights/reactions`;
    return lastValueFrom(this.http.post<GenericAPIResponse<undefined>>(url, body));
  }

  deleteHighlight(objectid: string): Promise<GenericAPIResponse<undefined>> {
    const url = `${this.apiHelper.apiUrl()}/highlights/${objectid}`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.delete<GenericAPIResponse<undefined>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  duplicateHighlight(highlightid: string): Promise<GenericAPIResponse<Highlight>> {
    const url = `${this.apiHelper.apiUrl()}/highlights/${highlightid}`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.put<GenericAPIResponse<Highlight>>(url, {}, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getHighlightBeats(): Promise<GenericAPIArrayResponse<{key: string, name: string}[]>> {
    const url = `${this.apiHelper.apiUrl()}/highlights/beats`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.get<GenericAPIArrayResponse<{key: string, name: string}[]>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getDuration(videoURL: string): Promise<GenericAPIResponse<number>> {
    const url = `${this.apiHelper.apiUrl()}/highlights/getduration`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = { url: videoURL };

    return lastValueFrom(this.http.post<GenericAPIResponse<number>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getHighlightMusic(): Promise<GenericAPIArrayResponse<AvailableHighlightMusicItem>> {
    const url = `${this.apiHelper.apiUrl()}/highlights/music/all`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIArrayResponse<AvailableHighlightMusicItem>>(url, {headers}).pipe(
      map( res => res),
      catchError(err => {
        console.debug(err);
        return observableThrowError(err.message);
      }), ));
  }

  getTeamRecentHighlights(teamid: string): Promise<GenericAPIArrayResponse<Highlight>> {
    const url = `${this.apiHelper.apiUrl()}/highlights/team/recent/${teamid}`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.get<GenericAPIArrayResponse<Highlight>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  submitAutoGameHighlight(autoHighlight: AutoGameHighlight, generateHighlight: boolean = false, skipBuild: boolean = false): Promise<GenericAPIResponse<AutoGameHighlight>> {
    const url = `${this.apiHelper.apiUrl()}/highlights/auto-highlight`;
    const headers = this.apiHelper.apiHeaderWithToken()
		const body = {autoHighlight, generateHighlight, skipBuild};

    return lastValueFrom(this.http.post<GenericAPIResponse<AutoGameHighlight>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getAutoGameHighlights(userid: string, teamid: string, gameids: string[], includeGame: boolean = false): Promise<GenericAPIArrayResponse<AutoGameHighlight>> {
    const url = `${this.apiHelper.apiUrl()}/highlights/auto-highlight`;
    const headers = this.apiHelper.apiHeaderWithToken()
		const body = {userid, teamid, gameids, includeGame};

    return lastValueFrom(this.http.put<GenericAPIArrayResponse<AutoGameHighlight>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getAutoGameHighlightById(_id: string): Promise<GenericAPIResponse<AutoGameHighlight>> {
    const url = `${this.apiHelper.apiUrl()}/highlights/auto-highlight/${_id}`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.get<GenericAPIResponse<AutoGameHighlight>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  submitQwikBuildHighlight(autohighlightid: string): Promise<GenericAPIResponse<AutoGameHighlight>> {
    const url = `${this.apiHelper.apiUrl()}/highlights/qwikbuild`;
    const headers = this.apiHelper.apiHeaderWithToken()
		const body = {autohighlightid};

    return lastValueFrom(this.http.post<GenericAPIResponse<AutoGameHighlight>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  updateAutoHighlight(autohighlightid: string, update: any, generateHighlight: boolean = false, skipBuild: boolean = false): Promise<GenericAPIResponse<AutoGameHighlight>> {
    const url = `${this.apiHelper.apiUrl()}/highlights/qwikbuild`;
    const headers = this.apiHelper.apiHeaderWithToken()
		const body = {autohighlightid, update, generateHighlight, skipBuild};

    return lastValueFrom(this.http.put<GenericAPIResponse<AutoGameHighlight>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  queryQwikbuildRequests(rangeStart?: Date, rangeEnd?: Date, assignmentStates?: AssignmentStateValue[], assignmentUserids?: string[]): Promise<GenericAPIArrayResponse<AutoGameHighlight>> {
    const url = `${this.apiHelper.apiUrl()}/highlights/qwikbuild-query`;
    const headers = this.apiHelper.apiHeaderWithToken()
		const body = {rangeStart, rangeEnd, assignmentStates, assignmentUserids};

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<AutoGameHighlight>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  queryQwikbuildRequestsCount(rangeStart?: Date, rangeEnd?: Date, assignmentStates?: AssignmentStateValue[], assignmentUserids?: string[]): Promise<GenericAPIResponse<number>> {
    const url = `${this.apiHelper.apiUrl()}/highlights/qwikbuild-query`;
    const headers = this.apiHelper.apiHeaderWithToken()
		const body = {rangeStart, rangeEnd, assignmentStates, assignmentUserids};

    return lastValueFrom(this.http.put<GenericAPIResponse<number>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  sendQwikBuildPushNotification(userids: string[], teamid: string, gameid: string, customMessage?: string): Promise<GenericAPIResponse<undefined>> {
    const url = `${this.apiHelper.apiUrl()}/highlights/qwikbuild-notification`;
    const headers = this.apiHelper.apiHeaderWithToken()
		const body = {userids, teamid, gameid, customMessage};

    return lastValueFrom(this.http.post<GenericAPIResponse<undefined>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

}
