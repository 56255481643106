import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-vertical-bar-chart',
  templateUrl: './vertical-bar-chart.component.html',
  styleUrls: ['./vertical-bar-chart.component.css']
})
export class VerticalBarChartComponent implements OnInit, OnChanges {

	@Input() data: {display: string, value: number}[] = [];

	totalValue: number = 1;

  constructor() { }

  ngOnInit(): void {
		this.calculateTotalValue();
  }

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['data']) this.calculateTotalValue();
	}

	calculateTotalValue() {
		this.totalValue = 0;
		_.forEach(this.data ?? [], item => {
			this.totalValue += (item?.value ?? 0);
		});
		if (this.totalValue < 1) this.totalValue = 1;
	}

}
