import { Injectable } from '@angular/core';
import * as moment from 'moment';
import {throwError as observableThrowError, lastValueFrom, Subject, Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { HttpClient, HttpParams, } from '@angular/common/http';
import { ApiHelperService } from './api-helper.service';
import { GenericAPIArrayResponse, GenericAPIResponse } from '../models/api-response';
import { ClipInfo, ExchangeVideoPlayData, Game, GameCopy, GameFolder, GameInfo, GameLocation, GameManagerMatchupGroup, GameType, GameUpload, GameView, HudlImportGameInfo, AdminNote, GameCaptureConnection, ImportGameInfo } from '../models/game';
import { Sport } from '../models/conference';
import { Angle, Clip } from '../models/clip';
import { FanView } from '../models/fans';
import { QwikTag } from '../models/qwiktag';
import { GameClip, GameVideo } from '../models/editvideo';
import { StatTotal } from '../models/stats';
import { DefenseType, OffenseType } from '../models/team';
import { AssignmentStatus } from '../models/shooters';
import { ClipPlaylist } from '../models/clip_playlist';
import { Playlist } from '../models/playlist';
import { UploadStatus } from '../models/shooters';

@Injectable({
  providedIn: 'root'
})
export class GameService {

  private refreshGameManager = new Subject<any>();

  constructor(
    private http: HttpClient,
    private apiHelper: ApiHelperService
  ) { }

  listenForGameManagerRefresh(): Observable<any> {
     return this.refreshGameManager.asObservable();
  }

  emitGameManagerRefresh() {
   this.refreshGameManager.next(true);
  }

  getGame(gameid: string): Promise<GenericAPIResponse<Game>> {
    const url = `${this.apiHelper.apiUrl()}/game/${gameid}`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.get<GenericAPIResponse<Game>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getTeamFullSchedule(): Promise<GenericAPIArrayResponse<GameFolder>> {
    const url = `${this.apiHelper.apiUrl()}/games/schedule`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIArrayResponse<GameFolder>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getTeamFullScheduleAdmin(teamid: string): Promise<GenericAPIArrayResponse<GameFolder>> {
    const url = `${this.apiHelper.apiUrl()}/games/team-schedule/${teamid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIArrayResponse<GameFolder>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getTeamSeasonSchedule(seasonid: number): Promise<GenericAPIArrayResponse<GameFolder>> {
    const url = `${this.apiHelper.apiUrl()}/games/schedule/${seasonid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIArrayResponse<GameFolder>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getTeamSeasonScheduleAdmin(teamid: string, seasonid: number): Promise<GenericAPIArrayResponse<GameFolder>> {
    const url = `${this.apiHelper.apiUrl()}/games/team-schedule/${teamid}/${seasonid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIArrayResponse<GameFolder>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  searchTeamSchedule(searchText: string, teamid?: string): Promise<GenericAPIArrayResponse<GameFolder>> {
    const url = `${this.apiHelper.apiUrl()}/games/search-schedule`;
    const headers = this.apiHelper.apiHeaderWithToken();
		const body = {searchText, teamid};

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<GameFolder>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getLeaguePoolData(leaguepoolid: string, seasonid: number, teamlevelid: string, filterTeamid?: string, offenseType?: OffenseType, defenseType?: DefenseType): Promise<GenericAPIArrayResponse<GameFolder>> {
    const url = `${this.apiHelper.apiUrl()}/games/league-pool`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {leaguepoolid, seasonid, teamlevelid, filterTeamid, offenseType, defenseType};

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<GameFolder>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  searchLeaguepool(leaguepoolid: string, seasonid: number, teamlevelid: string, searchText: string, teamid?: string): Promise<GenericAPIArrayResponse<GameFolder>> {
    const url = `${this.apiHelper.apiUrl()}/games/search-league-pool`;
    const headers = this.apiHelper.apiHeaderWithToken();
		const body = {leaguepoolid, seasonid, teamlevelid, searchText, teamid};

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<GameFolder>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  queryGames(query: any): Promise<GenericAPIArrayResponse<Game>> {
    const url = `${this.apiHelper.apiUrl()}/games`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {query};

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<Game>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getGamesByGameids(gameids: string[]): Promise<GenericAPIArrayResponse<Game>> {
    return this.queryGames({_id: {$in: gameids}});
  }

  getGamesByRange(start: Date, end: Date): Promise<GenericAPIArrayResponse<Game>> {
    return this.queryGames({
      gamedate: {
        $gte: start,
        $lte: end
      }
    });
  }

  getGamesFromTeamids(teamids: string[], extraQuery: any = {}): Promise<GenericAPIArrayResponse<Game>> {
    const body = {teamids, extraQuery};
    const url = `${this.apiHelper.apiUrl()}/games/teamids`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<Game>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getTeamSeasonGames(teamid: string, seasonid: number): Promise<GenericAPIArrayResponse<Game>> {
    const body = {teamid, seasonid};
    const url = `${this.apiHelper.apiUrl()}/games/season`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<Game>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getGamesBySeason(teamId: string): Promise<GenericAPIArrayResponse<Game>> {
    const body = { teamId };
    const url = `${this.apiHelper.apiUrl()}/games/season`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(
      this.http.put<GenericAPIArrayResponse<Game>>(url, body, { headers }).pipe(
        map((res) => res),
        catchError((err) => {
          console.log(err);
          return observableThrowError(err.message);
        })
      )
    );
  }

  getPpvGames(start: Date = moment().subtract(6, 'months').toDate(), end: Date = moment().add(6, 'months').toDate()): Promise<GenericAPIArrayResponse<Game>> {
    const query: any = {ppv: true};
    if (start || end) {
      query.gamedate = {};
      if (start) query.gamedate.$gte = start;
      if (end) query.gamedate.$lte = end;
    }
    return this.queryGames(query);
  }

  getPublicGames(start: Date = moment().subtract(6, 'months').toDate(), end: Date = moment().add(6, 'months').toDate()): Promise<GenericAPIArrayResponse<Game>> {
    const query: any = {public: true};
    if (start || end) {
      query.gamedate = {};
      if (start) query.gamedate.$gte = start;
      if (end) query.gamedate.$lte = end;
    }
    return this.queryGames(query);
  }

  createGame(type: GameType, sportid: Sport, gamedate: Date, seasonid: number, name: string, extrainfo: string, location: GameLocation, fieldLength: number = 100, parentfolderid: string = null,
    createEvent: boolean = false, hideFromLeaguePool: boolean = false, hometeamid?: string, awayteamid?: string, hidefromhome: boolean = false, hidefromaway: boolean = false, fieldid?: string, subfield?: string, ppv: boolean = false, publicGame: boolean = false, ppvPrice?: number, teamid?: string): Promise<GenericAPIResponse<Game>> {
    const body = {
      type,
      sportid,
      gamedate,
      seasonid,
      name,
      extrainfo,
      location,
      fieldLength,
      parentfolderid,
      createEvent,
      hideFromLeaguePool,
      hometeamid,
      awayteamid,
      hidefromhome,
      hidefromaway,
      fieldid,
      subfield,
      ppv,
      'public': publicGame,
			ppvPrice,
			teamid
    }
    const url = `${this.apiHelper.apiUrl()}/game`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<Game>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),))
  }

  updateGame(gameid: string, update: any, getFullGameInfo: boolean = false): Promise<GenericAPIResponse<Game>> {
    const url = `${this.apiHelper.apiUrl()}/game/${gameid}`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {update, getFullGameInfo};

    return lastValueFrom(this.http.put<GenericAPIResponse<Game>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  addLeaguePoolAngle(gameid: string, angle: Angle): Promise<GenericAPIResponse<Game>> {
    const url = `${this.apiHelper.apiUrl()}/games/leaguepool-angle/${gameid}`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {angle};

    return lastValueFrom(this.http.post<GenericAPIResponse<Game>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  restoreGame(gameid: string): Promise<GenericAPIResponse<Game>> {
    const url = `${this.apiHelper.apiUrl()}/games/restore/${gameid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<Game>>(url, {}, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  deleteGame(gameid: string): Promise<GenericAPIResponse<undefined>> {
    const url = `${this.apiHelper.apiUrl()}/game/${gameid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.delete<GenericAPIResponse<undefined>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  importGameFromHudl(gameid: string, linkurl: string, teamid: string, sportid: Sport): Promise<GenericAPIResponse<undefined>> {
    const body = {
      gameid,
      linkurl,
      teamid,
      sportid
    };
    const url = `${this.apiHelper.apiUrl()}/games/import-from-hudl`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.post<GenericAPIResponse<undefined>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  importSeasonFromHudl(teamid: string, seasonid: number, sportid: Sport, gameinfo: HudlImportGameInfo[]): Promise<GenericAPIResponse<undefined>> {
    const body = {
      teamid,
			seasonid,
      sportid,
			gameinfo
    };
    const url = `${this.apiHelper.apiUrl()}/games/import-season-from-hudl`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.post<GenericAPIResponse<undefined>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  importMultipleGames(teamid: string, seasonid: number, sportid: Sport, parentfolderid: string, gameinfos: ImportGameInfo[]): Promise<GenericAPIResponse<undefined>> {
    const body = {
      teamid,
			seasonid,
      sportid,
			parentfolderid,
			gameinfos
    };
    const url = `${this.apiHelper.apiUrl()}/games/import-multiple-games`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.post<GenericAPIResponse<undefined>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getGameClips(gameid: string): Promise<GenericAPIArrayResponse<Clip>> {
    const url = `${this.apiHelper.apiUrl()}/games/clips/${gameid}`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.get<GenericAPIArrayResponse<Clip>>(url, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getGameClipsPublic(gameid: string): Promise<GenericAPIArrayResponse<Clip>> {
    const url = `${this.apiHelper.apiUrl()}/games/clips-public/${gameid}`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.get<GenericAPIArrayResponse<Clip>>(url, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getGameClipsByAngle(gameid: string, angle?: Angle): Promise<GenericAPIArrayResponse<Clip>> {
    const url = `${this.apiHelper.apiUrl()}/games/clips/${gameid}/${angle}`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.get<GenericAPIArrayResponse<Clip>>(url, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getGameClipsByAngles(gameid: string, angles: Angle[]): Promise<GenericAPIArrayResponse<Clip>> {
    const url = `${this.apiHelper.apiUrl()}/games/clips/${gameid}`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {angles};

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<Clip>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getGameClipsWithDurations(gameid: string): Promise<GenericAPIArrayResponse<Clip>> {
    const url = `${this.apiHelper.apiUrl()}/games/clips-with-durations/${gameid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIArrayResponse<Clip>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  createClip(gameid: string, clipnumber: number, angle: Angle, tempname?: string, customUrl?: string): Promise<GenericAPIResponse<Clip>> {
    const url = `${this.apiHelper.apiUrl()}/games/clips`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {gameid, clipnumber, angle, tempname, customUrl};

    return lastValueFrom(this.http.post<GenericAPIResponse<Clip>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getClipsByGameids(gameids: string[]): Promise<GenericAPIArrayResponse<Clip>> {
    const body = {
      gameids
    };
    const url = `${this.apiHelper.apiUrl()}/games/clips-by-gameids`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<Clip>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getNumClipsByGameids(gameids: string[]): Promise<GenericAPIResponse<{[gameid: string]: number}>> {
    const body = {
      gameids
    };
    const url = `${this.apiHelper.apiUrl()}/games/num-clips-by-gameids`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<{[gameid: string]: number}>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  deleteClips(gameid: string, clipnumbers: number[], angle?: Angle): Promise<GenericAPIResponse<undefined>> {
    const body = {
      gameid,
      clipnumbers,
      angle
    };
    const url = `${this.apiHelper.apiUrl()}/games/clips`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.put<GenericAPIResponse<undefined>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  deleteGameClips(gameid: string): Promise<GenericAPIResponse<undefined>> {
    const url = `${this.apiHelper.apiUrl()}/games/clips/${gameid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.delete<GenericAPIResponse<undefined>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  deleteGameClipsByAngle(gameid: string, angle: Angle): Promise<GenericAPIResponse<undefined>> {
    const url = `${this.apiHelper.apiUrl()}/games/clips/${gameid}/${angle}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.delete<GenericAPIResponse<undefined>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  incrementGameViews(gameid: string, ipaddress: string, city: string, region: string): Promise<GenericAPIResponse<FanView>> {
    const url = `${this.apiHelper.apiUrl()}/games/fan-views`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {
      gameid,
      ipaddress,
      city,
      region
    };

    return lastValueFrom(this.http.post<GenericAPIResponse<FanView>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getFanViewsByGameids(gameids: string[]): Promise<GenericAPIArrayResponse<FanView>> {
    const body = {gameids};
    const url = `${this.apiHelper.apiUrl()}/games/fan-views`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.put<GenericAPIArrayResponse<FanView>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  invalidateGameCache(gameid: string): Promise<GenericAPIResponse<undefined>> {
    const body = {gameid};
    const url = `${this.apiHelper.apiUrl()}/games/invalidate-cache`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<undefined>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  //DEPRECATED
  finishMergedUpload(gameid: string, clips: number, angle: Angle, teamids?: string[], matchup?: string): Promise<GenericAPIResponse<undefined>> {
    const body = {
      gameid,
      clips,
      angle,
      teamids,
      matchup
    };
    const url = `${this.apiHelper.apiUrl()}/games/finish-merged-upload`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<undefined>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  processMergedUpload(gameid: string, angle: Angle, removeAudio: boolean = false): Promise<GenericAPIResponse<undefined>> {
    const body = {
      gameid,
      angle,
			removeAudio
    };
    const url = `${this.apiHelper.apiUrl()}/games/process-merged-upload`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<undefined>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  processClip(gameid: string, clipnumber: number, angle: Angle, tempname: string, removeAudio: boolean = false, exchangePlayData?: ExchangeVideoPlayData[]): Promise<GenericAPIResponse<Clip>> {
    const body = {
      gameid,
      clipnumber,
      angle,
      tempname,
      removeAudio,
			exchangePlayData
    };
    const url = `${this.apiHelper.apiUrl()}/games/process-clip`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<Clip>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  submitGameUpload(gameid: string, angle: Angle, numFiles: number, startingClipnumber: number): Promise<GenericAPIResponse<GameUpload>> {
    const body = {
      gameid,
      angle,
      numFiles,
      startingClipnumber
    };
    const url = `${this.apiHelper.apiUrl()}/games/uploads`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<GameUpload>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getGameUploads(gameids: string[]): Promise<GenericAPIArrayResponse<GameUpload>> {
    const url = `${this.apiHelper.apiUrl()}/games/uploads`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const params = new HttpParams({
      fromObject: {gameids}
    });

    return lastValueFrom(this.http.get<GenericAPIArrayResponse<GameUpload>>(url, { headers, params }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getRecordedStreamUrl(gameid: string): Promise<GenericAPIResponse<string>> {
    const url = `${this.apiHelper.apiUrl()}/games/stream-url/${gameid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIResponse<string>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  editGameVideo(gameid: string, clips: Array<GameClip>, endzoneClips: Array<GameClip>, tightClips: Array<GameClip>): Promise<GenericAPIResponse<undefined>> {
    const body = {
      gameid,
      clips,
      endzoneClips,
      tightClips
    }
    const url = `${this.apiHelper.apiUrl()}/games/edit-video`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.post<GenericAPIResponse<undefined>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  createGameCopy(teamid: string, gameid: string, seasonid: number, parentfolderid: string = null): Promise<GenericAPIResponse<GameCopy>> {
    const body = {
      teamid,
      gameid,
      seasonid,
      parentfolderid
    }
    const url = `${this.apiHelper.apiUrl()}/games/copy`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<GameCopy>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  createGameCopies(teamid: string, gameids: string[], seasonid: number, parentfolderid: string = null): Promise<GenericAPIArrayResponse<GameCopy>> {
    const body = {
      teamid,
      gameids,
      seasonid,
      parentfolderid
    }
    const url = `${this.apiHelper.apiUrl()}/games/copies`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<GameCopy>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  updateGameCopy(_id: string, update: any): Promise<GenericAPIResponse<GameCopy>> {
    const body = {
      _id,
      update
    }
    const url = `${this.apiHelper.apiUrl()}/games/copy`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.put<GenericAPIResponse<GameCopy>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  updateGameCopies(ids: string[], update: any): Promise<GenericAPIResponse<undefined>> {
    const body = {
      ids,
      update
    }
    const url = `${this.apiHelper.apiUrl()}/games/copies`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.put<GenericAPIResponse<undefined>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getGameCopies(teamid: number, seasonid: number): Promise<GenericAPIArrayResponse<GameCopy>> {
    const body = {
      teamid,
      seasonid
    }
    const url = `${this.apiHelper.apiUrl()}/games/get-copies`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<GameCopy>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  deleteGameCopy(_id: string): Promise<GenericAPIResponse<undefined>> {
    const url = `${this.apiHelper.apiUrl()}/games/copy/${_id}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.delete<GenericAPIResponse<undefined>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  deleteGameCopies(ids: string[]): Promise<GenericAPIResponse<undefined>> {
    const body = {
      ids
    }
    const url = `${this.apiHelper.apiUrl()}/games/delete-copies`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<undefined>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  createGameFolder(teamid: string, seasonid: number, name: string, date?: Date, parentfolderid?: string): Promise<GenericAPIResponse<GameFolder>> {
    const body = {
      teamid,
      seasonid,
      name,
      date,
      parentfolderid
    }
    const url = `${this.apiHelper.apiUrl()}/games/folder`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<GameFolder>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  updateGameFolder(_id: string, update: any): Promise<GenericAPIResponse<GameFolder>> {
    const body = {
      _id,
      update
    }
    const url = `${this.apiHelper.apiUrl()}/games/folder`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.put<GenericAPIResponse<GameFolder>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getGameFolders(teamid: number, seasonid: number): Promise<GenericAPIArrayResponse<GameFolder>> {
    const body = {
      teamid,
      seasonid
    }
    const url = `${this.apiHelper.apiUrl()}/games/folders`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<GameFolder>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  queryGameFolders(query: any): Promise<GenericAPIArrayResponse<GameFolder>> {
    const body = {
      query
    }
    const url = `${this.apiHelper.apiUrl()}/games/folders`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.put<GenericAPIArrayResponse<GameFolder>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  deleteGameFolder(_id: string): Promise<GenericAPIResponse<undefined>> {
    const url = `${this.apiHelper.apiUrl()}/games/folder/${_id}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.delete<GenericAPIResponse<undefined>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  regenerateGameFolders(gameid: string, oldTeamid: string, newTeamid: string): Promise<GenericAPIResponse<undefined>> {
    const body = {
      gameid,
      oldTeamid,
      newTeamid
    }
    const url = `${this.apiHelper.apiUrl()}/games/folders/regenerate`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<undefined>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  updateGames(gameids: string[], update: any): Promise<GenericAPIResponse<undefined>> {
    const body = {
      gameids,
      update
    }
    const url = `${this.apiHelper.apiUrl()}/games`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.put<GenericAPIResponse<undefined>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  updateTeamShares(teamid: string, gameids: string[], update: any): Promise<GenericAPIResponse<undefined>> {
    const body = {
      teamid,
      gameids,
      update
    }
    const url = `${this.apiHelper.apiUrl()}/games/teamshares`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.put<GenericAPIResponse<undefined>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getCanceledGames(teamid: string): Promise<GenericAPIArrayResponse<Game>> {
    const query = {
      canceled: true,
      $or: [{
        teamid,
      }, {
        hometeamid: teamid,
      }, {
        awayteamid: teamid,
      }],
    };
    return this.queryGames(query);
  }

  notifyTeamOfUpload(teamid: string, gameid: string) : Promise<GenericAPIResponse<undefined>> {
    const body = {
      teamid,
      gameid
    }
    const url = `${this.apiHelper.apiUrl()}/games/upload-finished`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.post<GenericAPIResponse<undefined>>(url, body, { headers }).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getQwikTags(gameid: string, clipnumber: number): Promise<GenericAPIArrayResponse<QwikTag>> {
    const body = {
      gameid: gameid,
      clipnumber: clipnumber
    }

    const url = `${this.apiHelper.apiUrl()}/games/qwiktags/get`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<QwikTag>>(url, body, {headers}));
  }

  getAllUserQwikTags(): Promise<GenericAPIArrayResponse<QwikTag>> {
    const body = {}

    const url = `${this.apiHelper.apiUrl()}/games/qwiktags/get-all-user-tags`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<QwikTag>>(url, body, {headers}));
  }

  deleteAllUserQwikTags(): Promise<GenericAPIResponse<undefined>> {
    const url = `${this.apiHelper.apiUrl()}/games/qwiktags/delete-all-user-tags`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.delete<GenericAPIResponse<undefined>>(url, {headers}));
  }

  clearAllQwikTagForUser(): Promise<GenericAPIResponse<undefined>> {
    const url = `${this.apiHelper.apiUrl()}/games/qwiktags/clear`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.delete<GenericAPIResponse<undefined>>(url, {headers}));
  }

  updateQwikTag(tag: QwikTag): Promise<GenericAPIResponse<QwikTag>> {
    const body = {
      qwiktag: tag
    }

    const url = `${this.apiHelper.apiUrl()}/games/qwiktags/update`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.post<GenericAPIResponse<QwikTag>>(url, body, {headers}));
  }

  addQwikTag(tag: QwikTag): Promise<GenericAPIResponse<QwikTag>> {
    const body = {
      qwiktag: tag
    }

    const url = `${this.apiHelper.apiUrl()}/games/qwiktags/new`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.post<GenericAPIResponse<QwikTag>>(url, body, {headers}));
  }

  deleteQwikTag(id: string): Promise<GenericAPIResponse<QwikTag>> {

    const url = `${this.apiHelper.apiUrl()}/games/qwiktags/delete/${id}`;
    const headers = this.apiHelper.apiHeaderWithToken()

    return lastValueFrom(this.http.delete<GenericAPIResponse<QwikTag>>(url, {headers}));
  }

  resetDownload(gameid: string): Promise<any> {
    const body = {
      gameid
    }

    const url = `${this.apiHelper.qwikcutEncoderUrlReset()}`;
    const headers = this.apiHelper.apiHeaderWithToken(this.getMergingServerToken());

    return lastValueFrom(this.http.post(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getMergingServerToken() {
    return 'ebd7a876-c8ad-11e6-9d9d-cec0c932ce01';
  }

  submitGameInfo(gameid: string, info: Partial<GameInfo>, employeeOverride: boolean = false): Promise<GenericAPIResponse<GameInfo>> {
    const body = {
			...info,
      employeeOverride
    }
    const url = `${this.apiHelper.apiUrl()}/games/info/${gameid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIResponse<GameInfo>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  shareMultipleGames(gameids: string[], shareUserids: string[], shareGroupids: string[]): Promise<GenericAPIArrayResponse<GameInfo>> {
    const body = {
			gameids,
			shareUserids,
			shareGroupids
    }
    const url = `${this.apiHelper.apiUrl()}/games/share`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<GameInfo>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getGameInfos(teamid: string, gameids: string[]): Promise<GenericAPIArrayResponse<GameInfo>> {
    const url = `${this.apiHelper.apiUrl()}/games/info`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {teamid, gameids};

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<GameInfo>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getGameInfo(gameid: string): Promise<GenericAPIResponse<GameInfo>> {
    const url = `${this.apiHelper.apiUrl()}/games/info/${gameid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIResponse<GameInfo>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getGameAngles(gameid: string): Promise<GenericAPIArrayResponse<Angle>> {
    const url = `${this.apiHelper.apiUrl()}/games/angles/${gameid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIArrayResponse<Angle>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  compileGameStats(teamid: string, gameids: string[], combinePlayers: boolean = false): Promise<GenericAPIArrayResponse<StatTotal & any>> {
    const url = `${this.apiHelper.apiUrl()}/games/compile-stats`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {teamid, gameids, combinePlayers};

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<StatTotal & any>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  saveVideoEdit(gameid: string, wideClips: GameClip[], endzoneClips: GameClip[], tightClips: GameClip[]): Promise<GenericAPIResponse<GameVideo>> {
    const url = `${this.apiHelper.apiUrl()}/videoedits`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {
      gameid,
      wideClips,
      endzoneClips,
      tightClips
    };

    return lastValueFrom(this.http.put<GenericAPIResponse<GameVideo>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  applyVideoEdit(gameid: string, wideClips: GameClip[], endzoneClips: GameClip[], tightClips: GameClip[]): Promise<GenericAPIResponse<GameVideo>> {
    const url = `${this.apiHelper.apiUrl()}/videoedits`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {
      gameid,
      wideClips,
      endzoneClips,
      tightClips
    };

    return lastValueFrom(this.http.post<GenericAPIResponse<GameVideo>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getVideoEdit(gameid: string): Promise<GenericAPIResponse<GameVideo>> {
    const url = `${this.apiHelper.apiUrl()}/videoedits/${gameid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIResponse<GameVideo>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  deleteVideoEdit(gameid: string): Promise<GenericAPIResponse<undefined>> {
    const url = `${this.apiHelper.apiUrl()}/videoedits/${gameid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.delete<GenericAPIResponse<undefined>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getVideoEditorsString(gameid: string): Promise<GenericAPIResponse<string>> {
    const url = `${this.apiHelper.apiUrl()}/videoedits/editors/${gameid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIResponse<string>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  submitGameView(gameid: string): Promise<GenericAPIArrayResponse<GameView>> {
    const url = `${this.apiHelper.apiUrl()}/games/views`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {gameid};

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<GameView>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getGameViews(gameid: string): Promise<GenericAPIArrayResponse<GameView>> {
    const url = `${this.apiHelper.apiUrl()}/games/views/${gameid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIArrayResponse<GameView>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  scheduleGames(sportid: Sport, seasonid: number, fieldid: string, subfield: string, gameInfos: {gamedate: Date, hometeamid: string, awayteamid: string, ppv: boolean, public: boolean, hideFromLeaguePool: boolean, hidefromhome: boolean, hidefromaway: boolean}[],
      camopAssignments: {userid: string, position: number}[] = []): Promise<GenericAPIArrayResponse<Game>> {
    const url = `${this.apiHelper.apiUrl()}/games/schedule-games`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {
      sportid,
      seasonid,
      fieldid,
      subfield,
      gameInfos,
      camopAssignments
    };

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<Game>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getAdminNotesForGame(gameId: string): Promise<GenericAPIArrayResponse<AdminNote>> {
    const url = `${this.apiHelper.apiUrl()}/game/note/${gameId}`;
    const headers = this.apiHelper.apiHeaderWithToken();
    return lastValueFrom(
      this.http.get<GenericAPIArrayResponse<AdminNote>>(url, { headers }).pipe(
        map((res) => res),
        catchError((err) => {
          console.log(err);
          return observableThrowError(err.message);
        })
      )
    );
  }

  saveAdminNoteForGame({
    gameId,
    note
  }: {
    gameId: string;
    note: string;
  }): Promise<GenericAPIResponse<Game>> {
    const url = `${this.apiHelper.apiUrl()}/game/note/${gameId}`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const timestamps = new Date();
    const body = { note, timestamps };
    return lastValueFrom(
      this.http.post<GenericAPIResponse<Game>>(url, body, { headers }).pipe(
        map((res) => res),
        catchError((err) => {
          console.log(err);
          return observableThrowError(err.message);
        })
      )
    );
  }

  getMatchupGroups(startDate: Date, endDate: Date, sportid?: Sport, conferenceids?: string[], organizationid?: string, regionids: string[] = [],
      camopids: string[] = [], assignmentStatus?: AssignmentStatus, hasNotes?: boolean, uploadStatus?: UploadStatus): Promise<GenericAPIArrayResponse<GameManagerMatchupGroup>> {
    const url = `${this.apiHelper.apiUrl()}/games/get-matchup-groups`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {
      startDate,
      endDate,
      sportid,
      conferenceids,
      organizationid,
      regionids,
      camopids,
      assignmentStatus,
      hasNotes,
      uploadStatus
    };

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<GameManagerMatchupGroup>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        return observableThrowError(err.message);
      }),));
  }

  downloadGameManagerGames(startDate: Date, endDate: Date, sportid?: Sport, conferenceids?: string[], organizationid?: string, regionids: string[] = [],
      camopids: string[] = [], assignmentStatus?: AssignmentStatus, hasNotes?: boolean, uploadStatus?: UploadStatus): Promise<GenericAPIResponse<undefined>> {
    const url = `${this.apiHelper.apiUrl()}/games/game-manager-download`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {
      startDate,
      endDate,
      sportid,
      conferenceids,
      organizationid,
      regionids,
      camopids,
      assignmentStatus,
      hasNotes,
      uploadStatus
    };

    return lastValueFrom(this.http.post<GenericAPIResponse<undefined>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        return observableThrowError(err.message);
      }),));
  }

  getGamesToConfirm(): Promise<GenericAPIArrayResponse<Game>> {
    const url = `${this.apiHelper.apiUrl()}/games/confirmations`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIArrayResponse<Game>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getRecentGameUploads(): Promise<GenericAPIArrayResponse<Game>> {
    const url = `${this.apiHelper.apiUrl()}/games/recent-uploads`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIArrayResponse<Game>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  mergeGameClips(gameid: string, angle: Angle, deleteotherclips: boolean = true): Promise<GenericAPIArrayResponse<Game>> {
    const url = `${this.apiHelper.apiUrl()}/games/merge-clips`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {
      gameid,
      angle,
      deleteotherclips,
      isZip: false
    }

    return lastValueFrom(this.http.post<GenericAPIArrayResponse<Game>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  copyGameVideo(sourceGameid: string, destGameid: string): Promise<GenericAPIResponse<undefined>> {
    const url = `${this.apiHelper.apiUrl()}/games/copy-game-video`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {
      sourceGameid,
      destGameid
    }

    return lastValueFrom(this.http.post<GenericAPIResponse<undefined>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  createDeepCopyOfGame(gameid: string, teamid: string, seasonid: number, sourceTeamid?: string, copyStats: boolean = false, statcolumns: string[] = []): Promise<GenericAPIResponse<Game>> {
    const url = `${this.apiHelper.apiUrl()}/games/create-deep-copy`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {
			gameid,
			teamid,
			seasonid,
			sourceTeamid,
			copyStats,
			statcolumns
    }

    return lastValueFrom(this.http.post<GenericAPIResponse<Game>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  copyStreamToGameFilm(gameid: string): Promise<GenericAPIResponse<undefined>> {
    const url = `${this.apiHelper.apiUrl()}/games/stream-to-game`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = {
      gameid
    }

    return lastValueFrom(this.http.post<GenericAPIResponse<undefined>>(url, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  getFeaturedGames(): Promise<GenericAPIArrayResponse<Game>> {
    const url = `${this.apiHelper.apiUrl()}/games/featured`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(this.http.get<GenericAPIArrayResponse<Game>>(url, { headers }).pipe(
      map(res => res),
      catchError(err => {
        console.log(err);
        return observableThrowError(err.message);
      }),));
  }

  createGameClipPlaylist(
    gameId: string,
    title: string,
    clipIds: string[] = [],
    sharedUserIds: string[] = [],
    score: string = '',
  ): Promise<GenericAPIArrayResponse<ClipPlaylist>> {
    const url = `${this.apiHelper.apiUrl()}/games/playlists/${gameId}`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = { title, clipIds, sharedUserIds, score };

    return lastValueFrom(
      this.http
        .post<GenericAPIArrayResponse<ClipPlaylist>>(url, body, { headers })
        .pipe(
          map((res) => res),
          catchError((err: any) => {
            console.log(err);
            return observableThrowError(err.message);
          })
        )
    );
  }

  shareGameClipPlaylist(
    playlistId: string,
    sharedUserIds: string[] = []
  ): Promise<GenericAPIArrayResponse<ClipPlaylist>> {
    const url = `${this.apiHelper.apiUrl()}/games/playlists/share/${playlistId}`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = { sharedUserIds };
    return lastValueFrom(
      this.http
        .post<GenericAPIArrayResponse<ClipPlaylist>>(url, body, { headers })
        .pipe(
          map((res) => res),
          catchError((err: any) => {
            return observableThrowError(err.message);
          })
        )
    );
  }

  updateGameClipPlaylist(
    playlistId: string,
    playlist: ClipPlaylist
  ): Promise<GenericAPIResponse<ClipPlaylist>> {
    const url = `${this.apiHelper.apiUrl()}/games/playlist/${playlistId}`;
    const headers = this.apiHelper.apiHeaderWithToken();
    const body = { ...playlist };
    return lastValueFrom(
      this.http
        .put<GenericAPIResponse<ClipPlaylist>>(url, body, { headers })
        .pipe(
          map((res) => res),
          catchError((err: any) => {
            return observableThrowError(err.message);
          })
        )
    );
  }

  fetchGameClipPlaylist(
    gameId: string,
  ): Promise<GenericAPIArrayResponse<ClipPlaylist>> {
    const url = `${this.apiHelper.apiUrl()}/games/playlists/${gameId}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(
      this.http
        .get<GenericAPIArrayResponse<ClipPlaylist>>(url, { headers })
        .pipe(
          map((res) => res),
          catchError((err: any) => {
            return observableThrowError(err.message);
          })
        )
    );
  }

  deleteGameClipPlaylist(
    playlistId: string
  ): Promise<GenericAPIArrayResponse<ClipPlaylist>> {
    const url = `${this.apiHelper.apiUrl()}/games/playlist/${playlistId}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(
      this.http
        .delete<GenericAPIArrayResponse<ClipPlaylist>>(url, { headers })
        .pipe(
          map((res) => res),
          catchError((err: any) => {
            return observableThrowError(err.message);
          })
        )
    );
  }

  getGameCaptureConnections(gameid: string): Promise<GenericAPIArrayResponse<GameCaptureConnection>> {
    const url = `${this.apiHelper.apiUrl()}/games/capture-connections/${gameid}`;
    const headers = this.apiHelper.apiHeaderWithToken();

    return lastValueFrom(
      this.http
        .get<GenericAPIArrayResponse<GameCaptureConnection>>(url, { headers })
        .pipe(
          map((res) => res),
          catchError((err: any) => {
            return observableThrowError(err.message);
          })
        )
    );
  }
}
