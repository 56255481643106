import { Component, NgZone } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { SocketIOService } from 'src/app/services/socket-io.service';

declare const gtag: Function; // Google Analytics

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'QwikCut Access';

  constructor(
    private zone: NgZone,
    private router: Router,
		private socketIOService: SocketIOService
  ) {

    this.router.onSameUrlNavigation = 'reload';
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

		/** START : Code to Track Page View using gtag.js */
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    )
		.subscribe({
			next: event => {
				gtag('event', 'page_view', {
					 page_path: (<NavigationEnd>event).urlAfterRedirects
				})
			 }
		});
		/** END : Code to Track Page View  using gtag.js */

    //this prevents duplicate keydown events from being propagated to the rest of the application
    // helps with performance, especially with video player keyboard shortcuts
    this.zone.runOutsideAngular(() => {
      let altKeyDown = false;
      window.document.addEventListener("keydown", (event: KeyboardEvent) => { 
        if (event.key === 'Alt') altKeyDown = true;
        if (event.repeat != undefined && event.repeat && (event.target == document.body || altKeyDown ||
          (event.target instanceof HTMLElement &&
            event.target.tagName.toUpperCase() != 'INPUT' &&
            event.target.tagName.toUpperCase() != 'TEXTAREA'))) {
              event.stopPropagation();
              event.stopImmediatePropagation();
              event.preventDefault();
        }
      });
      window.document.addEventListener("keyup", (event: KeyboardEvent) => { 
        if (event.key === 'Alt') altKeyDown = false;
				this.socketIOService.onAppInteraction();
      });
      window.document.addEventListener("mouseup", (event: MouseEvent) => {
				this.socketIOService.onAppInteraction();
      });
    })
  }
}
